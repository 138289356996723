import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

//Imports for sidebar and APIUser contexts
import { userContext } from "../../../context/userContext"
import { APIUserContext } from "../../../context/APIUserContext"

//Import for API User API handler
import * as ApiUserApi from '../../../api/users/api_user/ApiUserApi'

//Import for API Form component
import ApiUserForm from "./ApiUserForm"

//Import for notification
import NotificationManager from "react-notifications/lib/NotificationManager"

//Dummy data for api-user
const apiUserData = [
  {
    id: 1,
    address: "islamabad",
    document: "abc",
    email: "hamza@gmail.com",
    org_firstname: "aa",
    org_lastname: "abc",
    organization_name: "pkteam",
    register_number: 123,
    phone: 6666,
  },
  {
    id: 2,
    address: "islamabad",
    document: "abc",
    email: "hamza@gmail.com",
    org_firstName: "aa",
    org_lastName: "abc",
    organization_name: "pkteam",
    registerNumber: 123,
    phone: 6666,
  },
]

//Initial State for Components
const initialState = {
  userType: "Radiologist",
  loading: true,
  errorEmail: '',
  errorTelephone: '',
  errorRegisterNumber: '',
  errorAddress: '',
  errorAddressIsOpen: false,
  errorEmailIsOpen: false,
  errorTelephoneIsOpen: false,
  errorRegisterNumberIsOpen: false,
  saving: false,
  files: [],
  apiUsers: [],

  apiUser: {
    _id: "",
    address: "",
    document: [],
    email: "",
    organization_name: "",
    registration_number: "",
    phone: "",
    status: true,
    password: ""
  }
}

//Regular Expressions for validations
const telephoneRegExPattern = new RegExp(/[a-zA-Z]/)
const specialCharactersRegExPattern = new RegExp(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/)
const emailRegExPattern = new RegExp(/[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/)

const ManageApiUserPage = (props) => {
  //Users and their respective setters
  const [apiUsers, setApiUsers] = useState(initialState.apiUsers)

  //New and Updated Users with their respective setters
  const [newApiUser, setNewApiUser] = useState(initialState.apiUser)

  const { contextAPIUser, setContextAPIUser } = useContext(APIUserContext)

  //Initialization for Alert component and Alert component text
  const [errorEmail, setErrorEmail] = useState(initialState.errorEmail)
  const [errorTelephone, setErrorTelephone] = useState(initialState.errorTelephone)
  const [errorRegisterNumber, setErrorRegisterNumber] = useState(initialState.errorRegisterNumber)
  const [errorAddress, setErrorAddress] = useState(initialState.errorAddress)
  const [errorAddressIsOpen, setErrorAddressIsOpen] = useState(initialState.errorAddressIsOpen)
  const [errorRegisterNumberIsOpen, setErrorRegisterNumberIsOpen] = useState(initialState.errorRegisterNumberIsOpen)
  const [errorEmailIsOpen, setErrorEmailIsOpen] = useState(initialState.errorEmailIsOpen)
  const [errorTelephoneIsOpen, setErrorTelephoneIsOpen] = useState(initialState.errorTelephoneIsOpen)

  const [files, setFiles] = useState(initialState.files)
  const [saving, setSaving] = useState(initialState.saving)
  const [loading, setLoading] = useState(initialState.loading)

  const history = useHistory()

  // set visible to true to display this component on screen
  const { visible, setVisible } = useContext(userContext)

  //Runs once on render
  useEffect(() => {
    setLoading(true)

    if (typeof (props.location.data) !== "undefined") {
      console.log(props.location)
      setContextAPIUser({ ...props.location.data.apiUserData })
      setNewApiUser({ ...props.location.data.apiUserData })
      setLoading(false)
    } else {
      setNewApiUser(initialState.apiUser)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    console.log(newApiUser)
  }, [newApiUser])

  //Add Api User Handler
  const handleAddApiUser = () => {
    setSaving(true)

    if (newApiUser.email === "" || newApiUser.phone === "" ||
      newApiUser.registration_number === "" || newApiUser.organization_name === '' ||
      newApiUser.address === "") {
      setSaving(initialState.saving)
      NotificationManager.error('Form has empty fields, cannot submit', "Error", 1000)
    } else if (errorEmail || errorTelephone) {
      setSaving(initialState.saving)
      NotificationManager.error('Errors in form, cannot submit', "Error", 1000)
    } else {
      if (newApiUser._id === "") {
        delete newApiUser._id
        console.log(newApiUser)
      }
      const apiUserFormData = new FormData()

      apiUserFormData.append("email", newApiUser.email)
      apiUserFormData.append("phone", newApiUser.phone)
      apiUserFormData.append("organization_name", newApiUser.organization_name)
      apiUserFormData.append("address", newApiUser.address)
      apiUserFormData.append("registration_number", newApiUser.registration_number)
      apiUserFormData.append("status", newApiUser.status)
      apiUserFormData.append("documents", JSON.stringify(newApiUser.document))

      console.log(...apiUserFormData)

      ApiUserApi.createApiUser(apiUserFormData)
        .then(res => {
          setSaving(false)
          NotificationManager.success(`${newApiUser.organization_name} has been registered with the system.`, "Success", 1000)
          console.log(res)
          history.push('/admin/api-users')
        })
        .catch(err => {
          setSaving(false)

          NotificationManager.error(`Something went wrong while creating ${newApiUser.organization_name}: ` + err, "Error", 1000)
        })
    }
  }

  //Update Api User Handler
  const handleUpdateApiUser = () => {
    setSaving(true)
    console.log(newApiUser)

    const apiUserFormData = new FormData()

    apiUserFormData.append("_id", newApiUser.api_user_id)
    apiUserFormData.append("email", newApiUser.email)
    apiUserFormData.append("phone", newApiUser.phone)
    apiUserFormData.append("organization_name", newApiUser.organization_name)
    apiUserFormData.append("address", newApiUser.address)
    apiUserFormData.append("registration_number", newApiUser.registration_number)
    apiUserFormData.append("status", newApiUser.status)
    apiUserFormData.append("password", newApiUser.password)
    apiUserFormData.append("documents", JSON.stringify(newApiUser.document))

    console.log(...apiUserFormData)

    ApiUserApi.updateApiUser(apiUserFormData, newApiUser.api_user_id)
      .then(res => {
        if (res === 201) {
          setSaving(false)

          NotificationManager.success(`${newApiUser.organization_name}'s info has been updated.`, "Success", 1000)
          console.log(res)
          history.push('/admin/api-users')
        } else {
          setSaving(false)

          NotificationManager.error(`Something went wrong while updating ${newApiUser.organization_name}`, 'Error', 1000)
        }
      })
      .catch(err => {
        setSaving(false)

        NotificationManager.error(`Something went wrong while updating ${newApiUser.organization_name}: ` + err, 'Error', 1000)
      })
  }

  //Api User input change handler
  const handleInputChangeApiUser = (event) => {
    const { name, value } = event.target

    if (name === "document") {
      if (event.target.files[0].name.includes("pdf" || "jpeg")) {
        console.log(event.target.files[0])
        setNewApiUser((prevApiUser) => ({
          ...prevApiUser,
          [name]: event.target.files[0],
        }))
      } else {
        console.log(event.target.files[0].name)
        alert("Please select a PDF or JPEG format file.")
      }
    } else if (name === 'email') {
      emailValidation(event)
      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    } else if (name === 'phone') {
      telephoneValidation(event)
      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    } else if (name === 'registerNumber') {
      //telephoneValidation(event)
      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    } else if (name === 'address') {
      addressValidation(event)
      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    } else
      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
  }

  const handleDocumentChange = (files) => {

    console.log(files[0])

    setFiles(prevFiles => [...prevFiles, files[0]])

    let reader = new FileReader()

    reader.readAsDataURL(files[0])

    reader.onloadend = function () {
      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        document: [...prevApiUser.document, reader.result]
      }))
    }

    reader.onerror = function (error) {
      console.log('File Error: ', error);
    }
  }

  const handleDocumentRemoval = (file) => {
    let reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onloadend = () => {

      setFiles(files.filter(incomingFile => incomingFile.name !== file.name))

      setNewApiUser((prevApiUser) => ({
        ...prevApiUser,
        document: prevApiUser.document.filter(document => document !== reader.result)
      }))
    }
  }

  //validate email field
  const emailValidation = (event) => {
    const { name, value } = event.target

    if (value.length === 0) {
      setErrorEmail("Empty Field")
      setErrorEmailIsOpen(true)
      return false
    } else if (!emailRegExPattern.test(value)) {
      setErrorEmail("Please Enter a valid email address")
      setErrorEmailIsOpen(true)
      return false
    } else {
      setErrorEmail("")
      setErrorEmailIsOpen(false)
      return true
    }
  }

  //validate phone field
  const telephoneValidation = (event) => {
    const { name, value } = event.target

    if (name === 'phone') {
      if (value.length === 0) {
        setErrorTelephone("Phone Number is required")
        setErrorTelephoneIsOpen(true)
        return false
      } else if (value.length < 11) {
        setErrorTelephone("Phone Number must contain 11 digits")
        setErrorTelephoneIsOpen(true)
        return false
      } else if (telephoneRegExPattern.test(value)) {
        setErrorTelephone("Alphabet cannot be present in phone number")
        setErrorTelephoneIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorTelephone("Special Characters are not allowed")
        setErrorTelephoneIsOpen(true)
        return false
      } else {
        setErrorTelephone("")
        setErrorTelephoneIsOpen(false)
        return true
      }
    } else {
      if (value.length === 0) {
        setErrorRegisterNumber("Registeration Number is required")
        setErrorRegisterNumberIsOpen(true)
        return false
      } else if (value.length < 11) {
        setErrorRegisterNumber("Registeration Number must contain 11 digits")
        setErrorRegisterNumberIsOpen(true)
        return false
      } else if (telephoneRegExPattern.test(value)) {
        setErrorRegisterNumber("Alphabet cannot be present in Registeration number")
        setErrorRegisterNumberIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorRegisterNumber("Special Characters are not allowed")
        setErrorRegisterNumberIsOpen(true)
        return false
      } else {
        setErrorRegisterNumber("")
        setErrorRegisterNumberIsOpen(false)
        return true
      }
    }

  }

  //validate phone field
  const addressValidation = (event) => {
    const { name, value } = event.target

    if (value.length === 0) {
      setErrorAddress("Address is required")
      setErrorAddressIsOpen(true)
      return false
    } else if (value.length < 15) {
      setErrorAddress("Address must be atleast 15 characters")
      setErrorAddressIsOpen(true)
      return false
    } else {
      setErrorAddress("")
      setErrorAddressIsOpen(false)
      return true
    }
  }

  return (
    <div className="container">
      <div className="row">
        <ApiUserForm
          initialState={initialState.apiUser}
          saving={saving}
          apiUser={newApiUser}
          files={files}
          errorEmail={errorEmail}
          errorAddress={errorAddress}
          errorAddressIsOpen={errorAddressIsOpen}
          errorEmailIsOpen={errorEmailIsOpen}
          errorTelephone={errorTelephone}
          errorTelephoneIsOpen={errorTelephoneIsOpen}
          handleDocumentRemoval={handleDocumentRemoval}
          errorRegisterNumber={errorRegisterNumber}
          errorRegisterNumberIsOpen={errorRegisterNumberIsOpen}
          inputChange={handleInputChangeApiUser}
          documentChange={handleDocumentChange}
          addApiUser={handleAddApiUser}
          updateApiUser={handleUpdateApiUser}
        />
      </div>
    </div>
  );
};

export default ManageApiUserPage