import { get, patch, post } from "../../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const listReportsEndpoint = REACT_APP_API_URL + `/admin/report`
const getStatsEndpoint = REACT_APP_API_URL + `/admin/statistics`
const searchUsersEndpoint = REACT_APP_API_URL + `/admin/users`

//Api Handler for getting list of Visitor's Xrays via Fetch API
export const getListOfXrays = async (user_id, user_type) => {
  return await get(listReportsEndpoint + `?user_id=${user_id}&user_type=${user_type}`, { 'Authorization': localStorage.getItem('token') })
}

export const getStats = async () => {
  return await get(getStatsEndpoint, { 'Authorization': localStorage.getItem('token') })
}

export const searchUsers = async (user_type, name) => {
  return await get(searchUsersEndpoint + `?user_type=${user_type}&name=${name}`, { 'Authorization': localStorage.getItem('token') })
}