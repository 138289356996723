import React, { useContext, useEffect, useState } from "react"

import { NotificationManager } from "react-notifications"

import { userContext } from "../../context/userContext"
import { roleContext } from "../../context/RoleContext"

import { m1FeedbackApi, m2FeedbackApi } from '../../api/feedback/FeedbackApi'

import { css } from "@emotion/core"

import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`
const FadeInRightDiv = styled.div`animation:  0.9s ${fadeInRightAnimation};`

const initialState = {
  diagnosing: false,
  responseReceived: false,
  imagePreviewURL: "",
  incomingImagePath: "",
  file: null,
  loading: true,
  table1FeedbackRecorded: false,
  table2FeedbackRecorded: false,
  m1Cardiomegaly: "",
  m1Pneumonia: "",
  m1Tuberculosis: "",
  m2Cardiomegaly: "",
  m2Normal: "",
  m2Pneumonia: "",
  m2Tuberculosis: "",
  m1CardiomegalyFeedback: "",
  m1PneumoniaFeedback: "",
  m1TuberculosisFeedback: "",
  m2CardiomegalyFeedback: "",
  m2PneumoniaFeedback: "",
  m2TuberculosisFeedback: "",
  table1FeedbackCount: 0,
  table2FeedbackCount: 0,
  visitor_id: "",
  diagnosedXray_id: "",
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4A90E2;
  align-items: "center";
`;

const XrayDetailPage = (props) => {
  const { REACT_APP_API_URL } = process.env

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [diagnosedXray_id, setDiagnosedXray_id] = useState(initialState.diagnosedXray_id)
  const [incomingImagePath, setIncomingImagePath] = useState(initialState.incomingImagePath)
  const [visitor_id, setVisitor_id] = useState(initialState.visitor_id)

  const [m1Cardiomegaly, setM1Cardiomegaly] = useState(initialState.m1Cardiomegaly)
  const [m1Pneumonia, setM1Pneumonia] = useState(initialState.m1Pneumonia)
  const [m1Tuberculosis, setM1Tuberculosis] = useState(initialState.m1Tuberculosis)
  const [m2Cardiomegaly, setM2Cardiomegaly] = useState(initialState.m2Cardiomegaly)
  const [m2Pneumonia, setM2Pneumonia] = useState(initialState.m2Pneumonia)
  const [m2Tuberculosis, setM2Tuberculosis] = useState(initialState.m2Tuberculosis)

  const [m1CardiomegalyFeedback, setM1CardiomegalyFeedback] = useState(initialState.m1CardiomegalyFeedback)
  const [m1PneumoniaFeedback, setM1PneumoniaFeedback] = useState(initialState.m1PneumoniaFeedback)
  const [m1TuberculosisFeedback, setM1TuberculosisFeedback] = useState(initialState.m1TuberculosisFeedback)
  const [m2CardiomegalyFeedback, setM2CardiomegalyFeedback] = useState(initialState.m2CardiomegalyFeedback)
  const [m2PneumoniaFeedback, setM2PneumoniaFeedback] = useState(initialState.m2PneumoniaFeedback)
  const [m2TuberculosisFeedback, setM2TuberculosisFeedback] = useState(initialState.m2TuberculosisFeedback)
  const [table1FeedbackCount, setTable1FeedbackCount] = useState(initialState.table1FeedbackCount)
  const [table2FeedbackCount, setTable2FeedbackCount] = useState(initialState.table2FeedbackCount)

  const [heatMapURL, setHeatMapURL] = useState('')
  const [heatMapCardiomegaly, setHeatMapCardiomegaly] = useState('')
  const [heatMapPneumonia, setHeatMapPneumonia] = useState("")
  const [heatMapTuberculosis, setHeatMapTuberculosis] = useState("")
  const [heatMapAll, setHeatMapAll] = useState("")

  const [table1FeedbackRecorded, setTable1FeedbackRecorded] = useState(initialState.table1FeedbackRecorded)
  const [table2FeedbackRecorded, setTable2FeedbackRecorded] = useState(initialState.table2FeedbackRecorded)

  const [imagePreviewURL, setImagePreviewURL] = useState(initialState.imagePreviewURL)
  const [responseReceived, setResponseReceived] = useState(initialState.responseReceived)
  const [diagnosing, setDiagnosing] = useState(initialState.diagnosing)

  const [swapXrayType, setSwapXrayType] = useState(false)

  const [loading, setLoading] = useState(initialState.loading)
  const [file, setFile] = useState(initialState.file)

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('role') === 'visitor' || 'radiologist') {
      setVisible(true)
      setRole(localStorage.getItem('role'))
      setLoading(true)

      console.log(props)

      if (props.location.data !== undefined) {
        setImagePreviewURL(REACT_APP_API_URL + props.location.data.image.slice(18))
        setIncomingImagePath(props.location.data.image)
        setVisitor_id(localStorage.getItem("user_id"))
        setDiagnosedXray_id(props.location.data.id)
        setM1Cardiomegaly(props.location.data.model1_data.m1cardiomegaly)
        setM1Pneumonia(props.location.data.model1_data.m1pneumonia)
        setM1Tuberculosis(props.location.data.model1_data.m1tuberculosis)
        setM2Cardiomegaly(props.location.data.model2_data.m2cardiomegaly)
        setM2Pneumonia(props.location.data.model2_data.m2pneumonia)
        setM2Tuberculosis(props.location.data.model2_data.m2tuberculosis)
        setHeatMapCardiomegaly(props.location.data.heatmaps !== undefined ? REACT_APP_API_URL + props.location.data.heatmap.cardiomegaly : "")
        setHeatMapPneumonia(props.location.data.heatmap !== undefined ? REACT_APP_API_URL + props.location.data.heatmap.pneumonia : "")
        setHeatMapTuberculosis(props.location.data.heatmap !== undefined ? REACT_APP_API_URL + props.location.data.heatmap.tuberculosis : "")
        setHeatMapAll(props.location.data.heatmap !== undefined ? REACT_APP_API_URL + props.location.data.heatmap.model_all : "")

        localStorage.setItem("imagePreviewURL", props.location.data.image)
        localStorage.setItem("incomingImagePath", props.location.data.image_path)
        localStorage.setItem("diagnose_xray_id", props.location.data.id)
        localStorage.setItem("m1Cardiomegaly", props.location.data.model1_data.m1Cardiomegaly)
        localStorage.setItem("m1pneumonia", props.location.data.model1_data.m1pneumonia)
        localStorage.setItem("m1tuberclosis", props.location.data.model1_data.m1tuberculosis)
        localStorage.setItem("m2cardiomegaly", props.location.data.model2_data.m2cardiomegaly)
        localStorage.setItem("m2pneumonia", props.location.data.model2_data.m2pneumonia)
        localStorage.setItem("m2tuberculosis", props.location.data.model2_data.m2tuberculosis)
        localStorage.setItem("heatMapCardiomegaly", REACT_APP_API_URL + props.location.data.heatmap.cardiomegaly)
        localStorage.setItem("heatMapPneumonia", REACT_APP_API_URL + props.location.data.heatmap.pneumonia)
        localStorage.setItem("heatMapTuberculosis", REACT_APP_API_URL + props.location.data.heatmap.tuberculosis)
        localStorage.setItem("heatMapAll", REACT_APP_API_URL + props.location.data.heatmap.model_all)

        setResponseReceived(true)
        setLoading(false)
      } else {
        setImagePreviewURL(localStorage.getItem("imagePreviewURL"))
        setIncomingImagePath(localStorage.getItem("incomingImagePath"))
        setVisitor_id(localStorage.getItem("visitor_id"))
        setDiagnosedXray_id(localStorage.getItem("diagnosedXray_id"))
        setM1Cardiomegaly(localStorage.getItem("m1Cardiomegaly"))
        setM1Pneumonia(localStorage.getItem("m1pneumonia"))
        setM1Tuberculosis(localStorage.getItem("m1tuberclosis"))
        setM2Cardiomegaly(localStorage.getItem("m2cardiomegaly"))
        setM2Pneumonia(localStorage.getItem("m2pneumonia"))
        setM2Tuberculosis(localStorage.getItem("m2tuberclosis"))
        setHeatMapCardiomegaly(localStorage.getItem("heatMapCardiomegaly"))
        setHeatMapPneumonia(localStorage.getItem("heatMapPneumonia"))
        setHeatMapTuberculosis(localStorage.getItem("heatMapTuberculosis"))
        setHeatMapAll(localStorage.getItem("heatMapAll"))
      }

      setResponseReceived(true)
      NotificationManager.success('Image successfully uploaded', "Success", 1000)
    } else
      props.history.push('/')
  }, [])

  useEffect(() => {
    if (swapXrayType) {
      if (document.getElementById("inner-box") !== null) {
        document.getElementById("inner-box").classList.add("flip-inner")
        document.getElementById("inner-box").classList.remove("flip-otherside")
      }
    } else {
      if (document.getElementById("inner-box") !== null) {
        document.getElementById("inner-box").classList.remove("flip-inner")
        document.getElementById("inner-box").classList.add("flip-otherside")
      }
    }
  }, [swapXrayType])

  //Table 1 Cardiomegaly radio button group handler
  const handleRadioM1CadiomegalyOnChange = (event) => {
    setM1CardiomegalyFeedback(event.target.value)
    if (event.target.value === "" || m1PneumoniaFeedback === "" || m1TuberculosisFeedback === "")
      setTable1FeedbackRecorded(false)
    else
      setTable1FeedbackRecorded(true)
  }

  //Table 1 Pneumonia radio button group handler
  const handleRadioM1PneumoniaOnChange = (event) => {
    setM1PneumoniaFeedback(event.target.value)
    if (m1CardiomegalyFeedback === "" || event.target.value === "" || m1TuberculosisFeedback === "")
      setTable1FeedbackRecorded(false)
    else
      setTable1FeedbackRecorded(true)
  }

  //Table 1 Tuberculosis radio button group handler
  const handleRadioM1TuberculosisOnChange = (event) => {
    setM1TuberculosisFeedback(event.target.value)
    if (m1CardiomegalyFeedback === "" || m1PneumoniaFeedback === "" || event.target.value === "")
      setTable1FeedbackRecorded(false)
    else
      setTable1FeedbackRecorded(true)
  }

  //Table 2 Cardiomegaly radio button group handler
  const handleRadioM2CardiomegalyOnChange = (event) => {
    setM2CardiomegalyFeedback(event.target.value)
    if (event.target.value === "" || m2PneumoniaFeedback === "" || m2TuberculosisFeedback === "")
      setTable2FeedbackRecorded(false)
    else
      setTable2FeedbackRecorded(true)
  }

  //Table 2 Pneumonia radio button group handler
  const handleRadioM2PneumoniaOnChange = (event) => {
    setM2PneumoniaFeedback(event.target.value)
    if (m2CardiomegalyFeedback === "" || event.target.value === "" || m2TuberculosisFeedback === "")
      setTable2FeedbackRecorded(false)
    else
      setTable2FeedbackRecorded(true)
  }

  //Table 2 Tuberculosis radio button group handler
  const handleRadioM2TuberculosisOnChange = (event) => {
    setM2TuberculosisFeedback(event.target.value)
    if (m2CardiomegalyFeedback === "" || m2PneumoniaFeedback === "" || event.target.value === "")
      setTable2FeedbackRecorded(false)
    else
      setTable2FeedbackRecorded(true)
  }

  const handleTable1SubmitBtn = () => {
    const m1FeedbackData = {
      "diagnosedXray_id": diagnosedXray_id,
      "role": role,
      "image": incomingImagePath,
      "cardiomegaly_found": m1Cardiomegaly,
      "cardiomegaly_feedback": m1CardiomegalyFeedback,
      "pneumonia_found": m1Pneumonia,
      "pneumonia_feedback": m1PneumoniaFeedback,
      "tuberclosis_found": m1Tuberculosis,
      "tuberclosis_feedback": m1TuberculosisFeedback,
      "user_id": visitor_id,
    }

    console.log(m1FeedbackData)

    if (table1FeedbackCount === 0) {
      m1FeedbackApi(m1FeedbackData)
        .then(res => {
          setTable1FeedbackCount(1)
          setTable1FeedbackRecorded(initialState.table1FeedbackRecorded)
          console.log(res)
          NotificationManager.success('Feedback Submitted.', "Success", 1000)
        })
        .catch(err => NotificationManager.error('Somehting went wrong while submitting feedback ' + err, "Error", 1000))
    }
  }

  //Table 2 Data Submission button handler
  const handleTable2SubmitBtn = () => {
    const m2FeedbackData = {
      "diagnosedXray_id": diagnosedXray_id,
      "role": role,
      "image": incomingImagePath,
      "cardiomegaly_found": m2Cardiomegaly,
      "cardiomegaly_feedback": m2CardiomegalyFeedback,
      "pneumonia_found": m2Pneumonia,
      "pneumonia_feedback": m2PneumoniaFeedback,
      "tuberclosis_found": m2Tuberculosis,
      "tuberclosis_feedback": m2TuberculosisFeedback,
      "user_id": visitor_id,
    }

    console.log(m2FeedbackData)

    if (table2FeedbackCount === 0) {
      m2FeedbackApi(m2FeedbackData)
        .then(res => {
          setTable2FeedbackCount(1)
          setTable2FeedbackRecorded(initialState.table2FeedbackRecorded)
          console.log(res)
          NotificationManager.success('Feedback Submitted.', "Success", 1000)
        })
        .catch(err => NotificationManager.error('Somehting went wrong while submitting feedback ' + err, "Error", 1000))
    }
  }

  return (
    <FadeInDiv className="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <div className="card shadow">
            <div className='card-body text-center'>
              {imagePreviewURL === "" ? (
                <></>
              ) : (
                <FadeInDiv className='flip-box'>
                  <div id='inner-box'>
                    <div className='flip-box-front'>
                      <img
                        id="image-preview"
                        style={{ maxHeight: "484px", maxWidth: "500px" }}
                        src={imagePreviewURL}
                      />
                    </div>
                    <div className='flip-box-back'>
                      <img
                        id="image-preview"
                        style={{ maxHeight: "484px", maxWidth: "500px" }}
                        src={heatMapURL}
                        alt='Heatmap'
                      />
                    </div>
                  </div>
                </FadeInDiv>
              )}

              <div className='text-center'>
                <button
                  style={{ borderRadius: "50px" }}
                  className='btn-block text-center btn btn-success mt-2'
                  onClick={() => setSwapXrayType(!swapXrayType)}>
                  <i class='fas fa-eye mr-2'></i>
                  {swapXrayType ? "View Heatmap of your Xray" : "View your Xray"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {responseReceived ?
          <FadeInRightDiv className="col-lg-6">
            <div className="card shadow">
              <div className="card-header py-3 text-center">
                <h6 style={{ color: "#2a606d" }} className="m-0 font-weight-bold">Diagnosis 1</h6>
              </div>
              <div className='card-body'>
                <table
                  className="table table-bordered text-center"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th scope="col">Disease</th>
                      <th scope="col">Diagnosis</th>
                      <th scope="col">Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        onClick={() => setHeatMapURL(heatMapCardiomegaly)}
                        style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                      >
                        Cardiomegaly
                      </td>
                      <td>{m1Cardiomegaly}</td>
                      <td>
                        <div style={{ display: "inline", alignContent: "space-between" }}>
                          <label className="label" htmlFor="radioM1CardiomegalyYes">
                            <input disabled={true} type="radio" id="radioM1CardiomegalyYes" name="m1FeedbackCardiomegaly" value="yes" onChange={handleRadioM1CadiomegalyOnChange} /> Yes
                          </label>{" "}
                          <label className="label" htmlFor="radioM1CadiomegalyNo">
                            <input disabled={true} type="radio" id="radioM1CadiomegalyNo" name="m1FeedbackCardiomegaly" value="no" onChange={handleRadioM1CadiomegalyOnChange} /> No
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        onClick={() => setHeatMapURL(heatMapPneumonia)}
                        style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                      >
                        Pneumonia
                      </td>
                      <td>{m1Pneumonia}</td>
                      <td>
                        <div style={{ display: "inline", alignContent: "space-between" }}>
                          <label className="label" htmlFor="radioM1PneumoniaYes">
                            <input disabled={true} type="radio" id="radioM1PneumoniaYes" name="m1FeedbackPneumonia" value="yes" onChange={handleRadioM1PneumoniaOnChange} /> Yes
                          </label>{" "}
                          <label className="label" htmlFor="radioM1PneumoniaNo">
                            <input disabled={true} type="radio" id="radioM1PneumoniaNo" name="m1FeedbackPneumonia" value="no" onChange={handleRadioM1PneumoniaOnChange} /> No
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        onClick={() => setHeatMapURL(heatMapTuberculosis)}
                        style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                      >
                        Tuberculosis
                      </td>
                      <td>{m1Tuberculosis}</td>
                      <td>
                        <div style={{ display: "inline", alignContent: "space-between" }}>
                          <label className="label" htmlFor="radiom1Tuberculosis">
                            <input disabled={true} type="radio" id="radiom1Tuberculosis" name="m1FeedbackTuberculosis" value="yes" onChange={handleRadioM1TuberculosisOnChange} /> Yes
                          </label>{" "}
                          <label className="label" htmlFor="radiom1PneumoniaNo">
                            <input disabled={true} type="radio" id="radiom1PneumoniaNo" name="m1FeedbackTuberculosis" value="no" onChange={handleRadioM1TuberculosisOnChange} /> No
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card shadow">
              <div className="card-header py-3 text-center">
                <h6 style={{ color: "#2a606d" }} className="m-0 font-weight-bold">Diagnosis 2</h6>
              </div>
              <div className='card-body'>
                <table
                  className="table table-bordered text-center"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th scope="col">Disease</th>
                      <th scope="col">Diagnosis</th>
                      <th scope="col">Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cardiomegaly</td>
                      <td>{m2Cardiomegaly}</td>
                      <td>
                        <div style={{ display: "inline", alignContent: "space-between" }}>
                          <label className="label" htmlFor="radioM2CardiomegalyYes">
                            <input disabled={true} type="radio" id="radioM2CardiomegalyYes" name="m2FeedbackCardiomegaly" value="yes" onChange={handleRadioM2CardiomegalyOnChange} /> Yes
                          </label>{" "}
                          <label className="label" htmlFor="radioM2CardiomegalyNo">
                            <input disabled={true} type="radio" id="radioM2CardiomegalyNo" name="m2FeedbackCardiomegaly" value="no" onChange={handleRadioM2CardiomegalyOnChange} /> No
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Pneumonia</td>
                      <td>{m2Pneumonia}</td>
                      <td>
                        <div style={{ display: "inline", alignContent: "space-between" }}>
                          <label className="label" htmlFor="radioM2PnuemoniaYes">
                            <input disabled={true} type="radio" id="radioM2PneumoniaYes" name="m2FeedbackPneumonia" value="yes" onChange={handleRadioM2PneumoniaOnChange} /> Yes
                          </label>{" "}
                          <label className="label" htmlFor="radioM2PneumoniaNo">
                            <input disabled={true} type="radio" id="radioM2PneumoniaNo" name="m2FeedbackPneumonia" value="no" onChange={handleRadioM2PneumoniaOnChange} /> No
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Tuberculosis</td>
                      <td>{m2Tuberculosis}</td>
                      <td>
                        <div style={{ display: "inline", alignContent: "space-between" }}>
                          <label className="label" htmlFor="radioM2TuberculosisYes">
                            <input disabled={true} type="radio" id="radioM2TuberculosisYes" name="m2FeedbackTuberculosis" value="yes" onChange={handleRadioM2TuberculosisOnChange} /> Yes
                          </label>{" "}
                          <label className="label" htmlFor="radioM2TuberculosisNo">
                            <input disabled={true} type="radio" id="radioM2TuberculosisNo" name="m2FeedbackTuberculosis" value="no" onChange={handleRadioM2TuberculosisOnChange} /> No
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {responseReceived ?
                          <button
                            style={{ borderRadius: "50px" }}
                            className='text-center btn btn-success mt-2'
                            onClick={() => setHeatMapURL(heatMapAll)}
                          >
                            <i class='fas fa-eye mr-2'></i>
                              View Heatmap of your Xray
                          </button> :
                          <button
                            style={{ borderRadius: "50px" }}
                            disabled={true}
                            className='btn-block text-center btn btn-primary mt-2'
                          >
                            <i class='fas fa-eye mr-2'></i>
                              View Heatmap of your Xray
                          </button>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </FadeInRightDiv> :
          <div className='text-center'></div>
        }
      </div>
    </FadeInDiv>
  )
}

export default XrayDetailPage
