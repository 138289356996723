import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

//Imports for sidebar and Radiologist context
import { userContext } from "../../../context/userContext"
import { RadiologistContext } from "../../../context/RadiologistContext"

//Import for Radiologist API handler
import * as RadiologistApi from '../../../api/users/radiologist/RadiologistApi'

import defaultAvatar from "../../../assets/default_avatar.png"

//Import for Radiologist Form Component
import RadiologistForm from "./RadiologistForm"

//Import for notification
import NotificationManager from "react-notifications/lib/NotificationManager"
import { EmailValidation, ImageValidation, NameValidation, TelephoneValidation } from "../../../validation/validations"

//Dummy data for radiologist
const radiologistData = [
  {
    firstname: "Hamza",
    lastname: "Mushtaq",
    email: "hamza@gmail.com",
    phone: 11111111,
    password: 123,
    qualification: "B.A",
    uploadeddocument: "abc",
  },
  {
    firstname: "Ismail",
    lastname: "Khan",
    email: "Ismail@gmail.com",
    phone: 11111111,
    password: 123,
    qualification: "B.A",
    uploadeddocument: "abc",
  },
]

//Initial State for Components
const initialState = {
  userType: "Radiologist",
  loading: true,
  errorName: "",
  errorEmail: "",
  errorPassword: "",
  errorTelephone: "",
  errorQualification: "",
  errorNameIsOpen: false,
  errorEmailIsOpen: false,
  errorPasswordIsOpen: false,
  errorTelephoneIsOpen: false,
  errorQualificationIsOpen: false,
  errorProfilePic: false,
  errorProfilePicIsOpen: false,
  saving: false,
  files: [],
  radiologists: [],

  radiologist: {
    radiologist_id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    qualification: "",
    documents: [],
    photo: "",
    status: true
  }
}

const ManageRadiologistPage = (props) => {

  //New and Updated Users with their respective setters
  const [newRadiologist, setNewRadiologist] = useState(initialState.radiologist)
  const { contextRadiologist, setContextRadiologist } = useContext(RadiologistContext)
  const [photo, setPhoto] = useState("")

  //Initialization for Alert component and Alert component text
  const [errorName, setErrorName] = useState(initialState.errorName)
  const [errorEmail, setErrorEmail] = useState(initialState.errorEmail)
  const [errorPassword, setErrorPassword] = useState(initialState.errorPassword)
  const [errorTelephone, setErrorTelephone] = useState(initialState.errorTelephone)
  const [errorQualification, setErrorQualification] = useState(initialState.errorQualification)
  const [errorEmailIsOpen, setErrorEmailIsOpen] = useState(initialState.errorEmailIsOpen)
  const [errorPasswordIsOpen, setErrorPasswordIsOpen] = useState(initialState.errorPasswordIsOpen)
  const [errorNameIsOpen, setErrorNameIsOpen] = useState(initialState.errorNameIsOpen)
  const [errorTelephoneIsOpen, setErrorTelephoneIsOpen] = useState(initialState.errorTelephoneIsOpen)
  const [errorQualificationIsOpen, setErrorQualificationIsOpen] = useState(initialState.errorQualificationIsOpen)
  const [errorProfilePic, setErrorProfilePic] = useState(initialState.errorProfilePic)
  const [errorProfilePicIsOpen, setErrorProfilePicIsOpen] = useState(initialState.errorProfilePicIsOpen)
  const [files, setFiles] = useState(initialState.files)

  const [loading, setLoading] = useState(initialState.loading)
  const [saving, setSaving] = useState(initialState.saving)

  const history = useHistory()

  // set visible to true to display this component on screen
  const { visible, setVisible } = useContext(userContext)

  useEffect(() => {
    console.log(files)
  }, [files])

  //Get all users list before first render
  useEffect(() => {
    setLoading(true)

    if (typeof (props.location.data) !== "undefined") {
      console.log(props.location)
      setContextRadiologist({ ...props.location.data.radiologistData })
      setNewRadiologist({ ...props.location.data.radiologistData })
      setLoading(false)
    } else {
      setNewRadiologist(initialState.radiologist)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setNewRadiologist((prevRadiologist) => ({
      ...prevRadiologist,
      photo: photo
    }))
  }, [photo])

  useEffect(() => {
    console.log(newRadiologist)
  }, [newRadiologist])

  //Add Radiologist Handler
  const handleAddRadiologist = () => {
    setSaving(true)

    if (newRadiologist.name === '' || newRadiologist.email === "" || newRadiologist.phone === "" ||
      newRadiologist.qualification === "") {
      setSaving(initialState.saving)
      NotificationManager.error('Form has empty fields, cannot submit', "Error", 1000)
    } else if (errorName || errorEmail || errorPassword || errorTelephone || errorQualification) {
      setSaving(initialState.saving)
      NotificationManager.error('Errors in form, cnnot submit', 'Error', 1000)
    } else {
      if (newRadiologist._id === "") {
        delete newRadiologist.radiologist_id
        console.log(newRadiologist)
      }
      const radiologistFormData = new FormData()

      radiologistFormData.append("name", newRadiologist.name)
      radiologistFormData.append("email", newRadiologist.email)
      radiologistFormData.append("phone", newRadiologist.phone)
      radiologistFormData.append("password", newRadiologist.password)
      radiologistFormData.append("qualification", newRadiologist.qualification)
      radiologistFormData.append("documents", JSON.stringify(newRadiologist.documents))
      radiologistFormData.append('photo', newRadiologist.photo)
      radiologistFormData.append("status", newRadiologist.status)

      console.log(...radiologistFormData)

      RadiologistApi.createRadiologist(radiologistFormData)
        .then(res => {
          setSaving(initialState.saving)

          if (res.message === "Radiologist already exists") {
            NotificationManager.error(`${newRadiologist.email} already exists.`, "Error", 1000)
          } else {
            NotificationManager.success(`${newRadiologist.name} has been registered with the system.`, "Success", 1000)
            console.log(res)
            history.push("/admin/radiologists")
          }
        })
        .catch(err => {
          NotificationManager.error(`Something went wrong while registering ${newRadiologist.name}: ` + err, "Error", 1000)
          setSaving(initialState.saving)
        })
    }
  }

  const getBase64 = async (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    })

  }

  //Update Radiologist Handler  
  const handleUpdateRadiologist = () => {
    setSaving(true)

    console.log(newRadiologist)

    const radiologistFormData = new FormData()

    radiologistFormData.append("radiologist_id", newRadiologist.radiologist_id)
    radiologistFormData.append("name", newRadiologist.name)
    radiologistFormData.append("email", newRadiologist.email)
    radiologistFormData.append("phone", newRadiologist.phone)
    radiologistFormData.append("password", newRadiologist.password)
    radiologistFormData.append("qualification", newRadiologist.qualification)
    radiologistFormData.append('photo', newRadiologist.photo)
    radiologistFormData.append("documents", JSON.stringify(newRadiologist.documents))
    radiologistFormData.append("status", newRadiologist.status)

    console.log(...radiologistFormData)

    RadiologistApi.updateRadiologist(radiologistFormData, newRadiologist.radiologist_id)
      .then(res => {
        if (res.status === 200) {
          setSaving(initialState.saving)

          NotificationManager.success(`${newRadiologist.name}'s info has been updated.`, "Success", 1000)
          console.log(res)
          history.push("/admin/radiologists")
        } else {
          NotificationManager.error(`Something went wrong while updating ${newRadiologist.name}`, "Error", 1000)
          setSaving(initialState.saving)
        }
      })
      .catch(err => {
        NotificationManager.error(`Something went wrong while updating ${newRadiologist.name}: ` + err, "Error", 1000)
        setSaving(initialState.saving)
      })
  }

  //Radiologist input change handler
  const handleInputChangeRadiologist = (event) => {
    const { name, value } = event.target

    if (name === 'name') {
      setErrorName(NameValidation(event)[1])
      setErrorNameIsOpen(NameValidation(event)[2])

      setNewRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        [name]: value,
      }))
    } else if (name === 'email') {
      setErrorEmail(EmailValidation(event)[1])
      setErrorEmailIsOpen(EmailValidation(event)[2])

      setNewRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        [name]: value,
      }))
    } else if (name === 'phone') {
      setErrorTelephone(TelephoneValidation(event)[1])
      setErrorTelephoneIsOpen(TelephoneValidation(event)[2])

      setNewRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        [name]: value,
      }))
    } else if (name === "photo") {
      setErrorProfilePic(ImageValidation(event)[1])
      setErrorProfilePicIsOpen(ImageValidation(event)[2])

      let reader = new FileReader()

      reader.readAsDataURL(event.target.files[0])

      reader.onloadend = function () {
        setNewRadiologist((prevRadiologist) => ({
          ...prevRadiologist,
          photo: reader.result
        }))
      }

      reader.onerror = function (error) {
        console.log('File Error: ', error);
      }

    } else
      setNewRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        [name]: value,
      }))
  }

  const handleDocumentChange = (files) => {

    console.log(files[0])

    setFiles(prevFiles => [...prevFiles, files[0]])

    let reader = new FileReader()

    reader.readAsDataURL(files[0])

    reader.onloadend = function () {
      setNewRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        documents: [...prevRadiologist.documents, reader.result]
      }))
    }

    reader.onerror = function (error) {
      console.log('File Error: ', error);
    }
  }

  const handleDocumentRemoval = (file) => {
    let reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onloadend = () => {

      setFiles(files.filter(incomingFile => incomingFile.name !== file.name))

      setNewRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        documents: prevRadiologist.documents.filter(document => document !== reader.result)
      }))
    }
  }

  return (
    <div className="container">
      <div className="row">
        <RadiologistForm
          initialState={initialState.radiologist}
          saving={saving}
          radiologist={newRadiologist}
          files={files}
          errorName={errorName}
          setPhoto={setPhoto}
          errorNameIsOpen={errorNameIsOpen}
          errorEmail={errorEmail}
          errorEmailIsOpen={errorEmailIsOpen}
          errorTelephone={errorTelephone}
          errorTelephoneIsOpen={errorTelephoneIsOpen}
          errorQualification={errorQualification}
          errorQualificationIsOpen={errorQualificationIsOpen}
          errorProfilePic={errorProfilePic}
          errorProfilePicIsOpen={errorProfilePicIsOpen}
          inputChange={handleInputChangeRadiologist}
          handleDocumentRemoval={handleDocumentRemoval}
          documentChange={handleDocumentChange}
          addRadiologist={handleAddRadiologist}
          updateRadiologist={handleUpdateRadiologist}
        />
      </div>
    </div>
  )
}

export default ManageRadiologistPage