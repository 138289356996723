import React, { useEffect, useState } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"

//Import for notifications
import { NotificationManager } from "react-notifications"

//Import for logout API
import { LogoutApi } from "../../../api/auth/logout/LogoutApi"

import applogo from "../../../assets/applogo.png"

import defaultAvatar from "../../../assets/undraw_profile.svg"

//Initial State
const initialState = {
  user_name: "",
  color: 'white',
  radiologist: {
    radiologist_id: "",
    name: "",
    email: "",
    phone: "",
    photo: "",
    password: "",
    qualification: "",
    documents: "",
    status: true
  },
  visitor: {
    visitor_id: "",
    name: "",
    photo: "",
    email: "",
    phone: "",
    password: "",
    status: true
  },
  apiUser: {
    apiUser_id: "",
    address: "",
    document: "",
    email: "",
    organization_name: "",
    registration_number: "",
    phone: "",
    status: true,
    pasword: ""
  }
}

const Topbar = (props) => {

  const { REACT_APP_API_URL } = process.env

  const [color, setColor] = useState(initialState.color)
  const [visitor, setVisitor] = useState(initialState.visitor)
  const [radiologist, setRadiologist] = useState(initialState.radiologist)
  const [apiUser, setApiUser] = useState(initialState.apiUser)
  // const [ user_name, setUser_name ] = useState(initialState.user_name)
  const history = useHistory()

  //Runs once on render
  useEffect(() => {
    if (!localStorage.getItem('token'))
      history.push('/')
    else {
      if (localStorage.getItem('role') === 'admin') {
        setColor('white')
      }
      else if (localStorage.getItem('role') === 'visitor') {
        setColor('#59b1a7')
        setVisitor(JSON.parse(localStorage.getItem('visitor_detail')))
      }
      else if (localStorage.getItem('role') === 'radiologist') {
        setColor('#2a606d')
        setRadiologist(JSON.parse(localStorage.getItem('radiologist_detail')))
      }
      else if (localStorage.getItem('role') === 'apiUser') {
        setApiUser(JSON.parse(localStorage.getItem('apiUser_detail')))
      }
    }
  }, [])

  //runs whenever visitor is changed
  useEffect(() => {
    console.log(visitor)
  }, [visitor])

  //runs whenever visitor is changed
  useEffect(() => {
    console.log(radiologist)
  }, [radiologist])

  return (
    <nav style={{ backgroundColor: color }} className="navbar navbar-expand navbar-light bg topbar mb-4 static-top shadow">
      {localStorage.getItem("role") === "visitor" ?
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href={"/visitor"}
        >
          <img src={applogo} width="150" />
        </a> :
        localStorage.getItem("role") === "radiologist" ?
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href={"/radiologist"}
          >
            <img src={applogo} width="150" />

          </a> : <></>
      }

      {/* <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        <i className="fa fa-bars"></i>
      </button>

      <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input
            type="text"
            className="form-control bg-light border-0 small"
            placeholder="Search for..."
            aria-label="Search"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <i className="fas fa-search fa-sm"></i>
            </button>
          </div>
        </div>
      </form> */}

      <ul className="navbar-nav ml-auto">
        {/* <li className="nav-item dropdown no-arrow d-sm-none">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="searchDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-search fa-fw"></i>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown"
          >
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        <li className="nav-item dropdown no-arrow mx-1">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="alertsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-bell fa-fw"></i>
            <span className="badge badge-danger badge-counter">3+</span>
          </a>
          <div
            className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="alertsDropdown"
          >
            <h6 className="dropdown-header">Alerts Center</h6>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="mr-3">
                <div className="icon-circle bg-primary">
                  <i className="fas fa-file-alt text-white"></i>
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 12, 2019</div>
                <span className="font-weight-bold">
                  A new monthly report is ready to download!
                </span>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="mr-3">
                <div className="icon-circle bg-success">
                  <i className="fas fa-donate text-white"></i>
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 7, 2019</div>
                $290.29 has been deposited into your account!
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="mr-3">
                <div className="icon-circle bg-warning">
                  <i className="fas fa-exclamation-triangle text-white"></i>
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 2, 2019</div>
                Spending Alert: We've noticed unusually high spending for your
                account.
              </div>
            </a>
            <a
              className="dropdown-item text-center small text-gray-500"
              href="#"
            >
              Show All Alerts
            </a>
          </div>
        </li>

        <li className="nav-item dropdown no-arrow mx-1">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="messagesDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-envelope fa-fw"></i>
            <span className="badge badge-danger badge-counter">7</span>
          </a>
          <div
            className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="messagesDropdown"
          >
            <h6 className="dropdown-header">Message Center</h6>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="img/undraw_profile_1.svg"
                  alt=""
                />
                <div className="status-indicator bg-success"></div>
              </div>
              <div className="font-weight-bold">
                <div className="text-truncate">
                  Hi there! I am wondering if you can help me with a problem
                  I've been having.
                </div>
                <div className="small text-gray-500">Emily Fowler · 58m</div>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="img/undraw_profile_2.svg"
                  alt=""
                />
                <div className="status-indicator"></div>
              </div>
              <div>
                <div className="text-truncate">
                  I have the photos that you ordered last month, how would you
                  like them sent to you?
                </div>
                <div className="small text-gray-500">Jae Chun · 1d</div>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="img/undraw_profile_3.svg"
                  alt=""
                />
                <div className="status-indicator bg-warning"></div>
              </div>
              <div>
                <div className="text-truncate">
                  Last month's report looks great, I am very happy with the
                  progress so far, keep up the good work!
                </div>
                <div className="small text-gray-500">Morgan Alvarez · 2d</div>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="#">
              <div className="dropdown-list-image mr-3">
                <img
                  className="rounded-circle"
                  src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                  alt=""
                />
                <div className="status-indicator bg-success"></div>
              </div>
              <div>
                <div className="text-truncate">
                  Am I a good boy? The reason I ask is because someone told me
                  that people say this to all dogs, even if they aren't good...
                </div>
                <div className="small text-gray-500">Chicken the Dog · 2w</div>
              </div>
            </a>
            <a
              className="dropdown-item text-center small text-gray-500"
              href="#"
            >
              Read More Messages
            </a>
          </div>
        </li>

        <div className="topbar-divider d-none d-sm-block"></div> */}

        {/*Profile picture and username section*/}
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="mr-2 d-none d-lg-inline text-black">
              {localStorage.getItem('name')}
            </span>
            <img
              className="img-profile rounded-circle"
              src={
                localStorage.getItem('photo') !== null || localStorage.getItem('photo') !== "" ?
                  localStorage.getItem("photo") : defaultAvatar
              }
            />
          </a>

          {/*Dropdown items linking to Profile, logout buttons*/}
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            {localStorage.getItem('role') === 'visitor' ?
              <Link
                className="dropdown-item"
                to={{ pathname: `/visitor/${localStorage.getItem("user_id")}/profile` }}
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </Link> : localStorage.getItem('role') === 'radiologist' ?
                <Link
                  className="dropdown-item"
                  to={{ pathname: `/radiologist/${localStorage.getItem("user_id")}/profile` }}
                >
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </Link> : localStorage.getItem('role') === 'apiUser' ?
                  <Link
                    className="dropdown-item"
                    to={{ pathname: `/api-user/${localStorage.getItem("user_id")}/profile` }}
                  >
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                  </Link> : <></>
            }

            {localStorage.getItem('role') === 'visitor' ?
              <Link
                className="dropdown-item"
                to={{ pathname: `/visitor/${localStorage.getItem("user_id")}/history` }}
              >
                <i className="fas fa-history fa-sm fa-fw mr-2 text-gray-400"></i>
                View History
              </Link> : localStorage.getItem('role') === 'radiologist' ?
                <Link
                  className="dropdown-item"
                  to={{ pathname: `/radiologist/${localStorage.getItem("user_id")}/history` }}
                >
                  <i className="fas fa-history fa-sm fa-fw mr-2 text-gray-400"></i>
                  View History
                </Link> : <></>
            }
            {/* <a className="dropdown-item" href="#">
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
              Settings
          </a> */}
            <Link
              className="dropdown-item"
              to={{
                pathname: '/change-password'
              }}
            >
              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
              Change Password
            </Link>

            <button
              id="logout-btn"
              className="dropdown-item"
              onClick={() => {
                LogoutApi()
                  .then(res => {
                    NotificationManager.success('You have been logged out')
                    console.log(res)
                    if (localStorage.getItem('role') === 'admin') {
                      localStorage.clear()
                      history.push('/admin-login')
                    } else {
                      localStorage.clear()
                      history.push('/')
                    }
                  })
                  .catch(err => NotificationManager.error('Something went wrong while logging out ' + err))
              }}
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </button>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default withRouter(Topbar)
