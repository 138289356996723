import validator from "validator"

export const NameValidation = (event) => {
  const { name, value } = event.target

  if(!validator.isEmpty(value)) {
    if(validator.matches(value, /^[a-zA-Z ]*$/)) {
      if(validator.isLength(value, {min:1, max: 2})) {
        if(name === 'firstName')
          return["firstName", "* Name must be atleast 3 characters long.", true]
        else
          return["lastName", "* Name must be atleast 3 characters long.", true]
      } else if(validator.isLength(value, {min: 3, max: undefined})) {
        if(name === "firstName")
          return["firstName", "", false]
        else
          return["lastName", "", false]
      }
    } else {
      if(name === "firstName")
        return["firstName", "* Name cannot contain numeric values.", true]
      else
        return["lastName", "* Name cannot contain numeric values.", true]
    }
  } else {
    if(name === 'firstName')
      return["firstName", "* Name is empty.", true]
    else
      return["lastName", "* Name is empty.", true]
  }
}

export const TelephoneValidation = (event) => {
  const {name, value} = event.target

  if(!validator.isEmpty(value))
    if(validator.isNumeric(value))
      if(validator.isLength(value, { min:1, max:10 }))
        return["telephone", "* Please Enter a phone number with 11 digits.", true]
      else
        return["telephone", "", false]
    else 
      return["telephone", "* Phone Number doesn't contain alphabets.", true]
  else
    return["telephone", "* Phone Number is empty.", true]
}

export const EmailValidation = (event) => {
  const {name, value} = event.target

  if(validator.isEmail(value, { ignore_max_length: false }))
    return["email", "", false]
  else
    return["email", "* Please Enter a valid Email Address.", true]
}

export const ImageValidation = (event) => {
  const { name, files } = event.target

  console.log(event.target.files[0].name)

  if(validator.contains(files[0].name, (".png", ".jpg")))
    return["profilepic", "", false]
  else
    return["profilepic", "* Please provide a valid file with valid format i.e. jpg or png", true]
}

export const PasswordValidation = (event) => {
  const {name, value} = event.target
  if(validator.isLength(value, { min: 6, max: 6 }))
    return ["password", '', false]
  else
    return ['password', "* Please enter the password of 6 characters", true]
}