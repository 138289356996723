import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"

//Imports for topbar and visitor Contexts
import { VisitorContext } from "../../../context/VisitorContext"
import { userContext } from "../../../context/userContext"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

const VisitorList = (props) => {
  const {
    visitors,
    initialState
  } = props

  //Contexts for visitor and topbar
  const { contextVisitor, setContextVisitor } = useContext(VisitorContext)
  const { visible, setVisible } = useContext(userContext)

  const [visitor, setVisitor] = useState(initialState.visitor)
  const history = useHistory()
  const firstUpdate = useRef(true)

  //UseEffect for first time render
  useEffect(() => {
    setVisible(true)
  }, [])

  //UseEffect for when visitor is changed
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    } else
      setContextVisitor(visitor)
  }, [visitor])

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <div className="card-header py-3">
        <div className='row'>
          <div className='col-lg-8'>
            <h6 className="m-0 font-weight-bold text-primary">
              Visitors
            </h6>
          </div>
          <div className="col-lg-4">
            {/* <button
            className="btn btn-primary"
            type="button"
            onClick={ () =>
              history.push('/users/visitor')
            }
            style={{ float: "right" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-plus" viewBox="0 1 16 18">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
            {" "}Add New Visitor
          </button> */}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-bordered text-center"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {visitors.map((visitor, i) => (
                <tr key={i}>
                  <td>
                    <Link
                      to={{
                        pathname: `visitors/${visitor.visitor_id}/view/`,
                        state: {
                          visitor_data: visitor
                        }
                      }}
                    >
                      {visitor.name}
                    </Link>
                  </td>
                  <td>{visitor.email}</td>
                  <td>{visitor.phone}</td>
                  <td>
                    <Link
                      to={{
                        pathname: `visitors/${visitor.visitor_id}/edit`,
                        data: {
                          visitor_data: visitor
                        }
                      }}
                    >
                      <svg
                        style={{ marginRight: "8%" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </Link>
                    {visitor.status ?
                      <svg
                        style={{ cursor: 'pointer' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-person-x text-danger"
                        viewBox="0 0 16 16"
                        onClick={() => {
                          setVisitor(visitor)
                          setVisitor({ ...visitor, status: false })
                        }}
                      >
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        <path fillRule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                      </svg>
                      // <i
                      //   className="btn-danger btn-sm fas fa-user-times"
                      //   type="button"
                      //   onClick={() => {
                      //     setVisitor(visitor)
                      //     setVisitor({...visitor, status: false})
                      //   }}
                      /*></i>*/ :
                      <svg
                        onClick={() => {
                          setVisitor(visitor)
                          setVisitor({ ...visitor, status: true })
                        }}
                        style={{ cursor: 'pointer' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-person-check text-success"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                      // <i
                      //   className="btn-success btn-sm fas fa-user-check" 
                      //   type="button"
                      //   onClick={() => {
                      //     setVisitor(visitor)
                      //     setVisitor({...visitor, status: true})
                      //   }}
                      // ></i>  
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default VisitorList
