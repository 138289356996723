import React, { useContext, useEffect, useState } from 'react'

import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

import * as AdminApi from "../../../api/users/admin/AdminApi"

import { NotificationManager } from "react-notifications"

import { css } from "@emotion/core"
import BarLoader from "react-spinners/BarLoader"

import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from "react-animations"
import HistoryList from '../../utility/HistoryList'

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 0.9s ${fadeInAnimation};
`
const FadeInRightDiv = styled.div`
  animation: 0.9s ${fadeInRightAnimation};
`

//Override styling for loader
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4A90E2;
  align-items: "center";
`;

const VisitorDiagnosisHistoryPage = (props) => {

  const { REACT_APP_API_URL } = process.env
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState([])

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("role") === "visitor") {
      setVisible(true)
      setRole(localStorage.getItem("role"))

      AdminApi.getListOfXrays(localStorage.getItem('user_id'), "visitor")
      .then(res => {
        if(res.status === 200) {
          setHistory(res.data)
          setLoading(false)
        }
      })

      setLoading(false)
    } else 
      props.history.push("/")
  }, [])

  useEffect(() => {
    console.log(history)
  }, [history])

  return (
    <FadeInDiv className="mb-4" style={{ width: "100%" }}>
      { loading ?
        <BarLoader
          color={"#4A90E2"}
          loading={loading}
          css={override}
          size={100}
          height={5}
          width={1000}
        /> :
        <>
          { history.length === 0 ? <h4 className="text-center font-size 16">No History Found</h4> :
            <HistoryList
              xrays={history}
            />
          }
        </>
      }
    </FadeInDiv>
  )
}

export default VisitorDiagnosisHistoryPage
