const { REACT_APP_SERVER_UP } = process.env

export const post = async (url, data, headers) => {
  if(REACT_APP_SERVER_UP === "TRUE") {
    return (
      await fetch(url, {
        method: "POST",
        headers: headers,
        body: data
      })
    ).json()
  } else if(REACT_APP_SERVER_UP === "FALSE") {
    return { status: 141 }
  }
}

export const get = async (url, headers) => {
  if(REACT_APP_SERVER_UP === "TRUE") {
  return (
    await fetch(url, {
      method: "GET",
      headers: headers
    })
  ).json()
  } else if(REACT_APP_SERVER_UP === "FALSE") {
    return { status: 141 }
  }
}

export const patch = async (url, data, headers) => {
  if(REACT_APP_SERVER_UP === "TRUE") {
    return (
      await fetch(url, {
        method: "PATCH",
        body: data,
        headers: headers
      })
    ).json()
  } else if(REACT_APP_SERVER_UP === "FALSE") {
    return { status: 141 }
  }
}

export const put = async (url, data, headers) => {
  if(REACT_APP_SERVER_UP === "TRUE") {
    return (
      await fetch(url, {
        method: "PUT",
        body: data,
        headers: headers
      })
    ).json()
  } else if(REACT_APP_SERVER_UP === "FALSE") {
    return { status: 141 }
  }
}

export const del = async (url, headers) => {
  if(REACT_APP_SERVER_UP === "TRUE") {
    return (
      await fetch(url, {
        method: "DELETE",
        headers: headers
      })
    ).json()
  } else if(REACT_APP_SERVER_UP === "FALSE") {
    return { status: 141 }
  }
}
