import { useContext, useEffect, useRef, useState } from "react"

//Import for APIUserList component
import ApiUsersList from "./ApiUsersList"

//Imports for topbar, sidebar and APIUser Contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"
import { APIUserContext } from "../../../context/APIUserContext"

//Import for APIUser API
import * as ApiUserApi from '../../../api/users/api_user/ApiUserApi'

//Import for Notifications
import NotificationManager from "react-notifications/lib/NotificationManager"

//Imports for Loader
import { css } from "@emotion/core"
import BarLoader from "react-spinners/BarLoader"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import ViewAppToken from "./ViewAppToken"
import { Col, Input, Row } from "reactstrap"
import { searcher } from "../../utility/Searcher"

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  apiUsers: [],
  apiUser: {
    _id: "",
    address: "",
    document: "",
    email: "",
    // org_firstName: "",
    // org_lastName: "",
    organization_name: "",
    registration_number: "",
    phone: "",
    status: true
  },
  confirmSuspension: false,
  confirmReinstation: false
}

// const apiUserData = [
//   {
//     _id: 1,
//     address: "islamabad",
//     document: "abc",
//     email: "hamza@gmail.com",
//     org_firstname: "aa",
//     org_lastname: "abc",
//     organization_name: "pkteam",
//     register_number: 123,
//     phone: 6666,
//   },
//   {
//     _id: 2,
//     address: "islamabad",
//     document: "abc",
//     email: "hamza@gmail.com",
//     org_firstName: "aa",
//     org_lastName: "abc",
//     organization_name: "pkteam",
//     registerNumber: 123,
//     phone: 6666,
//   },
// ]

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4A90E2;
  align-items: "center";
`;

const ApiUserPage = (props) => {

  const { setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const { contextAPIUser } = useContext(APIUserContext)
  const [apiUsers, setApiUsers] = useState(initialState.apiUsers)
  const [loading, setLoading] = useState(true)
  const firstUpdate = useRef(true)

  //Get all API Users when Page is loaded
  useEffect(() => {

    setLoading(true)
    setVisible(true)
    setRole(localStorage.getItem('role'))

    ApiUserApi.getApiUsers()
      .then(res => {
        if (res.status === 200) {
          setApiUsers(res.data)
          setLoading(false)
          NotificationManager.success("API Users Loaded Successfully", "Success", 1000)
        } else {
          setLoading(false)
          NotificationManager.error("Something went wrong while loading API Users", "Error", 1000)
        }
      })
      .catch(err => {
        setLoading(false)
        NotificationManager.error("Something went wrong while loading API Users: " + err, "Error", 1000)
      })
  }, [])

  //Runs whenever API User context changes
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    } else {
      if (contextAPIUser._id !== "") {
        const APIUserFormData = new FormData()

        // APIUserFormData.append("_id", contextAPIUser._id)
        // APIUserFormData.append("org_firstName", contextAPIUser.org_firstName)
        // APIUserFormData.append("org_lastName", contextAPIUser.org_lastName)
        APIUserFormData.append("email", contextAPIUser.email)
        APIUserFormData.append("address", contextAPIUser.address)
        APIUserFormData.append("phone", contextAPIUser.phone)
        APIUserFormData.append("registration_number", contextAPIUser.registration_number)
        APIUserFormData.append("organization_name", contextAPIUser.organization_name)
        APIUserFormData.append("status", contextAPIUser.status)
        APIUserFormData.append("password", contextAPIUser.password)
        APIUserFormData.append("document", contextAPIUser.document)

        console.log(...APIUserFormData)

        ApiUserApi.updateApiUser(APIUserFormData)
          .then(res => {
            if (res.status === 201) {
              if (contextAPIUser.status === true)
                NotificationManager.success(`${contextAPIUser.organization_name} has been reinstated`, 'Success', 1000)
              else
                NotificationManager.success(`${contextAPIUser.organization_name} has been suspended`, 'Success', 1000)

              ApiUserApi.getApiUsers()
                .then(res => {
                  if (res.status === 200) {
                    setApiUsers(res.data)
                    setLoading(false)
                  } else {
                    NotificationManager.error(`Something went wrong`, 'Error', 1000)
                    setLoading(false)
                  }
                })
                .catch(err => NotificationManager.error("Something went wrong while loading API Users: " + err, 'Error', 1000))
            } else {
              NotificationManager.error(`Something went wrong while suspending ${contextAPIUser.organization_name}: `, 'Error', 1000)
              setLoading(false)
            }
          })
          .catch(err => NotificationManager.error(`Something went wrong while suspending ${contextAPIUser.firstName + ' ' + contextAPIUser.lastName}: ` + err, 'Error', 1000))
      }
    }
  }, [contextAPIUser])

  const resetAPIUserKey = (email, app_token) => {
    const userData = {
      email: email,
      app_token: app_token
    }
    ApiUserApi.resetApiUserKey(JSON.stringify(userData))
      .then((res) => {
        if (res.message === "token updated") {
          alert("Your Updated Token is as follows: " + res.app_token)
        }
      })
      .catch((err) => {
        alert(err)
        console.log(err)
      })
  }

  const handleSearch = async (event) => {
    const { name, value } = event.target

    setLoading(true)

    searcher('api_user', value)
      .then(res => {
        setApiUsers(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        NotificationManager.error(`Something went wrong while searching` + err, 'Error', 1000)
      })
  }

  return (
    <FadeInDiv className="mb-4" style={{ width: "100%" }}>
      <div>
        <Row>
          <Col>
            <Input className="mb-2 ml-2 mr-2" type="search" placeholder="Search API Users" onChange={handleSearch} />
          </Col>
          <Col>
            <button
              className="btn btn-primary mb-2 mr-2"
              type="button"
              onClick={() =>
                props.history.push('/admin/api-users/add')
              }
              style={{ float: "right" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-plus" viewBox="0 1 16 18">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
              </svg>
              {" "}Add API User
                </button>
          </Col>
        </Row>

        {apiUsers.length === 0 ? <h4 className="text-center font-size 16">No API Users Found</h4> :
          loading ?
            <BarLoader
              color={"#4A90E2"}
              loading={loading}
              css={override}
              size={100}
              width={1000}
              height={5}
            /> :
            <ApiUsersList
              apiUsers={apiUsers}
              initialState={initialState}
              resetAPIUserKey={resetAPIUserKey}
            />
        }
      </div>
      {/* <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
            <h4 className="modal-title">App Token</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <p>{app_token}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div> */}
    </FadeInDiv>
  )
}
export default ApiUserPage