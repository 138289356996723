import { post } from "../../middleware/ApiCallsMiddleware";

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env;
const adminLoginEndpoint = REACT_APP_API_URL + "/admin/login";
const visitorLoginEndpoint = REACT_APP_API_URL + "/visitor/login";
const radiologistLoginEndpoint = REACT_APP_API_URL + "/radiologist/login";
const apiUserLoginEndpoint = REACT_APP_API_URL + "/apiUser/login";

//Api handler for Admin Login via Fetch API using JSON data
export const AdminLoginApi = async adminFormData => {
  return await post(adminLoginEndpoint, adminFormData, { Accept: "application/json" })
}

//Api handler for Visitor Login via Fetch API using JSON data
export const VisitorLoginApi = async visitorData => {
  return await post(visitorLoginEndpoint, visitorData, { Accept: "application/json" })
}

//Api handler for Radiologist Login via Fetch API using JSON data
export const RadiologistLoginApi = async radiologistData => {
  return await post(radiologistLoginEndpoint, radiologistData, { Accept: "application/json" })
}

//Api handler for API User Login via Fetch API using JSON data
export const APIUserLoginApi = async apiUserData => {
  return await post(apiUserLoginEndpoint, apiUserData, { Accept: "application/json" })
}
