import { del } from "../../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const logoutEndpoint = REACT_APP_API_URL + "/admin/logout"

//Api handler for User logout via Fetch API
export const LogoutApi = async () => {
  return await del(logoutEndpoint, { 'Authorization': localStorage.getItem('token') })
}