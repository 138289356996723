import { useContext, useEffect, useRef, useCallback, useState } from "react"
import { useHistory } from "react-router-dom"

//Imports for sidebar and topbar contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

//Import for Radiologist API handler
import * as RadiologistApi from '../../../api/users/radiologist/RadiologistApi'

//Import for notifications
import NotificationManager from "react-notifications/lib/NotificationManager"

import Cropper from "react-easy-crop"
import getCroppedImg from '../../utility/ImageCropper'

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

const { REACT_APP_API_URL } = process.env

//Initial State
const initialState = {
  errorFirstName: "",
  errorLastName: "",
  errorTelephone: "",
  errorQualification: "",
  errorFirstNameIsOpen: false,
  errorLastNameIsOpen: false,
  errorTelephoneIsOpen: false,
  errorQualificationIsOpen: false,
  passVisible: false,
  saving: false,
  mouseEntered: false,
  image: null,
  croppedImage: null,
  radiologist: {
    _id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    photo: "",
    qualification: "",
    documents: [],
    status: true
  }
}

//Regular Expressions for validations
const nameRegExPattern = new RegExp(/[0-9]/)
const specialCharactersRegExPattern = new RegExp(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/)
const telephoneRegExPattern = new RegExp(/[a-zA-Z]/)

const RadiologistProfile = (props) => {

  // const { radiologist_data } = props.location.state

  const history = useHistory()

  const [mouseEntered, setMouseEntered] = useState(initialState.mouseEntered)
  const [passVisible, setPassVisible] = useState(initialState.passVisible)
  const [image, setImage] = useState(initialState.image)
  const [croppedImage, setCroppedImage] = useState(initialState.croppedImage)

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [saving, setSaving] = useState(initialState.saving)

  const [loading, setLoading] = useState(false)

  const [errorFirstName, setErrorFirstName] = useState(initialState.errorFirstName)
  const [errorLastName, setErrorLastName] = useState(initialState.errorLastName)
  const [errorTelephone, setErrorTelephone] = useState(initialState.errorTelephone)
  const [errorQualification, setErrorQualification] = useState(initialState.errorQualification)

  const [errorFirstNameIsOpen, setErrorFirstNameIsOpen] = useState(initialState.errorFirstNameIsOpen)
  const [errorLastNameIsOpen, setErrorLastNameIsOpen] = useState(initialState.errorLastNameIsOpen)
  const [errorTelephoneIsOpen, setErrorTelephoneIsOpen] = useState(initialState.errorTelephoneIsOpen)
  const [errorQualificationIsOpen, setErrorQualificationIsOpen] = useState(initialState.errorQualificationIsOpen)

  // const [firstName, setFirstName] = useState(initialState.radiologist.firstName)
  const [name, setName] = useState(initialState.radiologist.name)
  const [phone, setTelephone] = useState(initialState.radiologist.phone)
  const [email, setEmail] = useState(initialState.radiologist.email)
  const [password, setPassword] = useState(initialState.radiologist.password)
  const [photo, setPhoto] = useState(initialState.radiologist.photo)
  const [status, setStatus] = useState(initialState.radiologist.status)
  const [qualification, setQualification] = useState(initialState.radiologist.qualification)
  const [documents, setDocuments] = useState(initialState.radiologist.documents)

  const [radiologist, setRadiologist] = useState(initialState.radiologist)

  const firstUpdate = useRef(true)
  const firstImageUpdate = useRef(true)

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  useEffect(() => {
    console.log(props)

    setVisible(true)
    setRole(localStorage.getItem('role'))
    setLoading(true)

    RadiologistApi.getRadiologist(localStorage.getItem('user_id'))
      .then(res => {
        setRadiologist(res)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)

        NotificationManager.error(`Something went wrong while updating: ` + err, "Error", 1000)
      })

    // setRadiologist(JSON.parse(localStorage.getItem('radiologist_detail')))
  }, [])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    } else {
      setName(radiologist.name)
      setEmail(radiologist.email)
      setTelephone(radiologist.phone)
      setPassword(radiologist.password)
      setStatus(radiologist.status)
      setPhoto(radiologist.photo)
      setQualification(radiologist.qualification)
      setDocuments(radiologist.documents)
    }
  }, [radiologist])

  //Reads the image uploaded
  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  //Handler for profile picture change
  const onImageChange = async (event) => {
    let imageDataUrl = await readFile(event.target.files[0])
    setImage(imageDataUrl)
  }

  //Runs everytime user crops the image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
    localStorage.setItem(
      'croppedAreaPixels',
      JSON.stringify(croppedAreaPixels)
    )
  }, [])

  //Runs when user clicks the "Crop Image" button
  const handleCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      console.log('done', { croppedImage })
      setCroppedImage(croppedImage)

    } catch (e) {
      console.error(e)
    }
  }

  //Runs when croppedImage is changed
  useEffect(() => {
    console.log(croppedImage)

    if (firstImageUpdate.current) {
      firstImageUpdate.current = false
      return
    } else {
      setSaving(true)
      console.log(radiologist)


      const radiologistFormData = new FormData()

      radiologistFormData.append("_id", radiologist._id)
      radiologistFormData.append("name", radiologist.name)
      radiologistFormData.append("email", radiologist.email)
      radiologistFormData.append("phone", radiologist.phone)
      radiologistFormData.append("password", radiologist.password)
      radiologistFormData.append("qualification", radiologist.qualification)
      radiologistFormData.append("documents", radiologist.documents === [] ? radiologist.documents : JSON.stringify(radiologist.documents))
      radiologistFormData.append("photo", croppedImage === null ? radiologist.photo : croppedImage)
      radiologistFormData.append("status", radiologist.status)

      console.log(...radiologistFormData)

      RadiologistApi.updateRadiologist(radiologistFormData)
        .then(res => {
          setSaving(initialState.saving)

          NotificationManager.success(`${radiologist.firstName + " " + radiologist.lastName}'s info has been successfully updated.`, "Success", 1000)
          history.push('/radiologist')
          console.log(res)

          var radiologist_detail = {
            _id: radiologistFormData.get('_id'),
            firstName: radiologistFormData.get('firstName'),
            lastName: radiologistFormData.get('lastName'),
            email: radiologistFormData.get('email'),
            phone: radiologistFormData.get('phone'),
            password: radiologistFormData.get('password'),
            qualification: radiologistFormData.get('qualification'),
            documents: radiologistFormData.get('documents'),
            status: radiologistFormData.get('status'),
            photo: radiologistFormData.get('photo')
          }

          localStorage.setItem('radiologist_detail', JSON.stringify(radiologist_detail))
          localStorage.setItem('user_name', radiologist_detail.firstName + " " + radiologist_detail.lastName)

          setRadiologist(radiologist_detail)
        })
        .catch(err => {
          setSaving(initialState.saving)

          NotificationManager.error(`Something went wrong while updating ${radiologist.firstName + " " + radiologist.lastName}: ` + err, "Error", 1000)
        })

      // setProfile_pic(croppedImage)
    }

  }, [croppedImage])

  const onChangeHandler = (event) => {
    const { name, value } = event.target

    if (name === "firstName" || name === "lastName") {
      nameValidation(event)

      setRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        [name]: value,
      }))
    } else if (name === "phone") {
      telephoneValidation(event)

      setRadiologist((prevRadiologist) => ({
        ...prevRadiologist,
        [name]: value,
      }))
    }
  }

  //Validate name fields
  const nameValidation = (event) => {
    const { name, value } = event.target

    if (name === 'firstName') {

      if (value.length === 0) {
        setErrorFirstName("Empty Field")
        setErrorFirstNameIsOpen(true)
        return false
      } else if (value.length > 0 && value.length < 3) {
        setErrorFirstName("Name must be atleast 3 characters long")
        setErrorFirstNameIsOpen(true)
        return false
      } else if (nameRegExPattern.test(value)) {
        console.log(value)
        setErrorFirstName("Numeric values are not allowed")
        setErrorFirstNameIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorFirstName("Special Characters are not allowed")
        setErrorFirstNameIsOpen(true)
        return false
      } else {
        setErrorFirstName("")
        setErrorFirstNameIsOpen(false)
        return true
      }
    } else {
      if (value.length === 0) {
        setErrorLastName("Empty Field")
        setErrorLastNameIsOpen(true)
        return false
      } else if (value.length > 0 && value.length < 3) {
        setErrorLastName("Name must be atleast 3 characters long")
        setErrorLastNameIsOpen(true)
        return false
      } else if (nameRegExPattern.test(value)) {
        console.log(value)
        setErrorLastName("Numeric values cannot be present in name")
        setErrorLastNameIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorLastName("Special Characters are not allowed")
        setErrorLastNameIsOpen(true)
        return false
      } else {
        setErrorLastName("")
        setErrorLastNameIsOpen(false)
        return true
      }
    }
  }

  //Validate phone field
  const telephoneValidation = (event) => {
    const { name, value } = event.target

    if (value.length === 0) {
      setErrorTelephone("Phone Number is required")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (value.length < 11) {
      setErrorTelephone("Phone Number must contain 11 digits")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (telephoneRegExPattern.test(value)) {
      setErrorTelephone("Alphabet cannot be present in Phone number")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (specialCharactersRegExPattern.test(value)) {
      setErrorTelephone("Special Characters are not allowed")
      setErrorTelephoneIsOpen(true)
      return false
    } else {
      setErrorTelephone("")
      setErrorTelephoneIsOpen(false)
      return true
    }
  }

  const updateRadiologist = () => {

    setSaving(true)

    console.log(radiologist)

    const radiologistFormData = new FormData()

    radiologistFormData.append("_id", radiologist._id)
    radiologistFormData.append("firstName", radiologist.firstName)
    radiologistFormData.append("lastName", radiologist.lastName)
    radiologistFormData.append("email", radiologist.email)
    radiologistFormData.append("phone", radiologist.phone)
    radiologistFormData.append("password", radiologist.password)
    radiologistFormData.append("qualification", radiologist.qualification)
    radiologistFormData.append('photo', radiologist.photo)
    radiologistFormData.append("uploaded_documents", radiologist.uploaded_documents)
    radiologistFormData.append("status", radiologist.status)

    console.log(...radiologistFormData)

    RadiologistApi.updateRadiologist(radiologistFormData)
      .then(res => {
        setSaving(initialState.saving)

        NotificationManager.success(`${radiologist.firstName + ' ' + radiologist.lastName}'s info has been updated.`, "Success", 1000)
        console.log(res)

        var radiologist_detail = {
          _id: radiologistFormData.get('_id'),
          firstName: radiologistFormData.get('firstName'),
          lastName: radiologistFormData.get('lastName'),
          email: radiologistFormData.get('email'),
          phone: radiologistFormData.get('phone'),
          password: radiologistFormData.get('password'),
          status: radiologistFormData.get('status'),
          photo: radiologistFormData.get('photo'),
          qualification: radiologistFormData.get('qualification'),
          uploaded_documents: radiologistFormData.get('uploaded_documents')
        }

        localStorage.setItem('radiologist_detail', JSON.stringify(radiologist_detail))
        localStorage.setItem('user_name', radiologist_detail.firstName + " " + radiologist_detail.lastName)

        setRadiologist(radiologist_detail)
      })
      .catch(err => {
        NotificationManager.error(`Something went wrong while updating ${radiologist.firstName + ' ' + radiologist.lastName}: ` + err, "Error", 1000)
        setSaving(initialState.saving)
      })
  }

  const handleDocumentView = (base64URL) => {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "70%", left: "16%" }}>
      { loading ? <h3 className="text-center">Loading...</h3> :
        <div className="card-body row">
          <div className="col-lg-6 d-block">
            <div
              style={{
                borderRadius: '50%',
                height: '200px',
                width: '200px',
              }}
              onMouseEnter={() => setMouseEntered(true)}
              onMouseLeave={() => setMouseEntered(false)}
            >
              {mouseEntered ?
                <>
                  <img
                    style={{
                      filter: 'blur(8px)',
                      WebkitFilter: 'blur(8px)',
                      animationTimingFunction: 'linear',
                      transition: 'all 0.2s ease-in-out',
                      marginLeft: "50%"
                    }}
                    className="rounded-circle"
                    height='200px'
                    width="200px"
                    src={photo === "" ? "/img/undraw_profile.svg" : photo}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: "30%",
                      borderRadius: '50%',
                      marginLeft: "28%",
                      color: '#fff',
                    }}
                  >
                    <label htmlFor='imageInput'>
                      <i className='far fa-edit fa-2x' style={{ cursor: "pointer" }}></i>
                    </label>
                    <input type="file" id='imageInput' onChange={onImageChange} style={{ display: 'none' }} />
                  </div>
                </> :
                <img
                  style={{
                    animationTimingFunction: 'linear',
                    transition: 'all 0.2s ease-in-out',
                    marginLeft: "50%",
                  }}
                  className="rounded-circle"
                  height='200px'
                  width="200px"
                  src={photo === "" ? "/img/undraw_profile.svg" : photo}
                />
              }
            </div>
          </div>
          <div className="col-lg-6">

            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Name</label>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control form-control-sm"
                  name="name"
                  value={name}
                  onChange={onChangeHandler}
                />
              </div>
              {errorLastNameIsOpen ?
                <FadeInDiv
                  className='alert alert-danger'
                  role='alert'
                >
                  {errorLastName}
                </FadeInDiv> :
                <></>
              }
            </div>

            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Email</label>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control form-control-sm"
                  value={email}
                  name="email"
                  readOnly
                  disabled
                />
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Phone</label>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control form-control-sm"
                  value={phone}
                  name="phone"
                  onChange={onChangeHandler}
                />
              </div>
              {errorTelephoneIsOpen ?
                <FadeInDiv
                  className='alert alert-danger'
                  role='alert'
                >
                  {errorTelephone}
                </FadeInDiv> :
                <></>
              }
            </div>

            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Password</label>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control form-control-sm"
                  type={passVisible ? "text" : "password"}
                  value={password}
                  readOnly
                  disabled
                />
              </div>
              <div className="small mt-1">
                <i
                  style={{ color: '#59b1a7' }}
                  onClick={() => setPassVisible(prevState => !prevState)}
                  className={passVisible ? "far fa-eye" : "far fa-eye-slash"}
                ></i>
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Qualification</label>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control form-control-sm"
                  value={qualification}
                  name="qualification"
                  onChange={onChangeHandler}
                />
              </div>
            </div>

            {documents === undefined || documents.length === 0 ?
              <></> :
              <div className="row mb-1">
                <div className="col-md-3 font-weight-bold">
                  <label>Document 1</label>
                </div>
                <div className="col-md-6 text-center">
                  <label style={{ cursor: "pointer" }} className="text-primary font-weight-bold" onClick={() => handleDocumentView(documents[0])}>View Document 1</label>
                </div>
              </div>
            }

            {documents === undefined || documents.length === 0 ?
              <></> :
              <div className="row mb-1">
                <div className="col-md-3 font-weight-bold">
                  <label>Document 2</label>
                </div>
                <div className="col-md-6 text-center">
                  <label style={{ cursor: "pointer" }} className="text-primary font-weight-bold" onClick={() => handleDocumentView(documents[1])}>View Document 2</label>
                </div>
              </div>
            }

            {documents === undefined || documents.length === 0 ?
              <></> :
              <div className="row mb-1">
                <div className="col-md-3 font-weight-bold">
                  <label>Document 3</label>
                </div>
                <div className="col-md-6 text-center">
                  <label style={{ cursor: "pointer" }} className="text-primary font-weight-bold" onClick={() => handleDocumentView(documents[2])}>View Document 3</label>
                </div>
              </div>
            }

            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Status</label>
              </div>
              <div className="col-md-6">
                {status ?
                  <p
                    className="text-success font-weight-bold text-center"
                  >
                    Active
                </p> :
                  <p
                    className="text-danger font-weight-bold text-center"
                  >
                    Suspended
                </p>
                }
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-md-12">
                {saving ?
                  <button
                    style={{ borderRadius: '50px' }}
                    type="button"
                    className="btn btn-outline-success btn-block"
                    disabled
                  >
                    <i className="fas fa-spinner fa-spin mr-2"></i>
                  Updating
                </button> :
                  <button
                    type="button"
                    style={{ borderRadius: '50px' }}
                    className="btn btn-outline-primary btn-block"
                    onClick={updateRadiologist}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square mr-2" viewBox="0 0 16 17">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  Update
                </button>
                }
              </div>
            </div>
          </div>

          <div>
            {image === null ?
              <></> : (
                <>
                  <FadeInDiv>
                    <Cropper
                      image={image}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      cropShape="round"
                      showGrid={false}
                      onRotationChange={setRotation}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </FadeInDiv>

                  <div
                    style={{ position: 'absolute', left: "97%", top: '1%' }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-x-circle text-white"
                      viewBox="0 0 16 16"
                      onClick={() => setImage(initialState.image)}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>

                  <div className="text-center" style={{ position: "absolute", left: "43%", top: "70%" }}>
                    <button
                      type="button"
                      className="btn btn-info"
                      style={{ borderRadius: '50px', cursor: "pointer" }}
                      onClick={handleCroppedImage}
                    >
                      <i className="fas fa-crop-alt mr-2"></i>
                  Crop Image
                </button>
                  </div>
                </>
              )}
          </div>
        </div>
      }
    </FadeInDiv>
  )
}

export default RadiologistProfile