import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Card, Button } from 'reactstrap'

import Dropzone from "react-dropzone"
import { AiOutlineClose } from 'react-icons/ai'
import { FiUpload } from "react-icons/fi"
import { useEffect } from 'react'

import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

const initialState = {
  selectedFiles: [{ size: "", preview: "", type: "", name: "" }],
  // files: [{filename: "", base64: ""}],
  // error: { apiError: "", fileError: "", selectedFilesError: "", fileFormatError: "" },
  // loading: false,
}


const FormUpload = (props) => {
  const [selectedFiles, setselectedFiles] = useState(initialState.selectedFiles) // Raw File in Dropzone For previewing puposes 
  const [currentSelectedFiles, setCurrentSelectedFiles] = useState([])

  const { uploadMultiple, acceptedFormats, setFiles } = props

  useEffect(() => {
    setFiles(currentSelectedFiles)
  }, [currentSelectedFiles])

  // ----------------------------- Format Bytes -----------------------------
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  async function processFiles(acceptedFiles, unacceptedFiles) {
    // Add `formattedSize`, `base64` and `preview` to acceptedFiles
    acceptedFiles.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
  }

  const handleFileDrop = async (acceptedFiles, unacceptedFiles) => {
    await processFiles(acceptedFiles, unacceptedFiles)
    let currentSelectedFiles = []
    acceptedFiles.forEach(file => {
      let currentSelectedFile = {
        formattedSize: file.formattedSize,
        preview: file.preview,
        type: file.type,
        name: file.name,
      }
      currentSelectedFiles.push(currentSelectedFile)
      delete file.preview
      delete file.formattedSize
    })
    setselectedFiles(currentSelectedFiles)

    let currentFiles = []
    acceptedFiles.map(file => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async (e) => file.base64 = e.target.result
      reader.onloadend = () => {

        setFiles(reader.result)

        // let currentFile = {
        //   filename: `${file.lastModified}-${file.size}-${file.name}`,
        //   base64: file.base64,
        // }
        // currentFiles.push(currentFile)
      }
    })

    // setCurrentSelectedFiles(currentFiles)
    // setFiles(currentFiles)
  }

  const removeFile = (file) => {
    setselectedFiles(selectedFiles.filter(selectedFile => selectedFile.name !== file.name))

    setCurrentSelectedFiles(currentSelectedFiles.filter(currentSelectedFile => currentSelectedFile.filename.includes(file.name)))
  }

  return (
    <>
      <Dropzone
        multiple={uploadMultiple}
        accept={acceptedFormats}
        onDrop={handleFileDrop}
      >
        {
          ({ getRootProps, getInputProps }) => (
            <div className="dropzone" style={{ cursor: "pointer" }}>
              <div
                className="dz-message needsclick mt-2 text-center"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="mb-3">
                  <FiUpload size={35}/>
                </div>
                <h4>Upload Image</h4>
              </div>
            </div>
          )
        }
      </Dropzone>

      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles[0] !== undefined ?
          selectedFiles[0].preview !== ""
            ? selectedFiles.map((f, i) => {
              return (
                <FadeInDiv>
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">    {f.name}    </Link>
                          <p className="mb-0"><strong>    {f.formattedSize}   </strong></p>
                        </Col>
                        <Col>
                          <div className="float-right">
                            <Button outline color="danger" style={{ borderRadius: "20px" }} onClick={() => removeFile(f)}><AiOutlineClose /></Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </FadeInDiv>
              )
            })
            : "" : ""
        }
      </div>
    </>
  )
}


export default FormUpload
