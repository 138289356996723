import { post } from "../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const covidFeedbackEndpoint = REACT_APP_API_URL + "/feedback/covid"
const m1FeedbackEndpoint = REACT_APP_API_URL + "/feedback/m1"
const m2FeedbackEndpoint = REACT_APP_API_URL + "/feedback/m2"

//Api handler for xray analysis from Covid via Fetch API using FormData
export const covidFeedbackApi = async(covidFeedbackData) => {
  return await post(covidFeedbackEndpoint, covidFeedbackData, { 'Accept': 'application/json', 'Authorization': localStorage.getItem('token') })
}

//Api handler for xray analysis from Model1 via Fetch API using FormData
export const m1FeedbackApi = async(m1FeedbackData) => {
  return await post(m1FeedbackEndpoint, m1FeedbackData, { 'Accept': 'application/json', 'Authorization': localStorage.getItem('token') })
}

//Api handler for xray analysis from Model2 via Fetch API using FormData
export const m2FeedbackApi = async(m2FeedbackData) => {
  return await post(m2FeedbackEndpoint, m2FeedbackData, { 'Accept': 'application/json', 'Authorization': localStorage.getItem('token') })
}