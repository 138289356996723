import { get, put, patch, post } from "../../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const createVisitorEndpoint = REACT_APP_API_URL + "/visitor/register"
const updateVisitorEndpoint = REACT_APP_API_URL + "/visitor/update?user_id="
const getVisitorEndpoint = REACT_APP_API_URL + "/visitor/?user_id="
const getVisitorsEndpoint = REACT_APP_API_URL + "/visitor/"
const listVisitorXraysEndpoint = REACT_APP_API_URL + "/report"
const resetPasswordEndpoint = REACT_APP_API_URL + "/visitor/reset_password"

//Api Handler for creating Visitor via Fetch API using JSON data
export const createVisitor = async(visitorFormData) => {
  return await post(createVisitorEndpoint, visitorFormData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for updating existing Visitor via Fetch API using JSON data
export const updateVisitor = async(visitorData, user_id) => {
  return await put(updateVisitorEndpoint + user_id, visitorData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting single Visitor via Fetch API
export const getVisitor = async (user_id) => {
  return await get(getVisitorEndpoint + user_id, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting list of Visitors via Fetch API
export const getVisitors = async () => {
  return await get(getVisitorsEndpoint, { 'Authorization': localStorage.getItem('token') })
}

export const resetPassword = async (userData) => {
  return await patch(resetPasswordEndpoint, userData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting list of Visitor's Xrays via Fetch API
// export const getListOfVisitorXrays = async () => {
  // return await get(listVisitorXraysEndpoint, { 'Authorization': localStorage.getItem('token') })

  // const response = await fetch(REACT_APP_API_URL + "/admin/admin_view_Visitor_daignose_Xray_list", {
  //   method: 'GET',
  //   headers: {
  //     'Authorization': localStorage.getItem('token')
  //   }
  // })
  // return await response.json()
// }
