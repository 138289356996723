import React from 'react'
import { useContext, useRef, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { userContext } from "../../context/userContext"

import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from "react-animations"
import { Table } from 'reactstrap'

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 0.9s ${fadeInAnimation};
`
const FadeInRightDiv = styled.div`
  animation: 0.9s ${fadeInRightAnimation};
`

const HistoryList = (props) => {

  const { xrays } = props

  const { REACT_APP_API_URL } = process.env

  //Contexts for visitor and topbar
  const { visible, setVisible } = useContext(userContext)

  const history = useHistory()
  const firstUpdate = useRef(true)

  //UseEffect for first time render
  useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <div className="card-header py-3">
        <div className='row'>
          <div className='col-lg-8'>
            <h6 className="m-0 font-weight-bold text-primary">
              Xrays
          </h6>
          </div>
          <div className="col-lg-4">
            {/* <button
          className="btn btn-primary"
          type="button"
          onClick={ () =>
            history.push('/users/visitor')
          }
          style={{ float: "right" }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-plus" viewBox="0 1 16 18">
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          {" "}Add New Visitor
        </button> */}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <Table
            hover
            className="text-center"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Xray</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {xrays.map((xray, i) => (
                <tr key={i}>
                  <td>
                    {i + 1}
                  </td>
                  <td><img src={REACT_APP_API_URL + xray.image.slice(18)} height="100" width="100" style={{ borderRadius: "10px" }} /></td>
                  <td>
                    <Link
                      to={{
                        pathname: `/${localStorage.getItem("user_id")}/xray-detail`,
                        data: xray
                      }}
                    >
                      View in detail
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default HistoryList
