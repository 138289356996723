import { useContext, useEffect, useState } from "react"

//Imports for sidear and topbar contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

//Imports for loader
import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`animation:  0.9s ${ fadeInAnimation };`
const FadeInRightDiv = styled.div`animation:  0.9s ${ fadeInRightAnimation };`

//Initial State
const initialState = {
  loading: false,
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4A90E2;
  align-items: "center";
`;

const ViewAppToken = (props) => {

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const [app_token, setApp_Token] = useState("")

  const [loading, setLoading] = useState(initialState.loading)

  //Runs once on render
  useEffect(() => {
    if(localStorage.getItem('token') && localStorage.getItem('role') === 'apiUser') {
      setVisible(true)
      setRole(localStorage.getItem('role'))
      setApp_Token(localStorage.getItem("app_token"))
      setLoading(false)
    } else {
      props.history.push('/')
    }
  }, [])

  return  (
    <FadeInDiv className="container">
      <div className="card shadow border-left-info">
      <a href="#collapseCard" class="d-block card-header py-3" data-toggle="collapse"
          role="button" aria-expanded="true" aria-controls="collapseCard">
        <h6 class="m-0 font-weight-bold text-info">Your App Token</h6>
      </a>

        <div className="collapse show" id="collapseCard">
          <div className="card-body">
            <dl className="row">
              <dt className="col-sm-12">
                {app_token}
              </dt>
            </dl>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default ViewAppToken