import * as AdminApi from "../../api/users/admin/AdminApi"

export const searcher = async (user_type, name) => {
  return await AdminApi.searchUsers(user_type, name)
  // .then(res => {
  //   if(res.status === 200) {
  //     return res.data
  //   } else {
  //     return "Cannot find users"
  //   }
  // })
  // .catch(err => {
  //   return err
  // })
}