import React from "react"
import { Redirect, Route } from "react-router-dom"

const AuthMiddleware = ({ component: Component, isAuthProtected, isRoleProtected, AllowedRoles, serverUp, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!serverUp) {
        return <Component {...props} />
      } else {
        if (isAuthProtected && !localStorage.getItem("token")) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        }

        if (isRoleProtected && !AllowedRoles.includes(localStorage.getItem("role"))) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        }

        return <Component {...props} />
      }
    }}
  />
)

export default AuthMiddleware
