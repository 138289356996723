import { useContext, useEffect, useRef, useState, useCallback } from "react"
import { Link, useHistory } from "react-router-dom"

//Imports for topbar, sidebar ad visitor contexts
import { userContext } from "../../context/userContext"
import { roleContext } from "../../context/RoleContext"
import { VisitorContext } from "../../context/VisitorContext"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from "react-animations"

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

import * as ApiUserApi from "../../api/users/api_user/ApiUserApi"
import * as VisitorApi from "../../api/users/visitor/VisitorApi"
import * as RadiologistApi from "../../api/users/radiologist/RadiologistApi"

//Import for notifications
import NotificationManager from "react-notifications/lib/NotificationManager"

import { Button, CardBody, CardFooter, CardHeader, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap"
import { PasswordValidation } from "../../validation/validations"

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`
  animation: 0.9s ${fadeInAnimation};
`

//Initial State
const initialState = {
  currentPassword: '',
  password: "",
  confirmPassword: "",
  showPass: false,
  showConfirmPass: false,
  showCurrentPass: false,
  saving: false,
  errorPassword: "",
  errorPasswordIsOpen: false,
  errorConfirmPassword: "",
  errorConfirmPasswordIsOpen: false,
  errorCurrentPassword: "",
  errorCurrentPasswordIsOpen: false
}

const ChangePassword = props => {
  const history = useHistory()

  //Contexts for sidebar and topbar
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const [loading, setLoading] = useState(false)

  const [password, setPassword] = useState(initialState.password)
  const [currentPassword, setCurrentPassword] = useState(initialState.currentPassword)
  const [confirmPassword, setConfirmPassword] = useState(initialState.confirmPassword)
  const [showPass, setShowPass] = useState(initialState.showPass)
  const [showCurrentPass, setShowCurrentPass] = useState(initialState.showCurrentPass)
  const [showConfirmPass, setShowConfirmPass] = useState(initialState.showConfirmPass)

  //Runs on first render
  useEffect(() => {
    setVisible(true)
    setRole(localStorage.getItem("role"))

    // if (role === "apiUser") {
    //   ApiUserApi.getApiUser(localStorage.getItem("user_id"))
    //     .then(res => {
    //       setCurrentPassword(res.password)
    //     })
    // } else if (role === "visitor") {
    //   VisitorApi.getVisitor(localStorage.getItem("user_id"))
    //     .then(res => {
    //       setCurrentPassword(res.password)
    //     })
    // } else if (role === 'radiologist') {
    //   RadiologistApi.getRadiologist(localStorage.getItem("user_id"))
    //     .then(res => {
    //       setCurrentPassword(res.password)
    //     })
    // }
  }, [])

  //Handler for change in values for visitor profile
  const handleChange = event => {
    const { name, value } = event.target

    if (name === "password") {
      PasswordValidation(event)
      setPassword(value)
    } else if (name === "confirmPassword") {
      PasswordValidation(event)
      setConfirmPassword(value)
    } else if (name === "currentPassword") {
      PasswordValidation(event)
      setCurrentPassword(value)
    }
  }

  const handleChangePassword = () => {
    setLoading(true)

    const userFormData = new FormData()

    userFormData.append('user_id', localStorage.getItem("user_id"))
    userFormData.append('current_password', currentPassword)
    userFormData.append('new_password', password)
    userFormData.append('confirm_password', confirmPassword)

    if (role === "apiUser") {
      ApiUserApi.resetPassword(userFormData)
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            NotificationManager.error("Password changed Successfully", "Success", 1000)
            history.push('/api-user')
          } else {
            NotificationManager.error("Something went wrong", "Error", 1000)
            setLoading(false)
          }
        })
    } else if (role === "visitor") {
      VisitorApi.resetPassword(userFormData)
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            NotificationManager.error("Password changed Successfully", "Success", 1000)
            history.push('/visitor')
          } else {
            NotificationManager.error("Something went wrong", "Error", 1000)
            setLoading(false)
          }
        })
    } else if (role === "radiologist") {
      RadiologistApi.resetPassword(userFormData)
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            NotificationManager.error("Password changed Successfully", "Success", 1000)
            history.push('/radiologist')
          } else {
            NotificationManager.error("Something went wrong", "Error", 1000)
            setLoading(false)
          }
        })
    }
  }

  return (
    <Col md="6" style={{ width: "35%", left: "28%" }}>
      <FadeInDiv className='card shadow mb-4'>
        <CardHeader>
          <h3 className='text-center'>Change Password</h3>
        </CardHeader>
        <CardBody>
          <div>
            <Label htmlFor='currentPassInput'>Current Password</Label>
            <InputGroup>
              <Input id='currentPassInput' name='currentPassword' type={showCurrentPass ? "text" : "password"} onChange={handleChange} value={currentPassword} />
              <InputGroupAddon addonType='append'>
                <InputGroupText onClick={() => setShowCurrentPass(!showCurrentPass)}>
                  {showCurrentPass ? <AiOutlineEye size='19' /> : <AiOutlineEyeInvisible size='19' />}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>

          <div className='mt-3'>
            <Label htmlFor='newPassInput'>New Password</Label>
            <InputGroup>
              <Input id='newPassInput' name='password' type={showPass ? "text" : "password"} onChange={handleChange} value={password} />
              <InputGroupAddon addonType='append'>
                <InputGroupText onClick={() => setShowPass(!showPass)}>
                  {showPass ? <AiOutlineEye size='19' /> : <AiOutlineEyeInvisible size='19' />}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>

          <div className='mt-3'>
            <Label htmlFor='confirmPassInput'>Confirm Password</Label>
            <InputGroup>
              <Input
                id='confirmPassInput'
                name='confirmPassword'
                type={showConfirmPass ? "text" : "password"}
                onChange={handleChange}
                value={confirmPassword}
              />
              <InputGroupAddon addonType='append'>
                <InputGroupText onClick={() => setShowConfirmPass(!showConfirmPass)}>
                  {showConfirmPass ? <AiOutlineEye size='19' /> : <AiOutlineEyeInvisible size='19' />}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </CardBody>
        <CardFooter>
          <Button disabled={loading} outline color='primary' onClick={handleChangePassword}>
            {loading ? "Changing..." : "Change Password"}
          </Button>
        </CardFooter>
      </FadeInDiv>
    </Col>
  )
}

export default ChangePassword
