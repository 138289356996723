import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"

//Imports for topbar and visitor Contexts
import { VisitorContext } from "../../../context/VisitorContext"
import { userContext } from "../../../context/userContext"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import { getListOfVisitorXrays } from "../../../api/users/visitor/VisitorApi"

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${ fadeInAnimation };`

function VisitorXrayList(props) {
  const { 
    visitorXrays,
    initialState
  } = props

  //Contexts for topbar
  const { visible, setVisible } = useContext(userContext)

  const history = useHistory()
  const firstUpdate = useRef(true)

  //UseEffect for first time render
  useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <div className="card-header py-3">
        <div className='row'>
          <div className='col-lg-8'>
            <h6 className="m-0 font-weight-bold text-primary">
              Visitor Xrays
            </h6>
          </div>
        </div>
      </div>
      
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-bordered text-center"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Visitor Id</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {visitorXrays.map((visitorXray, i) => (
                <tr key={i}>
                  <td>
                    {visitorXray.visitor_id}
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: "admin/visitor-xray-feedback/" + visitorXray._id,
                        data: {
                          visitorXray_data: visitorXray
                        }
                      }}
                    >
                      Provide Feedback
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default VisitorXrayList

