import { useContext, useEffect, useRef, useState, useCallback } from "react"
import { Link, useHistory } from "react-router-dom"

//Imports for topbar, sidebar ad visitor contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"
import { VisitorContext } from "../../../context/VisitorContext"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

import * as VisitorApi from '../../../api/users/visitor/VisitorApi'

//Import for notifications
import NotificationManager from "react-notifications/lib/NotificationManager"

import Cropper from "react-easy-crop"
import getCroppedImg from '../../utility/ImageCropper'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  visitor: {
    _id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    photo: "",
    status: true
  },
  passVisible: false,
  mouseEntered: false,
  image: null,
  croppedImage: null,
  saving: false,
  errorName: "",
  errorPassword: "",
  errorTelephone: "",
  errorFirstNameIsOpen: false,
  errorLastNameIsOpen: false,
  errorPasswordIsOpen: false,
  errorTelephoneIsOpen: false,
}

//Regular Expressions for validations
const nameRegExPattern = new RegExp(/[0-9]/)
const specialCharactersRegExPattern = new RegExp(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/)
const telephoneRegExPattern = new RegExp(/[a-zA-Z]/)


const VisitorProfile = (props) => {

  const history = useHistory()
  const reader = new FileReader()

  //Contexts for sidebar and topbar
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [visitor, setVisitor] = useState(initialState.visitor)

  const [saving, setSaving] = useState(initialState.saving)

  const [mouseEntered, setMouseEntered] = useState(initialState.mouseEntered)
  const [passVisible, setPassVisible] = useState(initialState.passVisible)
  const [image, setImage] = useState(initialState.image)
  const [croppedImage, setCroppedImage] = useState(initialState.croppedImage)

  //Initialization for visitor attributes
  const [name, setName] = useState(initialState.visitor.name)
  const [phone, setTelephone] = useState(initialState.visitor.phone)
  const [email, setEmail] = useState(initialState.visitor.email)
  const [password, setPassword] = useState(initialState.visitor.password)
  const [photo, setPhoto] = useState(initialState.visitor.photo)
  const [status, setStatus] = useState(initialState.visitor.status)

  //Initialization for errors in fields
  const [errorName, setErrorName] = useState(initialState.errorName)
  const [errorPassword, setErrorPassword] = useState(initialState.errorPassword)
  const [errorTelephone, setErrorTelephone] = useState(initialState.errorTelephone)
  const [errorPasswordIsOpen, setErrorPasswordIsOpen] = useState(initialState.errorPasswordIsOpen)
  const [errorNameIsOpen, setErrorNameIsOpen] = useState(initialState.errorNameIsOpen)
  const [errorTelephoneIsOpen, setErrorTelephoneIsOpen] = useState(initialState.errorTelephoneIsOpen)


  //Ref hook for preventing useEffect hook from running first time
  const firstUpdate = useRef(true)
  const firstImageUpdate = useRef(true)

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //   console.log(croppedArea, croppedAreaPixels)
  // }, [])

  //Runs on first render
  useEffect(() => {
    setVisible(true)
    setRole(localStorage.getItem('role'))


    VisitorApi.getVisitor(localStorage.getItem("user_id"))
      .then(res => {
        setVisitor(res)
      })
      .catch(err => {
        NotificationManager.error(`Something went wrong: ` + err, "Error", 1000)
      })
    // setVisitor(JSON.parse(localStorage.getItem('visitor_detail')))

  }, [])

  //Runs when visitor is changed
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    } else {
      console.log(visitor)

      setName(visitor.name)
      setEmail(visitor.email)
      setTelephone(visitor.phone)
      setPassword(visitor.password)
      setPhoto(visitor.photo)
      setStatus(visitor.status)

      // setImage(visitor.profile_pic)
    }
  }, [visitor])

  //Reads the image uploaded
  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  //Handler for profile picture change
  const onImageChange = async (event) => {
    let imageDataUrl = await readFile(event.target.files[0])
    setImage(imageDataUrl)
  }

  //Runs everytime user crops the image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
    localStorage.setItem(
      'croppedAreaPixels',
      JSON.stringify(croppedAreaPixels)
    )
  }, [])

  //Runs when user clicks the "Crop Image" button
  const handleCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      console.log('done', { croppedImage })

      setCroppedImage(croppedImage)

    } catch (e) {
      console.error(e)
    }
  }

  //Runs when croppedImage is changed
  useEffect(() => {
    console.log(croppedImage)

    if (firstImageUpdate.current) {
      firstImageUpdate.current = false
      return
    } else {
      setSaving(true)
      console.log(visitor)

      let file = new File([croppedImage], "profile");

      console.log(file)
      const visitorFormData = new FormData()

      visitorFormData.append("_id", visitor._id)
      visitorFormData.append("lastName", visitor.name)
      visitorFormData.append("email", visitor.email)
      visitorFormData.append("phone", visitor.phone)
      visitorFormData.append("password", visitor.password)
      visitorFormData.append("photo", croppedImage === null ? visitor.photo : croppedImage)
      visitorFormData.append("status", visitor.status)

      console.log(...visitorFormData)

      VisitorApi.updateVisitor(visitorFormData)
        .then(res => {
          setSaving(initialState.saving)

          NotificationManager.success(`${visitor.name}'s info has been successfully updated.`, 'Success', 1000)
          history.push('/visitor')
          console.log(res)

          var visitor_detail = {
            _id: visitorFormData.get('_id'),
            name: visitorFormData.get('name'),
            email: visitorFormData.get('email'),
            phone: visitorFormData.get('phone'),
            password: visitorFormData.get('password'),
            status: visitorFormData.get('status'),
            photo: visitorFormData.get('photo')
          }

          console.log(visitor_detail)

          localStorage.setItem('visitor_detail', JSON.stringify(visitor_detail))
          localStorage.setItem('user_name', visitor_detail.name)

          setVisitor(visitor_detail)
        })
        .catch(err => {
          setSaving(initialState.saving)

          NotificationManager.error(`Something went wrong while updating ${visitor.name}: ` + err, "Error", 1000)
        })

      // setProfilePic(croppedImage)
    }

  }, [croppedImage])

  //Update Visitor Handler
  const handleUpdateVisitor = () => {
    setSaving(true)
    console.log(visitor)

    const visitorFormData = new FormData()

    visitorFormData.append("_id", visitor._id)
    visitorFormData.append("name", visitor.name)
    visitorFormData.append("email", visitor.email)
    visitorFormData.append("phone", visitor.phone)
    visitorFormData.append("password", visitor.password)
    visitorFormData.append("photo", visitor.photo)
    visitorFormData.append("status", visitor.status)

    console.log(...visitorFormData)

    VisitorApi.updateVisitor(visitorFormData)
      .then(res => {
        setSaving(initialState.saving)

        NotificationManager.success(`${visitor.name}'s info has been successfully updated.`, "Success", 1000)
        history.push('/visitor')
        console.log(res)

        var visitor_detail = {
          _id: visitorFormData.get('_id'),
          name: visitorFormData.get('name'),
          email: visitorFormData.get('email'),
          phone: visitorFormData.get('phone'),
          password: visitorFormData.get('password'),
          status: visitorFormData.get('status'),
          profile_pic: visitorFormData.get('profile_pic')
        }

        localStorage.setItem('visitor_detail', JSON.stringify(visitor_detail))
        localStorage.setItem('user_name', visitor_detail.name)

        setVisitor(visitor_detail)
      })
      .catch(err => {
        setSaving(initialState.saving)

        NotificationManager.error(`Something went wrong while updating ${visitor.name}: ` + err, "Error", 1000)
      })
  }

  //Handler for change in values for visitor profile
  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "name") {
      nameValidation(event)

      setVisitor((prevVisitor) => ({
        ...prevVisitor,
        [name]: value,
      }))
    } else if (name === "phone") {
      telephoneValidation(event)

      setVisitor((prevVisitor) => ({
        ...prevVisitor,
        [name]: value,
      }))
    }
  }

  //validate name fields
  const nameValidation = (event) => {
    const { name, value } = event.target

    if (name === 'name') {
      if (value.length === 0) {
        setErrorName("Empty Field")
        setErrorNameIsOpen(true)
        return false
      } else if (value.length > 0 && value.length < 3) {
        setErrorName("Name must be atleast 3 characters long")
        setErrorNameIsOpen(true)
        return false
      } else if (nameRegExPattern.test(value)) {
        console.log(value)
        setErrorName("Numeric values are not allowed")
        setErrorNameIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorName("Special Characters are not allowed")
        setErrorNameIsOpen(true)
        return false
      } else {
        setErrorName("")
        setErrorNameIsOpen(false)
        return true
      }
    }
  }

  //validate phone Field
  const telephoneValidation = (event) => {
    const { name, value } = event.target

    if (value.length === 0) {
      setErrorTelephone("Phone Number is required")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (value.length < 11) {
      setErrorTelephone("Phone Number must contain 11 digits")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (telephoneRegExPattern.test(value)) {
      setErrorTelephone("Alphabet cannot be present in Phone number")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (specialCharactersRegExPattern.test(value)) {
      setErrorTelephone("Special Characters are not allowed")
      setErrorTelephoneIsOpen(true)
      return false
    } else {
      setErrorTelephone("")
      setErrorTelephoneIsOpen(false)
      return true
    }
  }

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "65%", left: "16%" }}>
      <div className="card-body row">
        <div className="col-lg-6 text-center">
          <div
            style={{
              borderRadius: '50%',
              height: '200px',
              width: '200px',
              position: 'absolute',
              left: '140px'
            }}
            onMouseEnter={() => setMouseEntered(true)}
            onMouseLeave={() => setMouseEntered(false)}
          >
            {mouseEntered ?
              <>
                <img
                  style={{
                    filter: 'blur(8px)',
                    WebkitFilter: 'blur(8px)',
                    animationTimingFunction: 'linear',
                    transition: 'all 0.2s ease-in-out'
                  }}
                  className="rounded-circle"
                  height='200px'
                  width="200px"
                  src={photo === "" || photo === undefined || photo === null ? "/img/undraw_profile.svg" : photo}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '80px',
                    right: '80px',
                    borderRadius: '50%',
                    color: '#59b1a7'
                  }}
                >
                  <label htmlFor='imageInput' style={{ cursor: "pointer" }}>
                    <i className='far fa-edit fa-2x'></i>
                  </label>
                  <input type="file" id='imageInput' onChange={onImageChange} style={{ display: 'none' }} />
                </div>
              </> :
              <img
                style={{
                  animationTimingFunction: 'linear',
                  transition: 'all 0.2s ease-in-out'
                }}
                className="rounded-circle"
                height='200px'
                width="200px"
                src={visitor.photo === "" ? "/img/undraw_profile.svg" : visitor.photo}
              />
            }
          </div>
        </div>

        <div className="col-lg-6">
          <div className="row mb-1">
            <div className="col-md-3 font-weight-bold">
              <label>Name</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={visitor.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            {errorNameIsOpen ?
              <FadeInDiv
                className='alert alert-danger mt-1'
                role='alert'
              >
                {errorName}
              </FadeInDiv> :
              <></>
            }
          </div>

          <div className="row mb-1">
            <div className="col-md-3 font-weight-bold">
              <label>Email</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={visitor.email}
                disabled
                readOnly
              />
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-3 font-weight-bold">
              <label>Phone</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={visitor.phone}
                name="phone"
                onChange={handleChange}
              />
            </div>
            {errorTelephoneIsOpen ?
              <FadeInDiv
                className='alert alert-danger mt-1'
                role='alert'
              >
                {errorTelephone}
              </FadeInDiv> :
              <></>
            }
          </div>

          <div className="row mb-1">
            <div className="col-md-3 font-weight-bold">
              <label>Password</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                type={passVisible ? "text" : "password"}
                readOnly
                disabled
                value={visitor.password}
              />
            </div>
            <div className="small mt-2">
              <i
                style={{ color: '#59b1a7' }}
                onClick={() => setPassVisible(prevState => !prevState)}
                className={passVisible ? "far fa-eye" : "far fa-eye-slash"}
              ></i>
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-3 font-weight-bold">
              <label>Status</label>
            </div>
            <div className="col-md-6">
              {visitor.status ?
                <p className="text-success font-weight-bold text-center">
                  Active
                </p> :
                <p className="text-danger font-weight-bold text-center">
                  Suspended
                </p>
              }
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-6" style={{ left: "90px" }}>
              {saving ?
                <button
                  style={{ borderRadius: '50px' }}
                  type="button"
                  className="btn btn-outline-success btn-block"
                  disabled
                >
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                  Updating
                </button> :
                <button
                  type="button"
                  style={{ borderRadius: '50px' }}
                  className="btn btn-outline-primary btn-block"
                  onClick={handleUpdateVisitor}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square mr-2" viewBox="0 0 16 17">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                  Update
                </button>
              }
            </div>
          </div>
        </div>

        <div>
          {image === null ?
            <></> : (
              <>
                <FadeInDiv>
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onRotationChange={setRotation}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </FadeInDiv>

                <div
                  style={{ position: 'absolute', left: "910px", top: '10px' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-circle text-white"
                    style={{ cursor: "pointer" }}
                    viewBox="0 0 16 16"
                    onClick={() => setImage(initialState.image)}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <div className="text-center" style={{ position: 'absolute', left: "423px", top: '250px' }}>
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{ borderRadius: '50px' }}
                    onClick={handleCroppedImage}
                  >
                    <i className="fas fa-crop-alt mr-2"></i>
                  Crop Image
                </button>
                </div>
              </>
            )}
        </div>
      </div>
    </FadeInDiv>
  )
}

export default VisitorProfile