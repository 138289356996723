import AdminLogin from "../components/auth/admin/AdminLogin"
import APIUserLogin from "../components/auth/api-user/APIUserLogin"
import RadiologistLogin from "../components/auth/radiologist/RadiologistLogin"
import VisitorLogin from "../components/auth/visitor/VisitorLogin"

import Admin from "../components/users/admin/Admin"
import XrayFeedbackPage from "../components/users/admin/XrayFeedbackPage"

import APIUser from "../components/users/api-user/APIUser"
import ApiUserPage from "../components/users/api-user/ApiUserPage"
import ApiUserProfile from "../components/users/api-user/ApiUserProfile"
import M1M2DiagnosisDocumentation from "../components/users/api-user/documentation/diagnosis/M1M2DiagnosisDocumentation"
import CovidDiagnosisDocumentation from "../components/users/api-user/documentation/diagnosis/CovidDiagnosisDocumentation"

import Model1FeedbackDocumentation from "../components/users/api-user/documentation/feedback/Model1FeedbackDocumentation"
import Model2FeedbackDocumentation from "../components/users/api-user/documentation/feedback/Model2FeedbackDocumentation"
import CovidFeedbackDocumentation from "../components/users/api-user/documentation/feedback/CovidFeedbackDocumentation"

import ManageApiUserPage from "../components/users/api-user/ManageApiUserPage"
import ViewAPIUser from "../components/users/api-user/ViewAPIUser"
import ViewAppToken from "../components/users/api-user/ViewAppToken"

import ManageRadiologistPage from "../components/users/radiologist/ManageRadiologistPage"
import Radiologist from "../components/users/radiologist/Radiologist"
import RadiologistPage from "../components/users/radiologist/RadiologistPage"
import RadiologistProfile from "../components/users/radiologist/RadiologistProfile"
import ViewRadiologist from "../components/users/radiologist/ViewRadiologist"
import VisitorDiagnosisHistoryPage from "../components/users/visitor/VisitorDiagnosisHistoryPage"

import ManageVisitorPage from "../components/users/visitor/ManageVisitorPage"
import ViewVisitor from "../components/users/visitor/ViewVisitor"
import Visitor from "../components/users/visitor/Visitor"
import VisitorPage from "../components/users/visitor/VisitorPage"
import VisitorProfile from "../components/users/visitor/VisitorProfile"
import XrayDetailPage from "../components/utility/XrayDetailPage"

import ChangePassword from "../components/utility/ChangePassword"
import LandingPage from "../components/utility/LandingPage"
import RadiologistDiagnosisHistoryPage from "../components/users/radiologist/RadiologistDiagnosisHistoryPage"

const userRoutes = [
  { path: "/admin-login", component: AdminLogin },
  { path: "/visitor-login", component: VisitorLogin },
  { path: "/radiologist-login", component: RadiologistLogin },
  { path: "/api-user-login", component: APIUserLogin },
  { path: "/change-password", component: ChangePassword },
  { path: "/", exact: true, component: LandingPage },
]

const adminRoutes = [
  { path: "/admin", component: Admin },
  { path: "/admin/visitor-xray-feedback/:id", component: XrayFeedbackPage },

  { path: "/admin/api-users/:id/edit", component: ManageApiUserPage },
  { path: "/admin/api-users/add", component: ManageApiUserPage },
  { path: "/admin/api-users/:id/view", component: ViewAPIUser },

  { path: "/admin/radiologists/:id/edit", component: ManageRadiologistPage },
  { path: "/admin/radiologists/add", component: ManageRadiologistPage },
  { path: "/admin/radiologists/:id/view", component: ViewRadiologist },

  { path: "/admin/visitors/:id/edit", component: ManageVisitorPage },
  { path: "/admin/visitors/add", component: ManageVisitorPage },
  { path: "/admin/visitors/:id/view", component: ViewVisitor },

  { path: "/admin/visitors", component: VisitorPage },
  { path: "/admin/radiologists", component: RadiologistPage },
  { path: "/admin/api-users", component: ApiUserPage }
]

const radiologistRoutes = [
  { path: "/radiologist", component: Radiologist },
  { path: "/radiologist/:id/profile", component: RadiologistProfile },
  { path: "/radiologist/:id/history", component: RadiologistDiagnosisHistoryPage }
]

const visitorRoutes = [
  { path: "/visitor", component: Visitor },
  { path: "/visitor/:id/profile", component: VisitorProfile },
  { path: "/visitor/:id/history", component: VisitorDiagnosisHistoryPage },
]

const apiUserRoutes = [
  { path: "/api-user", component: APIUser },
  { path: "/api-user/:id/profile", component: ApiUserProfile },
  { path: "/api-user/model1-model2-diagnosis-api", component: M1M2DiagnosisDocumentation },
  { path: "/api-user/covid-diagnosis-api", component: CovidDiagnosisDocumentation },
  { path: "/api-user/model1-feedback-api", component: Model1FeedbackDocumentation },
  { path: "/api-user/model2-feedback-api", component: Model2FeedbackDocumentation },
  { path: "/api-user/covid-feedback-api", component: CovidFeedbackDocumentation },
  { path: "/api-user/app-token", component: ViewAppToken },
]

const xrayDetailRoutes = [
  { path: "/:id/xray-detail", component: XrayDetailPage }
]

export { adminRoutes, radiologistRoutes, visitorRoutes, apiUserRoutes, userRoutes, xrayDetailRoutes }
