import { useContext, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

import { VisitorContext } from "../../../context/VisitorContext"

import * as VisitorApi from '../../../api/users/visitor/VisitorApi'

import NotificationManager from "react-notifications/lib/NotificationManager"

import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

const initialState = {
  visitor: {
    _id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    profile_pic: "",
    status: true
  }
}

const ViewVisitor = (props) => {

  const visitorId = props.match.params.id

  const history = useHistory()
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const { contextVisitor, setContextVisitor } = useContext(VisitorContext)
  const [visitor, setVisitor] = useState(initialState.visitor)
  const [loading, setLoading] = useState(false)

  const firstVisitorUpdate = useRef(true)
  const firstContextVisitorUpdate = useRef(true)

  useEffect(() => {
    setVisible(true)
    setRole(localStorage.getItem('role'))

    /*Setup for api calling for single user on page load*/

    VisitorApi.getVisitor(visitorId)
      .then(res => {
        setVisitor(res)
        setContextVisitor(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (contextVisitor.status !== visitor.status) {
      VisitorApi.updateVisitor(contextVisitor)
        .then(res => {
          if (res.status === true) {
            setVisitor({ ...visitor, res })
            NotificationManager.success(`${contextVisitor.firstName + ' ' + contextVisitor.lastName} has been reinstated`, 'Success', 1000)
          }
          else
            NotificationManager.success(`${contextVisitor.firstName + ' ' + contextVisitor.lastName} has been suspended`, 'Success', 1000)

          setTimeout(() => {
            history.push('/users/visitors')
          }, 1000)

          console.log(res)
        })
        .catch(err => NotificationManager.error(`Something went wrong while suspending ${contextVisitor.firstName + ' ' + contextVisitor.lastName}: ` + err, 'Error', 1000))
    }
  }, [contextVisitor])

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <div className="card-header py-3">
        <h5 className="ml-1 h4">
          Visitor
        </h5>
        <div className='row'>
          <h6 className="ml-3 h2 text-primary">
            {visitor.name}
          </h6>
          <div style={{ width: '100%' }}>
            <Link
              to={{
                pathname: "/admin/visitors/" + visitor.visitor_id + "/edit",
                data: {
                  visitor_data: visitor
                }
              }}
            >
              <button
                className="btn btn-primary btn-sm float-right"
                type="button"
                style={{ marginRight: "2%", marginLeft: "2%" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 1 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>{"  "}
                Update
              </button>
            </Link>
            {visitor.status ?
              <button
                className="btn btn-danger btn-sm float-right"
                type="button"
                onClick={() => setContextVisitor({ ...contextVisitor, status: false })}
              >
                <svg
                  style={{ cursor: 'pointer' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  fill="currentColor"
                  className="bi bi-person-x"
                  viewBox="0 1 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path fillRule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                </svg>{"  "}
                Suspend
              </button> :
              <button
                className="btn btn-success btn-sm float-right"
                type="button"
                onClick={() => setContextVisitor({ ...contextVisitor, status: true })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-person-check mr-1"
                  viewBox="0 1 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                </svg>
                Reinstate
              </button>
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card-body text-center">
          <img
            id="image-preview"
            style={{ maxHeight: '300px', maxWidth: '500px' }}
            src={visitor.photo !== null || undefined || "" ? visitor.photo : ""}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-bordered text-center"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <tbody>
                <tr>
                  <th className="font-weight-bold">Name</th>
                  <th>{visitor.name === "" ? 'Fetching Data...' : visitor.name}</th>
                </tr>
                <tr>
                  <th className="font-weight-bold">Email</th>
                  <th>{visitor.email === "" ? 'Fetching Data...' : visitor.email}</th>
                </tr>
                <tr>
                  <th className="font-weight-bold">Phone</th>
                  <th>{visitor.phone === "" ? 'Fetching Data...' : visitor.phone}</th>
                </tr>
                <tr>
                  <th className="font-weight-bold">Password</th>
                  <th>{visitor.password === "" ? 'Fetching Data...' : visitor.password}</th>
                </tr>
                <tr>
                  <th className="font-weight-bold">Status</th>
                  {visitor.status === "" || visitor.status ?
                    <th className="text-success">
                      {visitor.status === "" ? 'Fetching Data...' : "Active"}
                    </th> :
                    <th className="text-danger">
                      {visitor.status === "" ? 'Fetching Data...' : "Suspended"}
                    </th>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default ViewVisitor