import { useContext, useEffect, useState } from "react"

import { userContext } from "../../../../../context/userContext"
import { roleContext } from "../../../../../context/RoleContext"

// import M2Feedback from "../../../../assets/m2Feedback.png"

import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader"

import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from 'react-animations'
import { Link } from "react-router-dom"

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`animation:  0.9s ${ fadeInAnimation };`
const FadeInRightDiv = styled.div`animation:  0.9s ${ fadeInRightAnimation };`

const initialState = {
  loading: false,
}

const Model2FeedbackDocumentation = (props) => {

  //Declaration and initialization for sidebar and topbar contexts
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [loading, setLoading] = useState(initialState.loading)

  //Runs when component loads for first time
  useEffect(() => {
    if(localStorage.getItem('token') && localStorage.getItem('role') === 'apiUser') {
      setVisible(true)
      setRole(localStorage.getItem('role'))
      setLoading(false)
    } else {
      props.history.push('/')
    }
  }, [])

  //Handler for image click
  const handleImageClick = () => {
    var modal = document.getElementById("myModal");

    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");

      modal.style.display = "block";
      // modalImg.src = M2Feedback;

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() { 
      modal.style.display = "none";
    }
  }

  return (
    <FadeInDiv className="container text-dark">
      <h2 className="text-dark">Model 2 Feedback API</h2>

      {/*First Card that holds all details regarding method, endpoint, headers*/}
      <div className="card shadow mb-2 border-left-info">
        <a href="#collapseFeedbackCard" className="d-block card-header py-3" data-toggle="collapse"
            role="button" aria-expanded="true" aria-controls="collapseFeedbackCard">
          <h4 className="m-0 font-weight-bold text-info">Request Detail</h4>
        </a>
        <div className="collapse show" id="collapseFeedbackCard">
          <div className="card-body">
            <dl className="row">
              <dt className="col-sm-2">
                Method:
              </dt>
              <dd className="col-sm-9">
                POST
              </dd>
              <dt className="col-sm-2">
                Endpoint:
              </dt>
              <dd className="col-sm-9">
                http://127.0.0.1:5000/feedback/m2
              </dd>
              <dt className="col-sm-2">
                Content:
              </dt>
              <dd className="col-sm-9">
                json
              </dd>
              <dt className="col-sm-2">
                Headers
              </dt>
              <dd className="col-sm-4">
                <dl>
                  <dt>
                    Authorization: Bearer token
                  </dt>
                  <dt>
                    Content-Type: application/json
                  </dt>
                  <dt>
                    Accept: application/json
                  </dt>
                </dl>
              </dd>    
            </dl>
          </div>
        </div>
      </div>

      {/*Second card where request body is detailed*/}
      <div className="card shadow mb-2 border-left-info">
        <a href="#collapseFeedbackRequestCard" className="d-block card-header py-3" data-toggle="collapse"
            role="button" aria-expanded="true" aria-controls="collapseFeedbackRequestCard">
          <h4 className="m-0 font-weight-bold text-info">Request Body</h4>
        </a>

        <div className="collapse show" id="collapseFeedbackRequestCard">
          <div className="card-body">
            <ul>
              <li className="mb-1">
                <strong>daignose_xray_id:</strong> Unique identifier for the image sent to <Link to="/documentation/diagnosis-api">diagnosis</Link> endpoint.
              </li>
              <li className="mb-1">
              <strong>image:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
              <strong>user_id:</strong> Unique identifier for the user that uploads the image.
              </li>
              <li className="mb-1">
              <strong>user_type:</strong> One string identifier for user type, one of the following: <strong>Radiologist, Visitor</strong>.
              </li>
              <li className="mb-1">
              <strong>cardiomegaly_found:</strong> This is the <Link to="/documentation/diagnosis-api">diagnosis API's</Link> response attribute <strong>m1cardiomegaly</strong>.
              </li>
              <li className="mb-1">
              <strong>cardiomegaly_feedback:</strong> Radiologist's response in string format either <strong>Yes</strong> or <strong>No</strong>, if radiologist agrees with the system's prediction.
              </li>
              <li className="mb-1">
              <strong>pneumonia_found:</strong> This is the <Link to="/documentation/diagnosis-api">diagnosis API's</Link> response attribute <strong>m1pneumonia</strong>.
              </li>
              <li className="mb-1">
              <strong>pneumonia_feedback:</strong> Radiologist's response in string format either <strong>Yes</strong> or <strong>No</strong>, if radiologist agrees with the system's prediction.
              </li>
              <li className="mb-1">
              <strong>tuberclosis_found:</strong> This is the <Link to="/documentation/diagnosis-api">diagnosis API's</Link> response attribute <strong>m1tuberculosis</strong>.
              </li>
              <li className="mb-1">
              <strong>tuberclosis_feedback:</strong> Radiologist's response in string format either <strong>Yes</strong> or <strong>No</strong>, if radiologist agrees with the system's prediction.
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      {/*Response card where a the API Response is detailed when communication is successful*/}
      <div className="card shadow border-left-info mb-2">
        <a href="#collapseFeedbackResponseCard" className="d-block card-header py-3" data-toggle="collapse"
            role="button" aria-expanded="true" aria-controls="collapseFeedbackResponseCard">
          <h4 className="m-0 font-weight-bold text-info">Response Body</h4>
        </a>
        <div className="collapse show" id="collapseFeedbackResponseCard">
          <div className="card-body">
            <ul>
              <li>
                <strong>message:</strong> A string is returned upon successful submission of feedback <strong>Feedback Submitted</strong>.
              </li>
              <li>
                <strong>category:</strong> A string is returned upon successful submission of feedback <strong>success</strong>.
              </li>
              <li>
                <strong>status:</strong> A status code is returned reflecting success or failure .
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*Image card where a postman rendition of the whole procedure is displayed*/}
      <div className="card shadow border-left-info mb-2">
        <a href="#collapseImageCard" className="d-block card-header py-3" data-toggle="collapse"
            role="button" aria-expanded="true" aria-controls="collapseImageCard">
          <h4 className="m-0 font-weight-bold text-info">Postman Visual</h4>
        </a>
        <div className="collapse show" id="collapseImageCard">
          <div className="card-body">
            {/* <img style={{ cursor: 'pointer' }} src={M2Feedback} width="100%" onClick={handleImageClick} id="myImg"/> */}
          </div>
        </div>
      </div>
      <FadeInDiv
        style={{
          display: 'none',
          position: 'fixed',
          zIndex: '1',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.9)',
          paddingTop: '100px',
          left: '0',
          top: '0',
          width: '100%',
          height: '100%'
        }} 
        id="myModal" 
        className="modal"
      >
        <span 
          style={{
            position: 'absolute',
            top: '15px',
            right: '35px',
            color: '#f1f1f1',
            fontSize: '40px',
            fontWeight: 'bold',
            transition: '0.3s',
            cursor: 'pointer'
          }} 
          className="close"
        >
          &times;
        </span>
        <img className="modal-content" id="img01" />
      </FadeInDiv>
    </FadeInDiv>
  )
}

export default Model2FeedbackDocumentation