//Necessary system import
import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"

//Imports for sidebar and APIUser Contexts
import { APIUserContext } from "../../../context/APIUserContext"
import { userContext } from "../../../context/userContext"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${ fadeInAnimation };`

function ApiUsersList(props) {
  const { 
    apiUsers,
    initialState,
    resetAPIUserKey
  } = props

  // const [apiUser, setApiUser] = useState(initialState.apiUser)
  const history = useHistory()
  const {contextAPIUser, setContextAPIUser} = useContext(APIUserContext)
  const [APIUser, setAPIUser] = useState(initialState.apiUser)
  const { visible, setVisible } = useContext(userContext)
  const firstUpdate = useRef(true)

  useEffect(() => {
    setVisible(true)
  }, [])

  //Runs whenever API User changes
  useEffect(() => {
    if(firstUpdate.current) {
      firstUpdate.current = false
      return
    } else
      setContextAPIUser(APIUser)
  }, [APIUser])

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <div className="card-header py-3">
        <div className='row'>
          <div className="col-lg-8">
            <h6 className="m-0 font-weight-bold text-primary">
              API Users
            </h6>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-bordered text-center"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Reg #</th>
                <th>Organization Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {apiUsers.map((apiUser, i) => (
                <tr key={i}>
                  <td>{apiUser.registration_number}</td>
                  <td>
                    <Link
                      onClick={() => {
                        localStorage.setItem('apiUser_data', JSON.stringify(apiUser))
                      }}
                      to={{
                        pathname: `api-users/${apiUser.api_user_id}/view/`,
                        state: {
                          apiUser_data: apiUser
                        }
                      }}
                    >
                      {apiUser.organization_name}
                    </Link>
                  </td>
                  <td>{apiUser.phone}</td>
                  <td>{apiUser.email}</td>
                  <td>
                    <Link
                      to={{
                        pathname: `api-users/${apiUser.api_user_id}/edit`,
                        data: {
                          apiUserData: apiUser
                        }
                      }}
                    >
                      <svg 
                        style={{ marginRight: "8%" }} 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="25" 
                        height="25" 
                        fill="currentColor" 
                        className="bi bi-pencil-square" 
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
                    </Link>
                    { apiUser.status ?
                      <svg
                        style={{ cursor: 'pointer', marginRight: "8%"  }}
                        xmlns="http://www.w3.org/2000/svg" 
                        width="25" 
                        height="25" 
                        fill="currentColor" 
                        className="bi bi-person-x text-danger" 
                        viewBox="0 0 16 16"
                        onClick={() => {
                          setAPIUser(apiUser)
                          setAPIUser({...apiUser, status: false})
                        }}
                      >
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                        <path fillRule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                      </svg>:
                      <svg
                        style={{ cursor: "pointer", marginRight: "8%" }}
                        onClick={() => {
                            setAPIUser(apiUser)
                            setAPIUser({...apiUser, status: true})
                          }}
                        xmlns="http://www.w3.org/2000/svg" 
                        width="25" 
                        height="25" 
                        fill="currentColor" 
                        className="bi bi-person-check text-success" 
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                        <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                      </svg>
                    }

                    <svg
                      style={{ cursor: 'pointer' }}
                      xmlns="http://www.w3.org/2000/svg" 
                      width="25" 
                      height="25" 
                      fill="currentColor" 
                      className="bi bi-arrow-counterclockwise text-warning" 
                      viewBox="0 0 16 16"
                      onClick={() => {
                        resetAPIUserKey(apiUser.email, apiUser.app_token)
                      }}
                    >
                      <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                    </svg>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default ApiUsersList
