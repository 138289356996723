import React from "react"
import { Link } from "react-router-dom"
import appLogo from "../../../assets/applogo.png"

const Sidebar = (props) => {
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/*Conditionally render sidebar contents based on user role via ternary operator*/}
      {localStorage.getItem('role') === 'admin' ?
        <>
          <Link
            className="sidebar-brand d-flex align-items-center justify-content-center"
            to="/admin"
          >
            <div className="sidebar-brand-text mx-3">
              <img src={appLogo} width="150" />
            </div>
          </Link>

          <hr className="sidebar-divider my-0" />

          <li className="nav-item active">
            <Link className="nav-link" to="/admin">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <hr className="sidebar-divider" />

          <div className="sidebar-heading">Admin</div>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people-fill" viewBox="0 1 16 16">
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                <path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>{" "}
              <span>Users</span>
            </a>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Manage</h6>
                <Link className="collapse-item" to="/admin/api-users">
                  API Users
                </Link>
                <Link className="collapse-item" to="/admin/radiologists">
                  Radiologists
                </Link>
                <Link className="collapse-item" to="/admin/visitors">
                  Visitors
                </Link>
              </div>
            </div>
          </li>
        </> :
        <>
          <Link
            className="sidebar-brand d-flex align-items-center justify-content-center"
            to="/api-user"
          >
            <div className="sidebar-brand-text mx-3">
              <img src={appLogo} width="150" />
            </div>
          </Link>

          <hr className="sidebar-divider my-0" />

          <li className="nav-item active">
            <Link className="nav-link" to="/api-user">
              <i className="fas fa-book mr-2"></i>
              <span>API Documentation</span>
            </Link>
          </li>

          <li className="nav-item active">
            <Link className="nav-link" to="/api-user/app-token">
              <i className="fas fa-book mr-2"></i>
              <span>App Token</span>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i class="fas fa-code"></i>{" "}
              <span>Diagnosis API</span>
            </a>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Model 1 & Model 2</h6>
                <Link className="collapse-item" to="/api-user/model1-model2-diagnosis-api">
                  POST Request
                </Link>
                <h6 className="collapse-header">COVID-19</h6>
                <Link className="collapse-item" to="/api-user/covid-diagnosis-api">
                  POST Request
                </Link>
              </div>

            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <i class="fas fa-code"></i>{" "}
              <span>Feedback API</span>
            </a>
            <div
              id="collapseOne"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Model 1 Feedback</h6>
                <Link className="collapse-item" to="/api-user/model1-feedback-api">
                  POST Request
                </Link>

                <h6 className="collapse-header">Model 2 Feedback</h6>
                <Link className="collapse-item" to="/api-user/model2-feedback-api">
                  POST Request
                </Link>

                <h6 className="collapse-header">COVID-19 Feedback</h6>
                <Link className="collapse-item" to="/api-user/covid-feedback-api">
                  POST Request
                </Link>
              </div>
            </div>
          </li>
        </>
      }

      {/* <li className="nav-item">
        <a
          className="nav-link collapsed"
          href="#"
          data-toggle="collapse"
          data-target="#collapseUtilities"
          aria-expanded="true"
          aria-controls="collapseUtilities"
        >
          <i className="fas fa-fw fa-wrench"></i>
          <span>Utilities</span>
        </a>
        <div
          id="collapseUtilities"
          className="collapse"
          aria-labelledby="headingUtilities"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Custom Utilities:</h6>
            <a className="collapse-item" href="utilities-color.html">
              Colors
            </a>
            <a className="collapse-item" href="utilities-border.html">
              Borders
            </a>
            <a className="collapse-item" href="utilities-animation.html">
              Animations
            </a>
            <a className="collapse-item" href="utilities-other.html">
              Other
            </a>
          </div>
        </div>
      </li>

      <hr className="sidebar-divider" />

      <div className="sidebar-heading">Addons</div>

      <li className="nav-item">
        <a
          className="nav-link collapsed"
          href="#"
          data-toggle="collapse"
          data-target="#collapsePages"
          aria-expanded="true"
          aria-controls="collapsePages"
        >
          <i className="fas fa-fw fa-folder"></i>
          <span>Pages</span>
        </a>
        <div
          id="collapsePages"
          className="collapse"
          aria-labelledby="headingPages"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Login Screens:</h6>
            <a className="collapse-item" href="login.html">
              Login
            </a>
            <a className="collapse-item" href="register.html">
              Register
            </a>
            <a className="collapse-item" href="register.html">
              Register
            </a>
            <a className="collapse-item" href="forgot-password.html">
              Forgot Password
            </a>
            <div className="collapse-divider"></div>
            <h6 className="collapse-header">Other Pages:</h6>
            <a className="collapse-item" href="404.html">
              404 Page
            </a>
            <a className="collapse-item" href="blank.html">
              Blank Page
            </a>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="charts.html">
          <i className="fas fa-fw fa-chart-area"></i>
          <span>Charts</span>
        </a>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="tables.html">
          <i className="fas fa-fw fa-table"></i>
          <span>Tables</span>
        </a>
      </li>

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div> */}
    </ul>
  )
}

export default Sidebar
