import { get, post } from "../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const xrayDiagnosisEndpoint = REACT_APP_API_URL + "/diagnose/"
const covidDiagnosisEndpoint = REACT_APP_API_URL + "/diagnose/covid"

//Api handler for xray analysis via Fetch API using FormData
export const xrayDiagnoseApi = async (xrayFormData) => {
  return await post(xrayDiagnosisEndpoint, xrayFormData, { 'Authorization': localStorage.getItem('token') })
}

//Api handler for covid analysis via Fetch API using FormData
export const covidDiagnoseApi = async (xrayFormData) => {
  return await post(covidDiagnosisEndpoint, xrayFormData, { 'Authorization': localStorage.getItem('token') })
}

//Api handler for xray analysis concerning radiologist via Fetch API using FormData
// export const xrayDiagnoseRadiologistApi = async(xrayFormData) => {
//   return await post(radiologistXrayDiagnosisEndpoint, xrayFormData, { 'Authorization': localStorage.getItem('token') })

  // const response = await fetch(REACT_APP_API_URL + "/radiologist/radiologist_daignose_Xray", {
  //   method: 'POST',
  //   headers: {
  //     'Authorization': localStorage.getItem('token')
  //   },
  //   body: xrayFormData
  // })
  // return await response.json()
// }