import { useContext, useEffect, useState } from "react"

import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

import { covidDiagnoseApi, xrayDiagnoseApi } from "../../../api/diagnosis/DiagnoseApi"

import { NotificationManager } from "react-notifications"

import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader"

import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from "react-animations"
import { AiOutlineEye } from "react-icons/ai"

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 0.9s ${fadeInAnimation};
`
const FadeInRightDiv = styled.div`
  animation: 0.9s ${fadeInRightAnimation};
`

const initialState = {
  diagnosing: false,
  responseReceived: false,
  imagePreviewURL: "",
  file: null,
  loading: false,
  m1Cardiomegaly: "",
  m1Pneumonia: "",
  m1Tuberculosis: "",
  m2Cardiomegaly: "",
  m2Normal: "",
  m2Pneumonia: "",
  m2Tuberculosis: "",
  heatMapImage: "",
  swapXrayType: false
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4a90e2;
  align-items: "center";
`

const Visitor = props => {
  const { REACT_APP_API_URL } = process.env
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [m1Cardiomegaly, setM1Cardiomegaly] = useState(initialState.m1Cardiomegaly)
  const [m1Pneumonia, setM1Pneumonia] = useState(initialState.m1Pneumonia)
  const [m1Tuberculosis, setM1Tuberculosis] = useState(initialState.m1Tuberculosis)
  const [m2Cardiomegaly, setM2Cardiomegaly] = useState(initialState.m2Cardiomegaly)
  const [m2Pneumonia, setM2Pneumonia] = useState(initialState.m2Pneumonia)
  const [m2Tuberculosis, setM2Tuberculosis] = useState(initialState.m2Tuberculosis)
  const [covid, setCovid] = useState("")

  const [isCovidDiagnosis, setIsCovidDiagnosis] = useState(false)

  const [covidHeatmap, setCovidHeatmap] = useState('')
  const [xray, setXray] = useState("")
  const [heatMapURL, setHeatMapURL] = useState('')
  const [heatMapCardiomegaly, setHeatMapCardiomegaly] = useState('')
  const [heatMapPneumonia, setHeatMapPneumonia] = useState("")
  const [heatMapTuberculosis, setHeatMapTuberculosis] = useState("")
  const [heatMapAll, setHeatMapAll] = useState("")

  const [swapXrayType, setSwapXrayType] = useState(initialState.swapXrayType)

  const [imagePreviewURL, setImagePreviewURL] = useState(initialState.imagePreviewURL)
  const [responseReceived, setResponseReceived] = useState(initialState.responseReceived)
  const [diagnosing, setDiagnosing] = useState(initialState.diagnosing)

  const [loading, setLoading] = useState(initialState.loading)
  const [file, setFile] = useState(initialState.file)

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("role") === "visitor") {
      setVisible(true)
      setRole(localStorage.getItem("role"))
      setLoading(false)
    } else props.history.push("/")
  }, [])

  useEffect(() => {
    if (swapXrayType) {
      if (document.getElementById("inner-box") !== null) {
        document.getElementById("inner-box").classList.add("flip-inner")
        document.getElementById("inner-box").classList.remove("flip-otherside")
      }
    } else {
      if (document.getElementById("inner-box") !== null) {
        document.getElementById("inner-box").classList.remove("flip-inner")
        document.getElementById("inner-box").classList.add("flip-otherside")
      }
    }
  }, [swapXrayType])

  const onImageChange = event => {
    if (event.target.name === "xrayInput") {

      setIsCovidDiagnosis(false)
      
      var fileExt = event.target.files[0].name.split(".").pop()

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        try {
          setFile(event.target.files[0])
          setXray([URL.createObjectURL(event.target.files[0])])
          setImagePreviewURL([URL.createObjectURL(event.target.files[0])])
        } catch (err) {
          NotificationManager.error("Something went wrong while selecting image " + err, "Error", 1000)
        } finally {
          reset()
        }
      } else {
        alert("Wrong file type selected...")
      }
    } else if (event.target.name === "covidXrayInput") {
      setIsCovidDiagnosis(true)

      var fileExt = event.target.files[0].name.split(".").pop()

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        try {
          setFile(event.target.files[0])
          setXray([URL.createObjectURL(event.target.files[0])])
          setImagePreviewURL([URL.createObjectURL(event.target.files[0])])
        } catch (err) {
          NotificationManager.error("Something went wrong while selecting image " + err, "Error", 1000)
        } finally {
          reset()
        }
      } else {
        alert("Wrong file type selected...")
      }
    }
  }

  const reset = () => {
    setResponseReceived(initialState.responseReceived)
    setDiagnosing(initialState.diagnosing)
  }

  const handleUpload = () => {
    setDiagnosing(true)

    if (isCovidDiagnosis) {
      
      const xrayFormData = new FormData()

      xrayFormData.append("image", file, file.name)
      xrayFormData.append("user_id", localStorage.getItem("user_id"))
      xrayFormData.append("user_type", localStorage.getItem("role"))
      console.log(...xrayFormData)

      covidDiagnoseApi(xrayFormData)
        .then(res => {
          if (res.category === "success") {
            setCovid(res.covid) 
            setCovidHeatmap(REACT_APP_API_URL + "/" + res.heatmaps)

            setResponseReceived(true)
            NotificationManager.success("Image successfully uploaded", "Success", 1000)
          } else if (res.category === "error") {
            NotificationManager.error("Bad Image Detected", "Error", 1000)
            setDiagnosing(false)
          } else {
            NotificationManager.error("Something went wrong", "Error", 1000)
            setDiagnosing(false)
          }
        })
        .catch(err => {
          NotificationManager.error("Something went wrong while uploading image " + err, "Error")
          setDiagnosing(false)
        })

    } else {
      const xrayFormData = new FormData()

      xrayFormData.append("image", file, file.name)
      xrayFormData.append("user_id", localStorage.getItem("user_id"))
      xrayFormData.append("user_type", localStorage.getItem("role"))
      console.log(...xrayFormData)

      xrayDiagnoseApi(xrayFormData)
        .then(res => {
          if (res.status === 200) {
            setM1Cardiomegaly(res.model1_data.m1cardiomegaly)
            setM1Pneumonia(res.model1_data.m1pneumonia)
            setM1Tuberculosis(res.model1_data.m1tuberculosis)
            setM2Cardiomegaly(res.model2_data.m2cardiomegaly)
            setM2Pneumonia(res.model2_data.m2pneumonia)
            setM2Tuberculosis(res.model2_data.m2tuberculosis)
            setHeatMapCardiomegaly(REACT_APP_API_URL + res.heatmaps.cardiomegaly)
            setHeatMapPneumonia(REACT_APP_API_URL + res.heatmaps.pneumonia)
            setHeatMapTuberculosis(REACT_APP_API_URL + res.heatmaps.tuberculosis)
            setHeatMapAll(REACT_APP_API_URL + res.heatmaps.model_all)

            setResponseReceived(true)
            NotificationManager.success("Image successfully uploaded", "Success", 1000)
          } else if (res.category === "error") {
            NotificationManager.error("Bad Image Detected", "Error", 1000)
            setDiagnosing(false)
          } else {
            NotificationManager.error("Something went wrong", "Error", 1000)
            setDiagnosing(false)
          }
        })
        .catch(err => {
          NotificationManager.error("Something went wrong while uploading image " + err, "Error")
          setDiagnosing(false)
        })
    }
  }

  return (
    <FadeInDiv className='container-fluid'>
      <div className='row mb-3'>
        <div className={responseReceived ? "col-lg-6" : "col-lg-12"}>
          <div className='card shadow'>
            <div className='card-header py-3 text-center'>
              <input style={{ display: "none" }} id='imageInput' type='file' accept='image/*' name="xrayInput" onChange={onImageChange} />
              <label
                htmlFor='imageInput'
                style=
                {{
                  cursor: "pointer",
                  background: "#59b1a7",
                  padding: "10px",
                  color: "white",
                  borderRadius: "5px"
                }}
                className='m-0 font-weight-bold'
              >
                Select Xray for diagnosis
              </label>{" "}

              <input style={{ display: "none" }} id='covidXrayInput' type='file' accept='image/*' name="covidXrayInput" onChange={onImageChange} />
              <label
                htmlFor='covidXrayInput'
                style=
                {{
                  cursor: "pointer",
                  background: "#59b1a7",
                  padding: "10px",
                  color: "white",
                  borderRadius: "5px"
                }}
                className='m-0 font-weight-bold'
              >
                Select Xray for Covid Diagnosis
              </label>
            </div>
            <div className='card-body text-center'>
              {imagePreviewURL === "" ? (
                <></>
              ) : (
                <FadeInDiv className='flip-box'>
                  <div id='inner-box'>
                    <div className='flip-box-front'>
                      <img
                        id="image-preview"
                        style={{ maxHeight: "484px", maxWidth: "500px" }}
                        src={imagePreviewURL}
                      />
                    </div>
                    {/* <div className='flip-box-back'>
                      <img
                        id="image-preview"
                        style={{ maxHeight: "484px", maxWidth: "500px" }}
                        src={heatMapURL}
                        alt='Heatmap'
                      />
                    </div> */}
                  </div>
                </FadeInDiv>
              )}
            </div>
          </div>
          {imagePreviewURL === "" ? (
            <></>
          ) : !responseReceived ? (
            <div className='text-center'>
              <button style={{ borderRadius: "50px" }} className='mt-2 btn btn-primary' disabled={diagnosing} onClick={handleUpload}>
                <i className={diagnosing ? "fas fa-spinner fa-spin mr-2" : "fas fa-stethoscope mr-2"}></i>
                {diagnosing ? "Diagnosing" : "Diagnose"}
              </button>
            </div>
          ) : (
            <div className='text-center'>
              <button
                style={{ borderRadius: "50px" }}
                className='btn-block text-center btn btn-success mt-2'
                onClick={() => setImagePreviewURL(xray)}
                disabled={xray === imagePreviewURL ? true : false}
              >
                <i className='fas fa-eye mr-2'></i>
                View your Xray
              </button>
            </div>
          )}
        </div>

        {responseReceived ?
          !isCovidDiagnosis ? (
            <FadeInRightDiv className='col-lg-6'>
              <div className='card shadow mb-4'>
                <div className='card-header py-3 text-center'>
                  <h6 style={{ color: "#59b1a7" }} className='m-0 font-weight-bold'>
                    Diagnosis 1
                  </h6>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered text-center' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th scope='col'>Disease</th>
                        <th scope='col'>Diagnosis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Cardiomegaly{" "}
                          <AiOutlineEye
                            size={18}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                            onClick={() => setImagePreviewURL(heatMapCardiomegaly)}
                          />
                        </td>
                        <td>{m1Cardiomegaly}</td>
                      </tr>
                      <tr>
                        <td>
                          Pneumonia{" "}
                          <AiOutlineEye
                            size={18}
                            onClick={() => setImagePreviewURL(heatMapPneumonia)}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                          />
                        </td>
                        <td>{m1Pneumonia}</td>
                      </tr>
                      <tr>
                        <td>
                          Tuberculosis{" "}
                          <AiOutlineEye
                            size={18}
                            onClick={() => setImagePreviewURL(heatMapTuberculosis)}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                          />
                        </td>
                        <td>{m1Tuberculosis}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='card shadow mb-4'>
                <div className='card-header py-3 text-center'>
                  <h6
                    style={{ color: "#59b1a7" }}
                    className='m-0 font-weight-bold'
                  >
                    Diagnosis 2
                  </h6>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered text-center' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th scope='col'>Disease</th>
                        <th scope='col'>Diagnosis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Cardiomegaly</td>
                        <td>{m2Cardiomegaly}</td>
                      </tr>
                      <tr>
                        <td>Pneumonia</td>
                        <td>{m2Pneumonia}</td>
                      </tr>
                      <tr>
                        <td>Tuberculosis</td>
                        <td>{m2Tuberculosis}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          {responseReceived ?
                            <button
                              style={{ borderRadius: "50px" }}
                              className='text-center btn btn-success mt-2'
                              onClick={() => setImagePreviewURL(heatMapAll)}
                              disabled={heatMapAll === imagePreviewURL ? true : false}
                            >
                              <i className='fas fa-eye mr-2'></i>
                              View Heatmap of your Xray
                            </button> :
                            <button
                              style={{ borderRadius: "50px" }}
                              disabled={true}
                              className='btn-block text-center btn btn-primary mt-2'
                            >
                              <i className='fas fa-eye mr-2'></i>
                              View Heatmap of your Xray
                            </button>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </FadeInRightDiv>
          ) : (
            <FadeInRightDiv className='col-lg-6'>
              <div className='card shadow mb-4'>
                <div className='card-header py-3 text-center'>
                  <h6 style={{ color: "#59b1a7" }} className='m-0 font-weight-bold'>
                    Covid Diagnosis
                  </h6>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered text-center' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th scope='col'>Disease</th>
                        <th scope='col'>Diagnosis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Covid{" "}
                          <AiOutlineEye
                            size={18}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                            onClick={() => setImagePreviewURL(covidHeatmap)}
                          />
                        </td>
                        <td>{covid}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </FadeInRightDiv>
          ) : (
            <div className='text-center'></div>
          )}
      </div>
    </FadeInDiv>
  )
}

export default Visitor
