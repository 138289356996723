import { useContext, useEffect, useRef, useState } from "react"

import RadiologistList from "./RadiologistList"

import { RadiologistContext } from "../../../context/RadiologistContext"
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

import * as RadiologistApi from '../../../api/users/radiologist/RadiologistApi'

import NotificationManager from "react-notifications/lib/NotificationManager"

import { css } from "@emotion/core"
import BarLoader from "react-spinners/BarLoader"

import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import { useHistory } from "react-router-dom"
import { Col, Input, Row } from "reactstrap"
import { searcher } from "../../utility/Searcher"

const fadeInAnimation = keyframes`${fadeIn}`

const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

const initialState = {
  radiologists: [],
  radiologist: {
    radiologist_id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    qualification: "",
    photo: "",
    documents: "",
    status: true
  }
}

const dummyRadiologist = [
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  },
  {
    _id: "1",
    firstName: "asd",
    lastName: "asd",
    email: "a@gmail.com",
    phone: "1223213",
    password: "12312",
    qualification: "asd",
    uploaded_documents: "path/document",
    status: true
  }
]

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4A90E2;
  align-items: "center";
`;

const RadiologistPage = (props) => {

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const { contextRadiologist } = useContext(RadiologistContext)

  const [loading, setLoading] = useState(true)
  const [radiologists, setRadiologists] = useState(initialState.radiologists)

  const history = useHistory()
  const firstUpdate = useRef(true)

  useEffect(() => {
    setLoading(true)
    setVisible(true)
    setRole(localStorage.getItem('role'))

    RadiologistApi.getRadiologists()
      .then(res => {
        if (res.status === 200) {
          setRadiologists(res.data)
          NotificationManager.success("Radiologists Loaded Successfully", 'Success', 1000)
          setLoading(false)
        } else {
          NotificationManager.error("Something went wrong while loading Radiologists: " + res.message, 'Error', 1000)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        NotificationManager.error("Something went wrong while loading Radiologists: " + err, "Error", 1000)
      })
  }, [])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    } else {
      if (contextRadiologist._id !== "") {
        const radiologistFormData = new FormData()

        // radiologistFormData.append("_id", contextRadiologist._id)
        // radiologistFormData.append("firstName", contextRadiologist.firstName)
        radiologistFormData.append("name", contextRadiologist.name)
        radiologistFormData.append("email", contextRadiologist.email)
        radiologistFormData.append("phone", contextRadiologist.phone)
        radiologistFormData.append("password", contextRadiologist.password)
        radiologistFormData.append("qualification", contextRadiologist.qualification)
        radiologistFormData.append('photo', contextRadiologist.photo)
        radiologistFormData.append("status", contextRadiologist.status)
        radiologistFormData.append("documents", contextRadiologist.documents)

        console.log(...radiologistFormData)

        RadiologistApi.updateRadiologist(radiologistFormData)
          .then(res => {
            if (res.status === 201) {
              if (contextRadiologist.status === true)
                NotificationManager.success(`${contextRadiologist.name} has been reinstated`, 'Success', 1000)
              else
                NotificationManager.success(`${contextRadiologist.name} has been suspended`, 'Success', 1000)

              RadiologistApi.getRadiologists()
                .then(res => {
                  if (res.status === 200) {
                    setRadiologists(res)
                    setLoading(false)
                  } else {
                    NotificationManager.error("Something went wrong while loading Radiologists: ", 'Error', 1000)
                    setLoading(false)
                  }
                })
                .catch(err => NotificationManager.error("Something went wrong while loading Radiologists: " + err, "Error", 1000))

              console.log(res)
            } else {
              NotificationManager.error(`Something went wrong while suspending ${contextRadiologist.name}: `, 'Error', 1000)
              setLoading(false)
            }
          })
          .catch(err => NotificationManager.error(`Something went wrong while suspending ${contextRadiologist.name}: ` + err, 'Error', 1000))
      }
    }
  }, [contextRadiologist])

  const handleSearch = async (event) => {
    const { name, value } = event.target

    setLoading(true)

    searcher('radiologist', value)
      .then(res => {
        setRadiologists(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        NotificationManager.error(`Something went wrong while searching` + err, 'Error', 1000)
      })
  }

  return (
    <FadeInDiv className="mb-4" style={{ width: "100%" }}>
      <div>
        <Row>
          <Col>
            <Input className="mb-2 ml-2 mr-2" type="search" placeholder="Search Radiologists" onChange={handleSearch} />
          </Col>
          <Col>
            <button
              className="btn btn-primary mb-2 mr-2 ml-2"
              type="button"
              onClick={() =>
                props.history.push('/admin/radiologists/add')
              }
              style={{ float: "right" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-plus" viewBox="0 1 16 18">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
              </svg>
              {" "}Add Radiologist
            </button>
          </Col>
        </Row>

        {radiologists.length === 0 ? <h4 className="text-center font-size 16">No Radiologists Found</h4> :
          loading ?
            <BarLoader
              color={"#4A90E2"}
              loading={loading}
              css={override}
              size={100}
              width={1000}
              height={5}
            /> :
            <RadiologistList
              radiologists={radiologists}
              initialState={initialState}
            />
        }
      </div>
    </FadeInDiv>
  )
}
export default RadiologistPage