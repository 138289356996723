import { useContext, useEffect, useRef, useState } from "react"

//Imports for sidebar and topbar contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import Error from "../../errors/Error"
import FormUpload from "../../fileUpload/FormUpload"

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

const VisitorForm = (props) => {
  const {
    inputChange,
    saving,
    addVisitor,
    visitor,
    setPhoto,
    updateVisitor,
    errorEmail,
    errorName,
    errorEmailIsOpen,
    errorNameIsOpen,
    errorTelephone,
    errorTelephoneIsOpen,
    errorProfilePic,
    errorProfilePicIsOpen
  } = props

  //Contexts for sidebar and topbar
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const [editFlag, setEditFlag] = useState(false)

  //Runs on first render
  useEffect(() => {
    setVisible(true)
    setRole(localStorage.getItem('role'))

    if (/edit/.test(window.location.href))
      setEditFlag(true)
    else
      setEditFlag(false)
  }, [])

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <form className="card-header py-3">
        {editFlag ? <h5>Edit Visitor</h5>
          : <h5>Add Visitor</h5>
        }
        <div className="form-group row">
          <div className="col-md-6">
            <label>Name</label>
            <input
              type="text"
              name="name"
              minLength="3"
              pattern='[A-Za-z]'
              placeholder="e.g Doe"
              title="Please enter a name containing only alphabets."
              className="form-control form-control-sm"
              onChange={inputChange}
              value={visitor.name}
            />
            {errorNameIsOpen ?
              <Error error={errorName} /> :
              <></>
            }
          </div>

          <div className="col-md-6">
            <label>Email</label>
            <input
              type="text"
              name="email"
              className="form-control form-control-sm"
              placeholder="e.g example@provider.domain"
              onChange={inputChange}
              value={visitor.email}
            />
            {errorEmailIsOpen ?
              <Error error={errorEmail} /> :
              <></>
            }
          </div>
          <div className="col-md-6">
            <label>Phone Number</label>
            <input
              type="text"
              name="phone"
              maxLength='11'
              pattern='[0-9]'
              placeholder="e.g xxxx-xxxxxxx (without dash)"
              className="form-control form-control-sm"
              onChange={inputChange}
              value={visitor.phone}
            />
            {errorTelephoneIsOpen ?
              <Error error={errorTelephone} /> :
              <></>
            }
          </div>

          <div className="col-md-6">
            <label>Password</label>
            <input
              type="text"
              name="password"
              disabled
              className="form-control form-control-sm"
              onChange={inputChange}
              value={visitor.password === "" ? 'System-generated' : visitor.password}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12">
            <label className="text-center">Profile Picture</label>
            <FormUpload uploadMultiple={false} acceptedFormats="image/*" setFiles={setPhoto} />
            {/* <input
              className="form-control form-control-sm"
              id="filePicker"
              accept="image/*"
              type="file"
              onChange={inputChange}
              //value={apiUser.document.name}
              name="profile_pic"
            /> */}
            {errorProfilePicIsOpen ?
              <Error error={errorProfilePic} /> :
              <></>
            }
          </div>
        </div>
        <div>
          {saving ?
            <button
              className="btn btn-success"
              type="button"
              disabled
              style={{ float: 'right', width: '20%' }}
            >
              <i className="fas fa-spinner fa-spin mr-2"></i>
              {editFlag ? "Updating" : "Submitting"}
            </button> :
            editFlag ?
              <button
                className="btn btn-primary"
                type="button"
                onClick={updateVisitor}
                style={{ float: 'right', width: '20%' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-pencil-square mr-1" viewBox="0 0 16 18">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>
                Update
              </button> :
              <button
                className="btn btn-primary"
                type="button"
                onClick={addVisitor}
                style={{ float: 'right', width: '20%' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-save mr-1" viewBox="0 0 16 18">
                  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                </svg>
                Submit
              </button>
          }
        </div>
      </form>
    </FadeInDiv>
  )
}

export default VisitorForm