import { get, patch, post, put } from "../../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const createRadiologistEndpoint = REACT_APP_API_URL + "/radiologist/register"
const updateRadiologistEndpoint = REACT_APP_API_URL + "/radiologist/update?user_id="
const getRadiologistEndpoint = REACT_APP_API_URL + "/radiologist/?user_id="
const getRadiologistsEndpoint = REACT_APP_API_URL + "/radiologist/"
const resetPasswordEndpoint = REACT_APP_API_URL + "/radiologist/reset_password"

//Api Handler for creating Radiologist via Fetch API using FormData
export const createRadiologist = async (radiologistData) => {
  return await post(createRadiologistEndpoint, radiologistData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for updating existing Radiologist via Fetch API using FormData
export const updateRadiologist = async (radiologistData, user_id) => {
  return await put(updateRadiologistEndpoint + user_id, radiologistData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting single Radiologist via Fetch API
export const getRadiologist = async (user_id) => {
  return await get(getRadiologistEndpoint + user_id, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting list of Radiologists via Fetch API
export const getRadiologists = async () => {
  return await get(getRadiologistsEndpoint, { 'Authorization': localStorage.getItem('token') })
}

export const resetPassword = async (userData) => {
  return await patch(resetPasswordEndpoint, userData, { 'Authorization': localStorage.getItem('token') })
}