import { useContext, useEffect, useState } from "react"

//Imports for topbar and sidebar
import { userContext } from "../../../../../context/userContext"
import { roleContext } from "../../../../../context/RoleContext"

// import xrayDiagnose from "../../../../assets/xrayDiagnose.PNG"

//Imports for loader
import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`
const FadeInRightDiv = styled.div`animation:  0.9s ${fadeInRightAnimation};`

//Initial State
const initialState = {
  loading: false,
}

const M1M2DiagnosisDocumentation = (props) => {

  //Declaration and initialization for sidebar and topbar contexts
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [loading, setLoading] = useState(initialState.loading)

  //Runs when component loads for first time
  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('role') === 'apiUser') {
      setVisible(true)
      setRole(localStorage.getItem('role'))
      setLoading(false)
    } else {
      props.history.push('/')
    }
  }, [])

  //Opens image in modal when image is clicked
  const handleImageClick = () => {
    var modal = document.getElementById("myModal")

    var img = document.getElementById("myImg")
    var modalImg = document.getElementById("img01")

    modal.style.display = "block"
    // modalImg.src = xrayDiagnose

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    }
  }

  return (
    <FadeInDiv className="container text-dark">
      <h2 className="text-dark">Model 1 & Model 2 Diagnosis API</h2>

      {/*First Card that holds all details regarding method, endpoint, headers*/}
      <div className="card border-left-info mb-2 shadow">
        <a href="#collapseRequestCard" className="d-block card-header py-3" data-toggle="collapse"
          role="button" aria-expanded="true" aria-controls="collapseRequestCard">
          <h4 className="m-0 font-weight-bold text-info">Request Pattern</h4>
        </a>
        <div className="collapse show" id="collapseRequestCard">
          <div className="card-body">
            <dl className="row">
              <dt className="col-sm-2">
                Method:
              </dt>
              <dd className="col-sm-9">
                POST
              </dd>
              <dt className="col-sm-2">
                Endpoint:
              </dt>
              <dd className="col-sm-9">
                http://127.0.0.1:5000/diagnose/
              </dd>
              <dt className="col-sm-2">
                Content:
              </dt>
              <dd className="col-sm-9">
                formData
              </dd>
              <dt className="col-sm-2">
                Headers
              </dt>
              <dt className="col-sm-6">
                Authorization: Bearer token
              </dt>
            </dl>
          </div>
        </div>
      </div>

      <div className="card border-left-info shadow mb-2">
        <a href="#collapseResponseCard" className="d-block card-header py-3" data-toggle="collapse"
          role="button" aria-expanded="true" aria-controls="collapseResponseCard">
          <h4 className="m-0 font-weight-bold text-info">Request Body</h4>
        </a>
        <div className="collapse show" id="collapseResponseCard">
          <div className="card-body">
            <ul>
              <li className="mb-1">
                <strong>image:</strong> Xray Image to be sent to API.
              </li>
              <li className="mb-1">
                <strong>user_id:</strong> User id for the user submitting xray.
              </li>
              <li className="mb-1">
                <strong>user_type:</strong> Type of the user submitting Xray.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*Second card where request body is detailed*/}
      <div className="card border-left-info shadow mb-2">
        <a href="#collapseResponseCard" className="d-block card-header py-3" data-toggle="collapse"
          role="button" aria-expanded="true" aria-controls="collapseResponseCard">
          <h4 className="m-0 font-weight-bold text-info">Response Object</h4>
        </a>
        <div className="collapse show" id="collapseResponseCard">
          <div className="card-body">
            <ul>
              <li className="mb-1">
                <strong>id:</strong> Unique identifier for the image sent previously.
              </li>
              <li className="mb-1">
                <strong>image:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>category:</strong> Category of api response either success or failure.
              </li>
              <li className="mb-1">
                <strong>message:</strong> Success of api response either success or failure.
              </li>
              <li className="mb-1">
                <strong>status:</strong> Status code for api response status.
              </li>
              <li className="mb-1">
                <strong>heatmaps:</strong> Object with key, value pairs.
              </li>
              <li className="mb-1">
                <strong>heatmaps.cardiomegaly:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>heatmaps.pneumonia:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>heatmaps.tuberculosis:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>heatmaps.model_all:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>model1_data:</strong> Object with key, value pairs.
              </li>
              <li className="mb-1">
                <strong>model1_data.m1cardiomegaly:</strong> One of the following strings: <strong>Not seen, Chances of Cardiomegaly, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>model1_data.m1pneumonia:</strong> One of the following strings: <strong>Not seen, Chances of Pneumonia, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>model1_data.m1tuberculosis:</strong> One of the following strings: <strong>Not seen, Chances of Tuberculosis, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>model2_data:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>model2_data.model_all:</strong> Path for the image uploaded to server.
              </li>
              <li className="mb-1">
                <strong>model2_data.m2cardiomegaly:</strong> One of the following strings: <strong>Not seen, Chances of Cardiomegaly, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>model2_data.m1pneumonia:</strong> One of the following strings: <strong>Not seen, Chances of Pneumonia, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>model2_data.m1tuberculosis:</strong> One of the following strings: <strong>Not seen, Chances of Tuberculosis, Likely, Most Likely</strong>.
              </li>
              {/* <li className="mb-1">
                <strong>m1cardiomegaly:</strong> One of the following strings: <strong>Not seen, Chances of Cardiomegaly, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>m1pneumonia:</strong> One of the following strings: <strong>Not seen, Chances of Pneumonia, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>m1tuberclosis:</strong> One of the following strings: <strong>Not seen, Chances of Tuberculosis, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>m2cardiomegaly:</strong> One of the following strings: <strong>Not seen, Chances of Cardiomegaly, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>m2pneumonia:</strong> One of the following strings: <strong>Not seen, Chances of Pneumonia, Likely, Most Likely</strong>.
              </li>
              <li className="mb-1">
                <strong>m2tuberclosis:</strong> One of the following strings: <strong>Not seen, Chances of Tuberculosis, Likely, Most Likely</strong>.
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      {/*Image card where a postman rendition of the whole procedure is displayed*/}
      <div className="card shadow border-left-info mb-2">
        <a href="#collapseImageCard" className="d-block card-header py-3" data-toggle="collapse"
          role="button" aria-expanded="true" aria-controls="collapseImageCard">
          <h4 className="m-0 font-weight-bold text-info">Postman Visual</h4>
        </a>
        <div className="collapse show" id="collapseImageCard">
          <div className="card-body">
            {/* <img style={{ cursor: 'pointer' }} src={xrayDiagnose} width="100%" onClick={handleImageClick} id="myImg"/> */}
          </div>
        </div>
      </div>
      <FadeInDiv
        style={{
          display: 'none',
          position: 'fixed',
          zIndex: '1',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.9)',
          paddingTop: '100px',
          left: '0',
          top: '0',
          width: '100%',
          height: '100%'
        }}
        id="myModal"
        className="modal"
      >
        <span
          style={{
            position: 'absolute',
            top: '15px',
            right: '35px',
            color: '#f1f1f1',
            fontSize: '40px',
            fontWeight: 'bold',
            transition: '0.3s',
            cursor: 'pointer'
          }}
          className="close"
        >
          &times;
        </span>
        <img className="modal-content" id="img01" />
      </FadeInDiv>
    </FadeInDiv>
  )
}

export default M1M2DiagnosisDocumentation