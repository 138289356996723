//Necessary system imports
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

//Imports for counting number of users for statistics display for admin
import * as VisitorApi from "../../../api/users/visitor/VisitorApi"
import * as RadiologistApi from "../../../api/users/radiologist/RadiologistApi"
import * as ApiUserApi from "../../../api/users/api_user/ApiUserApi"
import * as AdminApi from "../../../api/users/admin/AdminApi"

import VisitorXrayList from "./VisitorXrayList"

//Imports for Topbar and Sidebbar context
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

//Import for notifications
import { NotificationManager } from "react-notifications"

//Imports for loader
import { css } from "@emotion/core"
import BarLoader from "react-spinners/BarLoader"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"

//CSS for Loader
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4a90e2;
  align-items: "center";
`

const initialState = {
  visitorXrays: []
}

const Admin = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [visitorXrays, setVisitorXrays] = useState(initialState.visitorXrays)
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const [stats, setStats] = useState({})

  //Runs once on render
  useEffect(() => {
    setLoading(true)
    if (localStorage.getItem("token") && localStorage.getItem("role") === "admin") {
      setVisible(true)
      setRole(localStorage.getItem("role"))

      AdminApi.getStats()
      .then(res => {
        setStats(res)
        setLoading(false)
      })

      // AdminApi.getListOfXrays("visitor")
      // .then(res => {
      //   setVisitorXrays(res.data)
      //   setLoading(false)
      //   NotificationManager.success("Visitor Xrays Loaded Successfully", 'Success', 1000)
      // })
      // .catch(err => {
      //   console.log(err)
      //   NotificationManager.error("Something went wrong", 'Success', 1000)
      //   setLoading(false)
      // })
    } else {
      NotificationManager.error("Something went wrong, cannot login", "Error", 1000)
      history.push("/")
    }
  }, [])

  return (
    <div className='container'>
      <h1 className='h3 text-gray-800'>Dashboard</h1>
      {/* {loading ? (
        <BarLoader color={"#4A90E2"} loading={loading} css={override} size={100} width={1000} height={5} />
      ) : ( */}
        <Card className='mt-3'>
          <Row>
            <Col md='6'>
              <CardHeader>
                <h1 className='h4 text-gray-800 text-center'>Xrays Pending Feedback</h1>
              </CardHeader>
              <CardBody>
                {visitorXrays !== undefined ? visitorXrays.length === 0 ? (
                  <h2 className='text-center' style={{ padding: "20% 0" }}>
                    <BarLoader color={"#4A90E2"} loading={loading} css={override} size={100} width={500} height={5} />
                  </h2>
                ) : (
                  <VisitorXrayList visitorXrays={visitorXrays} />
                ) : <></>
                }
              </CardBody>
            </Col>

            <Col md='6' className='text-center'>
              <CardHeader>
                <h1 className='h4 text-gray-800'>Visitors</h1>
              </CardHeader>
              <CardBody>
                <h1>{loading ? <BarLoader color={"#4A90E2"} loading={loading} css={override} size={100} width={500} height={5} /> : stats.visitors}</h1>
              </CardBody>
              <CardHeader>
                <h1 className='h4 text-gray-800'>Radiologists</h1>
              </CardHeader>
              <CardBody>
                <h1>{loading ? <BarLoader color={"#4A90E2"} loading={loading} css={override} size={100} width={500} height={5} /> : stats.radiologists}</h1>
              </CardBody>
              <CardHeader>
                <h1 className='h4 text-gray-800'>API Users</h1>
              </CardHeader>
              <CardBody>
                <h1>{loading ? <BarLoader color={"#4A90E2"} loading={loading} css={override} size={100} width={500} height={5} /> : stats.api_users}</h1>
              </CardBody>
              <CardHeader>
                <h1 className='h4 text-gray-800'>Xrays Diagnosed</h1>
              </CardHeader>
              <CardBody>
                <h1>{loading ? <BarLoader color={"#4A90E2"} loading={loading} css={override} size={100} width={500} height={5} /> : stats.xray_details}</h1>
              </CardBody>
            </Col>
          </Row>
        </Card>
      {/* )} */}
    </div>
  )
}

export default Admin
