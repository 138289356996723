import React, { useContext, useEffect, useState } from "react"

import { NotificationManager } from "react-notifications"

import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

import { covidDiagnoseApi, xrayDiagnoseApi } from "../../../api/diagnosis/DiagnoseApi"
import { covidFeedbackApi, m1FeedbackApi, m2FeedbackApi } from "../../../api/feedback/FeedbackApi"

import { css } from "@emotion/core"
import BounceLoader from "react-spinners/BounceLoader"

import styled, { keyframes } from "styled-components"
import { fadeIn, fadeInRight } from "react-animations"
import { AiOutlineEye } from "react-icons/ai"

const fadeInAnimation = keyframes`${fadeIn}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 0.9s ${fadeInAnimation};
`
const FadeInRightDiv = styled.div`
  animation: 0.9s ${fadeInRightAnimation};
`

const initialState = {
  diagnosing: false,
  responseReceived: false,
  imagePreviewURL: "",
  incomingImagePath: "",
  file: null,
  loading: true,
  table1FeedbackRecorded: false,
  table2FeedbackRecorded: false,
  m1Cardiomegaly: "",
  m1Pneumonia: "",
  m1Tuberculosis: "",
  m2Cardiomegaly: "",
  m2Normal: "",
  m2Pneumonia: "",
  m2Tuberculosis: "",
  m1CardiomegalyFeedback: "",
  m1PneumoniaFeedback: "",
  m1TuberculosisFeedback: "",
  m2CardiomegalyFeedback: "",
  m2PneumoniaFeedback: "",
  m2TuberculosisFeedback: "",
  table1FeedbackCount: 0,
  table2FeedbackCount: 0,
  user_id: "",
  diagnosedXray_id: "",
  swapXrayType: false,
  heatMapTuberculosis: "",
  heatMapPneumonia: "",
  heatMapCardiomegaly: "",
  heatMapAll: ""
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4a90e2;
  align-items: "center";
`

const Radiologist = props => {
  const { REACT_APP_API_URL } = process.env

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [diagnosedXray_id, setDiagnosedXray_id] = useState(initialState.diagnosedXray_id)
  const [incomingImagePath, setIncomingImagePath] = useState(initialState.incomingImagePath)
  const [user_id, setUser_id] = useState(initialState.user_id)
  const [swapXrayType, setSwapXrayType] = useState(initialState.swapXrayType)

  const [m1Cardiomegaly, setM1Cardiomegaly] = useState(initialState.m1Cardiomegaly)
  const [m1Pneumonia, setM1Pneumonia] = useState(initialState.m1Pneumonia)
  const [m1Tuberculosis, setM1Tuberculosis] = useState(initialState.m1Tuberculosis)
  const [m2Cardiomegaly, setM2Cardiomegaly] = useState(initialState.m2Cardiomegaly)
  const [m2Pneumonia, setM2Pneumonia] = useState(initialState.m2Pneumonia)
  const [m2Tuberculosis, setM2Tuberculosis] = useState(initialState.m2Tuberculosis)
  const [covid, setCovid] = useState("")

  const [isCovidDiagnosis, setIsCovidDiagnosis] = useState(false)

  const [covidHeatmap, setCovidHeatmap] = useState('')
  const [xray, setXray] = useState("")
  const [heatMapTuberculosis, setHeatMapTuberculosis] = useState(initialState.heatMapTuberculosis)
  const [heatMapPneumonia, setHeatMapPneumonia] = useState(initialState.heatMapPneumonia)
  const [heatMapCardiomegaly, setHeatMapCardiomegaly] = useState(initialState.heatMapCardiomegaly)
  const [heatMapAll, setHeatMapAll] = useState(initialState.heatMapModelAll)

  const [m1CardiomegalyFeedback, setM1CardiomegalyFeedback] = useState(initialState.m1CardiomegalyFeedback)
  const [m1PneumoniaFeedback, setM1PneumoniaFeedback] = useState(initialState.m1PneumoniaFeedback)
  const [m1TuberculosisFeedback, setM1TuberculosisFeedback] = useState(initialState.m1TuberculosisFeedback)

  const [m2CardiomegalyFeedback, setM2CardiomegalyFeedback] = useState(initialState.m2CardiomegalyFeedback)
  const [m2PneumoniaFeedback, setM2PneumoniaFeedback] = useState(initialState.m2PneumoniaFeedback)
  const [m2TuberculosisFeedback, setM2TuberculosisFeedback] = useState(initialState.m2TuberculosisFeedback)

  const [covidFeedback, setCovidFeedback] = useState("")

  const [table1FeedbackCount, setTable1FeedbackCount] = useState(initialState.table1FeedbackCount)
  const [table2FeedbackCount, setTable2FeedbackCount] = useState(initialState.table2FeedbackCount)

  const [covidTableFeedbackCount, setCovidTableFeedbackCount] = useState(0)

  const [table1FeedbackRecorded, setTable1FeedbackRecorded] = useState(initialState.table1FeedbackRecorded)
  const [table2FeedbackRecorded, setTable2FeedbackRecorded] = useState(initialState.table2FeedbackRecorded)

  const [covidTableFeedbackRecorded, setCovidTableFeedbackRecorded] = useState("")

  const [imagePreviewURL, setImagePreviewURL] = useState(initialState.imagePreviewURL)
  const [heatMapURL, setHeatMapURL] = useState(initialState.heatMapURL)
  const [responseReceived, setResponseReceived] = useState(initialState.responseReceived)
  const [diagnosing, setDiagnosing] = useState(initialState.diagnosing)

  const [loading, setLoading] = useState(initialState.loading)
  const [file, setFile] = useState(initialState.file)

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("role") === "radiologist") {
      setVisible(true)
      setRole(localStorage.getItem("role"))
      setLoading(true)
    } else {
      props.history.push("/")
    }
  }, [])

  useEffect(() => {
    if (swapXrayType) {
      if (document.getElementById("inner-box") !== null) {
        document.getElementById("inner-box").classList.add("flip-inner")
        document.getElementById("inner-box").classList.remove("flip-otherside")
      }
    } else {
      if (document.getElementById("inner-box") !== null) {
        document.getElementById("inner-box").classList.remove("flip-inner")
        document.getElementById("inner-box").classList.add("flip-otherside")
      }
    }
  }, [swapXrayType])

  const onImageChange = event => {
    if (event.target.name === "xrayInput") {
      setIsCovidDiagnosis(false)

      var fileExt = event.target.files[0].name.split(".").pop()

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        try {
          setFile(event.target.files[0])
          setXray([URL.createObjectURL(event.target.files[0])])
          setImagePreviewURL([URL.createObjectURL(event.target.files[0])])
        } catch (err) {
          NotificationManager.error("Something went wrong while selecting image " + err, "Error", 1000)
        } finally {
          reset()
        }
      } else {
        alert("Wrong file type selected...")
      }
    }
    else if (event.target.name === "covidXrayInput") {
      setIsCovidDiagnosis(true)

      var fileExt = event.target.files[0].name.split(".").pop()

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        try {
          setFile(event.target.files[0])
          setXray([URL.createObjectURL(event.target.files[0])])
          setImagePreviewURL([URL.createObjectURL(event.target.files[0])])
        } catch (err) {
          NotificationManager.error("Something went wrong while selecting image " + err, "Error", 1000)
        } finally {
          reset()
        }
      } else {
        alert("Wrong file type selected...")
      }
    }
  }

  const reset = () => {
    setDiagnosing(initialState.diagnosing)
    setResponseReceived(initialState.responseReceived)
    setM1CardiomegalyFeedback(initialState.m1CardiomegalyFeedback)
    setM1PneumoniaFeedback(initialState.m1PneumoniaFeedback)
    setM1TuberculosisFeedback(initialState.m1TuberculosisFeedback)
    setM2CardiomegalyFeedback(initialState.m2CardiomegalyFeedback)
    setM2PneumoniaFeedback(initialState.m2PneumoniaFeedback)
    setM2TuberculosisFeedback(initialState.m2TuberculosisFeedback)
    setTable1FeedbackRecorded(initialState.table1FeedbackRecorded)
    setTable2FeedbackRecorded(initialState.table2FeedbackRecorded)
    setTable1FeedbackCount(initialState.table1FeedbackCount)
    setTable2FeedbackCount(initialState.table2FeedbackCount)

    var element = document.getElementsByName("m1FeedbackCardiomegaly")
    for (var i = 0; i < element.length; i++) element[i].checked = false

    var element = document.getElementsByName("m1FeedbackPneumonia")
    for (var i = 0; i < element.length; i++) element[i].checked = false

    var element = document.getElementsByName("m1FeedbackTuberculosis")
    for (var i = 0; i < element.length; i++) element[i].checked = false

    var element = document.getElementsByName("m2FeedbackCardiomegaly")
    for (var i = 0; i < element.length; i++) element[i].checked = false

    var element = document.getElementsByName("m2FeedbackPneumonia")
    for (var i = 0; i < element.length; i++) element[i].checked = false

    var element = document.getElementsByName("m2FeedbackTuberculosis")
    for (var i = 0; i < element.length; i++) element[i].checked = false
  }

  const handleUpload = () => {
    setDiagnosing(true)

    if (isCovidDiagnosis) {

      const xrayFormData = new FormData()

      xrayFormData.append("image", file, file.name)
      xrayFormData.append("user_id", localStorage.getItem("user_id"))
      xrayFormData.append("user_type", localStorage.getItem("role"))
      console.log(...xrayFormData)

      covidDiagnoseApi(xrayFormData)
        .then(res => {
          if (res.category === "success") {
            setDiagnosedXray_id(res.id)
            setIncomingImagePath(res.image)
            setCovid(res.covid)
            setCovidHeatmap(REACT_APP_API_URL + "/" + res.heatmaps)

            setResponseReceived(true)
            NotificationManager.success("Image successfully uploaded", "Success", 1000)
          } else if (res.category === "error") {
            NotificationManager.error("Bad Image Detected", "Error", 1000)
            setDiagnosing(false)
          } else {
            NotificationManager.error("Something went wrong", "Error", 1000)
            setDiagnosing(false)
          }
        })
        .catch(err => {
          NotificationManager.error("Something went wrong while uploading image " + err, "Error")
          setDiagnosing(false)
        })
    } else {
      const xrayFormData = new FormData()

      xrayFormData.append("image", file, file.name)
      xrayFormData.append("user_id", localStorage.getItem("user_id"))
      xrayFormData.append("user_type", localStorage.getItem("role"))
      console.log(...xrayFormData)

      xrayDiagnoseApi(xrayFormData)
        .then(res => {
          if (res.status === 200) {
            setIncomingImagePath(res.image)
            setDiagnosedXray_id(res.id)
            setHeatMapCardiomegaly(REACT_APP_API_URL + res.heatmaps.cardiomegaly)
            setHeatMapPneumonia(REACT_APP_API_URL + res.heatmaps.pneumonia)
            setHeatMapTuberculosis(REACT_APP_API_URL + res.heatmaps.tuberculosis)
            setHeatMapAll(REACT_APP_API_URL + res.heatmaps.model_all)
            setM1Cardiomegaly(res.model1_data.m1cardiomegaly)
            setM1Pneumonia(res.model1_data.m1pneumonia)
            setM1Tuberculosis(res.model1_data.m1tuberculosis)
            setM2Cardiomegaly(res.model2_data.m2cardiomegaly)
            setM2Pneumonia(res.model2_data.m2pneumonia)
            setM2Tuberculosis(res.model2_data.m2tuberculosis)

            setResponseReceived(true)
            NotificationManager.success("Image successfully uploaded", "Success", 1000)
          } else if (res.category === "error") {
            NotificationManager.warning("Bad Image Detected", "Error", 1000)
            setDiagnosing(false)
          } else {
            setDiagnosing(false)
            NotificationManager.error("Something went wrong", "Error", 1000)
          }
        })
        .catch(err => {
          setDiagnosing(false)
          NotificationManager.error("Something went wrong while uploading image " + err, "Error", 1000)
        })
    }
  }

  //Table 1 Cardiomegaly radio button group handler
  const handleRadioM1CadiomegalyOnChange = event => {
    setM1CardiomegalyFeedback(event.target.value)
    if (event.target.value === "" || m1PneumoniaFeedback === "" || m1TuberculosisFeedback === "") setTable1FeedbackRecorded(false)
    else setTable1FeedbackRecorded(true)
  }

  //Table 1 Pneumonia radio button group handler
  const handleRadioM1PneumoniaOnChange = event => {
    setM1PneumoniaFeedback(event.target.value)
    if (m1CardiomegalyFeedback === "" || event.target.value === "" || m1TuberculosisFeedback === "") setTable1FeedbackRecorded(false)
    else setTable1FeedbackRecorded(true)
  }

  //Table 1 Tuberculosis radio button group handler
  const handleRadioM1TuberculosisOnChange = event => {
    setM1TuberculosisFeedback(event.target.value)
    if (m1CardiomegalyFeedback === "" || m1PneumoniaFeedback === "" || event.target.value === "") setTable1FeedbackRecorded(false)
    else setTable1FeedbackRecorded(true)
  }

  //Table 2 Cardiomegaly radio button group handler
  const handleRadioM2CardiomegalyOnChange = event => {
    setM2CardiomegalyFeedback(event.target.value)
    if (event.target.value === "" || m2PneumoniaFeedback === "" || m2TuberculosisFeedback === "") setTable2FeedbackRecorded(false)
    else setTable2FeedbackRecorded(true)
  }

  //Table 2 Pneumonia radio button group handler
  const handleRadioM2PneumoniaOnChange = event => {
    setM2PneumoniaFeedback(event.target.value)
    if (m2CardiomegalyFeedback === "" || event.target.value === "" || m2TuberculosisFeedback === "") setTable2FeedbackRecorded(false)
    else setTable2FeedbackRecorded(true)
  }

  //Table 2 Tuberculosis radio button group handler
  const handleRadioM2TuberculosisOnChange = event => {
    setM2TuberculosisFeedback(event.target.value)
    if (m2CardiomegalyFeedback === "" || m2PneumoniaFeedback === "" || event.target.value === "") setTable2FeedbackRecorded(false)
    else setTable2FeedbackRecorded(true)
  }

  const handleRadioCovidOnChange = event => {
    setCovidFeedback(event.target.value)

    setCovidTableFeedbackRecorded(true)
  }

  const handleTable1SubmitBtn = () => {

    let m1FeedbackFormData = new FormData()
    m1FeedbackFormData.append("daignose_xray_id", diagnosedXray_id)
    m1FeedbackFormData.append("user_id", localStorage.getItem("user_id"))
    m1FeedbackFormData.append("image", /*REACT_APP_API_URL.includes("ptest", 7) ? */incomingImagePath/*.slice(36) : incomingImagePath.slice(29)*/)
    m1FeedbackFormData.append("cardiomegaly_found", m1Cardiomegaly)
    m1FeedbackFormData.append("cardiomegaly_feedback", m1CardiomegalyFeedback)
    m1FeedbackFormData.append("pneumonia_found", m1Pneumonia)
    m1FeedbackFormData.append("pneumonia_feedback", m1PneumoniaFeedback)
    m1FeedbackFormData.append("tuberclosis_found", m1Tuberculosis)
    m1FeedbackFormData.append("tuberculosis_feedback", m1TuberculosisFeedback)
    m1FeedbackFormData.append("user_type", role)

    // const m1FeedbackData = {
    //   diagnosedXray_id: diagnosedXray_id,
    //   role: role,
    //   image: incomingImagePath,
    //   cardiomegaly_found: m1Cardiomegaly,
    //   cardiomegaly_feedback: m1CardiomegalyFeedback,
    //   pneumonia_found: m1Pneumonia,
    //   pneumonia_feedback: m1PneumoniaFeedback,
    //   tuberclosis_found: m1Tuberculosis,
    //   tuberclosis_feedback: m1TuberculosisFeedback,
    //   user_id: user_id
    // }

    // console.log(m1FeedbackData)

    if (table1FeedbackCount === 0) {
      m1FeedbackApi(m1FeedbackFormData)
        .then(res => {
          setTable1FeedbackCount(1)
          setTable1FeedbackRecorded(initialState.table1FeedbackRecorded)
          NotificationManager.success("Feedback Submitted.", "Success", 1000)
        })
        .catch(err => NotificationManager.error("Somehting went wrong while submitting feedback " + err, "Error", 1000))
    }
  }

  //Table 2 Data Submission button handler
  const handleTable2SubmitBtn = () => {
    let m2FeedbackFormData = new FormData()

    m2FeedbackFormData.append("daignose_xray_id", diagnosedXray_id)
    m2FeedbackFormData.append("user_id", localStorage.getItem("user_id"))
    m2FeedbackFormData.append("image", incomingImagePath)
    m2FeedbackFormData.append("cardiomegaly_found", m1Cardiomegaly)
    m2FeedbackFormData.append("cardiomegaly_feedback", m1CardiomegalyFeedback)
    m2FeedbackFormData.append("pneumonia_found", m1Pneumonia)
    m2FeedbackFormData.append("pneumonia_feedback", m1PneumoniaFeedback)
    m2FeedbackFormData.append("tuberclosis_found", m1Tuberculosis)
    m2FeedbackFormData.append("tuberculosis_feedback", m1TuberculosisFeedback)
    m2FeedbackFormData.append("user_type", role)

    // const m2FeedbackData = {
    //   diagnosedXray_id: diagnosedXray_id,
    //   role: role,
    //   image: incomingImagePath,
    //   cardiomegaly_found: m2Cardiomegaly,
    //   cardiomegaly_feedback: m2CardiomegalyFeedback,
    //   pneumonia_found: m2Pneumonia,
    //   pneumonia_feedback: m2PneumoniaFeedback,
    //   tuberclosis_found: m2Tuberculosis,
    //   tuberclosis_feedback: m2TuberculosisFeedback,
    //   user_id: user_id
    // }

    // console.log(m2FeedbackData)

    if (table2FeedbackCount === 0) {
      m2FeedbackApi(m2FeedbackFormData)
        .then(res => {
          setTable2FeedbackCount(1)
          setTable2FeedbackRecorded(initialState.table2FeedbackRecorded)
          console.log(res)
          NotificationManager.success("Feedback Submitted.", "Success", 1000)
        })
        .catch(err => NotificationManager.error("Somehting went wrong while submitting feedback " + err, "Error", 1000))
    }
  }

  const handleCovidTableSubmitBtn = () => {
    let covidFeedbackFormData = new FormData()

    covidFeedbackFormData.append("diagnose_xray_id", diagnosedXray_id)
    covidFeedbackFormData.append("user_id", localStorage.getItem("user_id"))
    covidFeedbackFormData.append("image", incomingImagePath)
    covidFeedbackFormData.append("covid_found", covid)
    covidFeedbackFormData.append("covid_feedback", covidFeedback)
    covidFeedbackFormData.append("user_type", role)

    if (covidTableFeedbackCount === 0) {
      covidFeedbackApi(covidFeedbackFormData)
        .then(res => {
          if (res.category === "success") {
            setCovidTableFeedbackCount(1)
            setCovidTableFeedbackRecorded(false)
            NotificationManager.success("Feedback Submitted.", "Success", 1000)
          } else {
            NotificationManager.success("Something went wrong while submitting feedback.", "Success", 1000)
          }
        })
        .catch(err => NotificationManager.error("Somehting went wrong while submitting feedback " + err, "Error", 1000))
    }
  }

  return (
    <FadeInDiv className='container-fluid'>
      <div className='row mb-3'>
        <div className={responseReceived ? "col-lg-6" : "col-lg-12"}>
          <div className='card shadow'>
            <div className='card-header py-3 text-center'>
              <input
                style={{ display: "none" }}
                id='xrayInput'
                name="xrayInput"
                type='file'
                accept='image/*'
                onChange={onImageChange}
              />
              <label
                htmlFor='xrayInput'
                style=
                {{
                  cursor: "pointer",
                  color: "white",
                  background: "#2a606d",
                  padding: "10px",
                  borderRadius: "5px"
                }}
                className='m-0 font-weight-bold'
              >
                Select Xray to diagnose
              </label>{" "}

              <input
                style={{ display: "none" }}
                id='covidXrayInput'
                name="covidXrayInput"
                type='file'
                accept='image/*'
                onChange={onImageChange}
              />
              <label
                htmlFor='covidXrayInput'
                style=
                {{
                  cursor: "pointer",
                  color: "white",
                  background: "#2a606d",
                  padding: "10px",
                  borderRadius: "5px"
                }}
                className='m-0 font-weight-bold'
              >
                Select Covid Xray to diagnose
              </label>
            </div>
            <div className='card-body text-center'>
              {imagePreviewURL === "" ? (
                <></>
              ) : (
                <FadeInDiv className="flip-box">
                  <img
                    id="image-preview"
                    style={{ maxHeight: "484px", maxWidth: "500px" }}
                    src={imagePreviewURL}
                  />
                  {/* <div className='flip-box-back'>
                      <img
                        id="image-preview"
                        style={{ maxHeight: "484px", maxWidth: "500px" }}
                        src={heatMapURL}
                        alt='Heatmap'
                      />
                    </div> */}
                </FadeInDiv>
              )}
            </div>
          </div>
          {imagePreviewURL === "" ? (
            <></>
          ) : !responseReceived ? (
            <div className='text-center'>
              <button style={{ borderRadius: "50px" }} className='mt-2 btn btn-primary' disabled={diagnosing} onClick={handleUpload}>
                <i className={diagnosing ? "fas fa-spinner fa-spin mr-2" : "fas fa-stethoscope mr-2"}></i>
                {diagnosing ? "Diagnosing" : "Diagnose"}
              </button>
            </div>
          ) : (
            <div className='text-center'>
              <button
                style={{ borderRadius: "50px" }}
                className='btn-block text-center btn btn-success mt-2'
                onClick={() => setImagePreviewURL(xray)}
                disabled={xray === imagePreviewURL ? true : false}
              >
                <i class='fas fa-eye mr-2'></i>
                View your Xray
              </button>
            </div>
          )}
        </div>

        {responseReceived ?
          !isCovidDiagnosis ? (
            <FadeInRightDiv className='col-lg-6'>
              <div className='card shadow'>
                <div className='card-header py-3 text-center'>
                  <h6 style={{ color: "#2a606d" }} className='m-0 font-weight-bold'>
                    Diagnosis 1
                  </h6>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered text-center' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th scope='col'>Disease</th>
                        <th scope='col'>Diagnosis</th>
                        <th scope='col'>Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Cardiomegaly{" "}
                          <AiOutlineEye
                            size={18}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#2a606d' }}
                            onClick={() => setImagePreviewURL(heatMapCardiomegaly)}
                          />
                        </td>
                        <td>{m1Cardiomegaly}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radioM1CardiomegalyYes'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM1CardiomegalyYes'
                                name='m1FeedbackCardiomegaly'
                                value='yes'
                                onChange={handleRadioM1CadiomegalyOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radioM1CadiomegalyNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM1CadiomegalyNo'
                                name='m1FeedbackCardiomegaly'
                                value='no'
                                onChange={handleRadioM1CadiomegalyOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pneumonia{" "}
                          <AiOutlineEye
                            size={18}
                            onClick={() => setImagePreviewURL(heatMapPneumonia)}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#2a606d' }}
                          />
                        </td>
                        <td>{m1Pneumonia}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radioM1PneumoniaYes'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM1PneumoniaYes'
                                name='m1FeedbackPneumonia'
                                value='yes'
                                onChange={handleRadioM1PneumoniaOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radioM1PneumoniaNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM1PneumoniaNo'
                                name='m1FeedbackPneumonia'
                                value='no'
                                onChange={handleRadioM1PneumoniaOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Tuberculosis{" "}
                          <AiOutlineEye
                            size={18}
                            onClick={() => setImagePreviewURL(heatMapTuberculosis)}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#2a606d' }}
                          />
                        </td>
                        <td>{m1Tuberculosis}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radiom1Tuberculosis'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radiom1Tuberculosis'
                                name='m1FeedbackTuberculosis'
                                value='yes'
                                onChange={handleRadioM1TuberculosisOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radiom1PneumoniaNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radiom1PneumoniaNo'
                                name='m1FeedbackTuberculosis'
                                value='no'
                                onChange={handleRadioM1TuberculosisOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {table1FeedbackCount < 1 ? (
                <div className='text-center'>
                  <button
                    style={{ borderRadius: "50px" }}
                    className='btn btn-primary mt-2 mb-2'
                    type='button'
                    disabled={!table1FeedbackRecorded}
                    onClick={handleTable1SubmitBtn}>
                    <i className='fas fa-paper-plane mr-2'></i>
                    {table1FeedbackCount < 1 ? "Submit Feedback" : "Feedback Submitted"}
                  </button>
                </div>
              ) : (
                <div className='text-center'>
                  <button style={{ borderRadius: "50px" }} className='mt-2 btn btn-success mb-2' type='button' disabled={true}>
                    <i className='fas fa-check mr-2'></i>
                    Feedback Submitted
                  </button>
                </div>
              )}

              <div className='card shadow'>
                <div className='card-header py-3 text-center'>
                  <h6
                    style={{ color: "#2a606d" }}
                    className='m-0 font-weight-bold'
                  >
                    Diagnosis 2
                  </h6>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered text-center' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th scope='col'>Disease</th>
                        <th scope='col'>Diagnosis</th>
                        <th scope='col'>Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Cardiomegaly</td>
                        <td>{m2Cardiomegaly}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radioM2CardiomegalyYes'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM2CardiomegalyYes'
                                name='m2FeedbackCardiomegaly'
                                value='yes'
                                onChange={handleRadioM2CardiomegalyOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radioM2CardiomegalyNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM2CardiomegalyNo'
                                name='m2FeedbackCardiomegaly'
                                value='no'
                                onChange={handleRadioM2CardiomegalyOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Pneumonia</td>
                        <td>{m2Pneumonia}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radioM2PnuemoniaYes'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM2PneumoniaYes'
                                name='m2FeedbackPneumonia'
                                value='yes'
                                onChange={handleRadioM2PneumoniaOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radioM2PneumoniaNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM2PneumoniaNo'
                                name='m2FeedbackPneumonia'
                                value='no'
                                onChange={handleRadioM2PneumoniaOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Tuberculosis</td>
                        <td>{m2Tuberculosis}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radioM2TuberculosisYes'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM2TuberculosisYes'
                                name='m2FeedbackTuberculosis'
                                value='yes'
                                onChange={handleRadioM2TuberculosisOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radioM2TuberculosisNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioM2TuberculosisNo'
                                name='m2FeedbackTuberculosis'
                                value='no'
                                onChange={handleRadioM2TuberculosisOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          {responseReceived ?
                            <button
                              style={{ borderRadius: "50px" }}
                              className='text-center btn btn-success mt-2'
                              onClick={() => setImagePreviewURL(heatMapAll)}
                              disabled={heatMapAll === imagePreviewURL ? true : false}

                            >
                              <i class='fas fa-eye mr-2'></i>
                              View Heatmap of your Xray
                            </button> :
                            <button
                              style={{ borderRadius: "50px" }}
                              disabled={true}
                              className='btn-block text-center btn btn-primary mt-2'
                            >
                              <i class='fas fa-eye mr-2'></i>
                              View Heatmap of your Xray
                            </button>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {table2FeedbackCount < 1 ? (
                <div className='text-center'>
                  <button
                    style={{ borderRadius: "50px" }}
                    className='btn btn-primary mt-2'
                    type='button'
                    disabled={!table2FeedbackRecorded}
                    onClick={handleTable2SubmitBtn}>
                    <i className='fas fa-paper-plane mr-2'></i>
                    {table2FeedbackCount < 1 ? "Submit Feedback" : "Feedback Submitted"}
                  </button>
                </div>
              ) : (
                <div className='text-center'>
                  <button style={{ borderRadius: "50px" }} className='btn btn-success mt-2' type='button' disabled={true}>
                    <i className='fas fa-check mr-2'></i>
                    Feedback Submitted
                  </button>
                </div>
              )}
            </FadeInRightDiv>
          ) : (
            <FadeInRightDiv className='col-lg-6'>
              <div className='card shadow mb-4'>
                <div className='card-header py-3 text-center'>
                  <h6 style={{ color: "#59b1a7" }} className='m-0 font-weight-bold'>
                    Covid Diagnosis
                  </h6>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered text-center' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th scope='col'>Disease</th>
                        <th scope='col'>Diagnosis</th>
                        <th scope='col'>Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Covid{" "}
                          <AiOutlineEye
                            size={18}
                            style={{ cursor: "pointer", fontWeight: "bold", color: '#59b1a7' }}
                            onClick={() => setImagePreviewURL(covidHeatmap)}
                          />
                        </td>
                        <td>{covid}</td>
                        <td>
                          <div style={{ display: "inline", alignContent: "space-between" }}>
                            <label className='label' htmlFor='radioCovidYes'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioCovidYes'
                                name='feedbackCovid'
                                value='yes'
                                onChange={handleRadioCovidOnChange}
                              />{" "}
                              Yes
                            </label>{" "}
                            <label className='label' htmlFor='radioCovidNo'>
                              <input
                                disabled={!responseReceived}
                                type='radio'
                                id='radioCovidNo'
                                name='feedbackCovid'
                                value='no'
                                onChange={handleRadioCovidOnChange}
                              />{" "}
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {covidTableFeedbackCount < 1 ? (
                <div className='text-center'>
                  <button
                    style={{ borderRadius: "50px" }}
                    className='btn btn-primary mt-2 mb-2'
                    type='button'
                    disabled={!covidTableFeedbackRecorded}
                    onClick={handleCovidTableSubmitBtn}>
                    <i className='fas fa-paper-plane mr-2'></i>
                    {covidTableFeedbackCount < 1 ? "Submit Feedback" : "Feedback Submitted"}
                  </button>
                </div>
              ) : (
                <div className='text-center'>
                  <button style={{ borderRadius: "50px" }} className='mt-2 btn btn-success mb-2' type='button' disabled={true}>
                    <i className='fas fa-check mr-2'></i>
                    Feedback Submitted
                  </button>
                </div>
              )}
            </FadeInRightDiv>
          ) : (
            <div className='text-center'></div>
          )}
      </div>
    </FadeInDiv>
  )
}

export default Radiologist
