import React, { useContext, useEffect, useState } from "react"

import { userContext } from "../../../context/userContext"
import { VisitorContext } from "../../../context/VisitorContext"

import { useHistory } from "react-router-dom"

import defaultAvatar from "../../../assets/default_avatar.png"

import * as VisitorApi from '../../../api/users/visitor/VisitorApi'

import VisitorForm from "./VisitorForm"

import NotificationManager from "react-notifications/lib/NotificationManager"

import { EmailValidation, ImageValidation, NameValidation, nameValidation, TelephoneValidation } from "../../../validation/validations"

import validator from 'validator';

//Dummy data for visitor
const visitorData = [
  {
    _id: 1,
    firstName: "Hamza",
    lastName: "Mushtaq",
    email: "hamza@gmail.com",
    phone: 11117771,
  },
  {
    _id: 2,
    firstname: "Ismail",
    lastname: "Khan",
    email: "Ismail@gmail.com",
    phone: 11111111,
  }
]

//Initial State for Components
const initialState = {
  userType: "Radiologist",
  loading: false,
  errorName: "",
  errorEmail: "",
  errorPassword: "",
  errorTelephone: "",
  errorNameIsOpen: false,
  errorEmailIsOpen: false,
  errorPasswordIsOpen: false,
  errorTelephoneIsOpen: false,
  errorProfilePic: false,
  errorProfilePicIsOpen: false,
  saving: false,

  visitors: [],

  visitor: {
    visitor_id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    photo: '',
    status: true
  }
}

const ManageVisitorPage = (props) => {

  //New and Updated Users with their respective setters
  const [newVisitor, setNewVisitor] = useState(initialState.visitor)
  const { contextVisitor, setContextVisitor } = useContext(VisitorContext)
  const [photo, setPhoto] = useState("")
  const [errorName, setErrorName] = useState(initialState.errorName)
  const [errorEmail, setErrorEmail] = useState(initialState.errorEmail)
  const [errorPassword, setErrorPassword] = useState(initialState.errorPassword)
  const [errorTelephone, setErrorTelephone] = useState(initialState.errorTelephone)
  const [errorEmailIsOpen, setErrorEmailIsOpen] = useState(initialState.errorEmailIsOpen)
  const [errorPasswordIsOpen, setErrorPasswordIsOpen] = useState(initialState.errorPasswordIsOpen)
  const [errorNameIsOpen, setErrorNameIsOpen] = useState(initialState.errorNameIsOpen)
  const [errorTelephoneIsOpen, setErrorTelephoneIsOpen] = useState(initialState.errorTelephoneIsOpen)
  const [errorProfilePic, setErrorProfilePic] = useState(initialState.errorProfilePic)
  const [errorProfilePicIsOpen, setErrorProfilePicIsOpen] = useState(initialState.errorProfilePicIsOpen)

  const [loading, setLoading] = useState(initialState.loading)
  const [saving, setSaving] = useState(initialState.saving)

  const history = useHistory()

  // set visible to true to display this component on screen
  const { visible, setVisible } = useContext(userContext)

  useEffect(() => {
    setLoading(true)

    if (typeof (props.location.data) !== "undefined") {
      console.log(props.location)
      setContextVisitor({ ...props.location.data.visitor_data })
      setNewVisitor({ ...props.location.data.visitor_data })
      setLoading(false)
    } else {
      setNewVisitor(initialState.visitor)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setNewVisitor((prevVisitor) => ({
      ...prevVisitor,
      photo: photo
    }))
  }, [photo])

  useEffect(() => {
    console.log(newVisitor)
  }, [newVisitor])

  //Add Visitor Handler
  const handleAddVisitor = () => {
    setSaving(true)
    console.log(newVisitor)

    if (newVisitor.name === "" || newVisitor.email === "" || newVisitor.phone === "") {
      setSaving(initialState.saving)
      NotificationManager.error('Form has empty fields, cannot submit', "Error", 1000)
    } else if (errorName || errorEmail || errorPassword || errorTelephone) {
      setSaving(initialState.saving)
      NotificationManager.error('Errors in form, cannot submit', "Error", 1000)
    } else {
      if (newVisitor._id === "") {
        delete newVisitor._id
        console.log(newVisitor)
      }

      // var profilePic = new File([defaultAvatar], "profilePic.png")

      const visitorFormData = new FormData()

      visitorFormData.append("name", newVisitor.name)
      visitorFormData.append("email", newVisitor.email)
      visitorFormData.append("phone", newVisitor.phone)
      visitorFormData.append("password", newVisitor.password)
      visitorFormData.append("photo", newVisitor.photo)
      visitorFormData.append("status", newVisitor.status)

      console.log(...visitorFormData)

      VisitorApi.createVisitor(visitorFormData)
        .then(res => {
          setSaving(initialState.saving)

          if (res.message === "Visitor already exist") {
            NotificationManager.error(`${newVisitor.email} has been registered with the system.`, "Error", 1000)
          } else {
            NotificationManager.success(`${newVisitor.name} has been registered with the system.`, "Success", 1000)
            history.push('/admin/visitors')
            console.log(res)
          }
        })
        .catch(err => {
          setSaving(initialState.saving)
          NotificationManager.error('Something went wrong while creating visitor: ' + err, "Error", 1000)
        })
    }
  }

  //Update Visitor Handler
  const handleUpdateVisitor = () => {
    setSaving(true)
    console.log(newVisitor)

    const visitorFormData = new FormData()

    visitorFormData.append("name", newVisitor.name)
    visitorFormData.append("email", newVisitor.email)
    visitorFormData.append("phone", newVisitor.phone)
    visitorFormData.append("password", newVisitor.password)
    visitorFormData.append("photo", newVisitor.photo)
    visitorFormData.append("status", newVisitor.status)

    console.log(...visitorFormData)

    VisitorApi.updateVisitor(visitorFormData, newVisitor.visitor_id)
      .then(res => {
        if (res.status === 201) {
          setSaving(initialState.saving)

          NotificationManager.success(`${newVisitor.name}'s info has been successfully updated.`, "Success", 1000)
          history.push('/admin/visitors')
        } else {
          setSaving(initialState.saving)

          NotificationManager.error(`Something went wrong while updating ${newVisitor.name}: `, "Error", 1000)
        }
      })
      .catch(err => {
        setSaving(initialState.saving)

        NotificationManager.error(`Something went wrong while updating ${newVisitor.name}: ` + err, "Error", 1000)
      })
  }

  //Visitor input change handler
  const handleInputChangeVisitor = (event) => {
    const { name, value } = event.target

    if (name === 'name') {
      setErrorName(NameValidation(event)[1])
      setErrorNameIsOpen(NameValidation(event)[2])

      setNewVisitor((prevVisitor) => ({
        ...prevVisitor,
        [name]: value,
      }))

    } else if (name === 'email') {
      setErrorEmail(EmailValidation(event)[1])
      setErrorEmailIsOpen(EmailValidation(event)[2])

      setNewVisitor((prevVisitor) => ({
        ...prevVisitor,
        [name]: value,
      }))
    } else if (name === 'phone') {

      setErrorTelephone(TelephoneValidation(event)[1])
      setErrorTelephoneIsOpen(TelephoneValidation(event)[2])

      setNewVisitor((prevVisitor) => ({
        ...prevVisitor,
        [name]: value,
      }))
    } else if (name === "photo") {
      setErrorProfilePic(ImageValidation(event)[1])
      setErrorProfilePicIsOpen(ImageValidation(event)[2])

      let reader = new FileReader()

      reader.readAsDataURL(event.target.files[0])

      reader.onloadend = function () {
        setNewVisitor((prevVisitor) => ({
          ...prevVisitor,
          [name]: reader.result
        }))
      }

      setNewVisitor((prevVisitor) => ({
        ...prevVisitor,
        photo: event.target.files[0]
      }))
    } else {
      setNewVisitor((prevVisitor) => ({
        ...prevVisitor,
        [name]: value,
      }))
    }
  }

  return (
    <div className="container">
      <div className="row">
        <VisitorForm
          visitor={newVisitor}
          saving={saving}
          errorName={errorName}
          errorNameIsOpen={errorNameIsOpen}
          errorEmail={errorEmail}
          errorEmailIsOpen={errorEmailIsOpen}
          errorTelephone={errorTelephone}
          errorTelephoneIsOpen={errorTelephoneIsOpen}
          errorProfilePic={errorProfilePic}
          errorProfilePicIsOpen={errorProfilePicIsOpen}
          inputChange={handleInputChangeVisitor}
          addVisitor={handleAddVisitor}
          updateVisitor={handleUpdateVisitor}
          setPhoto={setPhoto}
        />
      </div>
    </div>
  )
}

export default ManageVisitorPage