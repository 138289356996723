//Necessary system imports
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap"

//Imports for Topbar and Sidebbar context
import { userContext } from "../../context/userContext"
import { roleContext } from "../../context/RoleContext"

import { AiOutlineApi } from "react-icons/ai"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeInLeft, fadeInRight, fadeInDown } from 'react-animations'
import Lottie from 'react-lottie'
import radiologistAnimation from "../../assets/lotties/lottie-radiologist"
import visitorAnimation from "../../assets/lotties/lottie-analysis"

const fadeInLeftAnimation = keyframes`${fadeInLeft}`
const fadeInRightAnimation = keyframes`${fadeInRight}`
const fadeInDownAnimation = keyframes`${fadeInDown}`

const FadeInLeftDiv = styled.div`animation:  0.9s ${fadeInLeftAnimation};`
const FadeInLeftButton = styled.div`animation:  1s ${fadeInLeftAnimation}; transition-delay: 3s`
const FadeInRightButton = styled.div`animation:  1s ${fadeInRightAnimation}; transition-delay: 3s`
const FadeInRightDiv = styled.div`animation:  0.9s ${fadeInRightAnimation};`
const FadeInDownDiv = styled.div`animation:  0.9s ${fadeInDownAnimation};`

//Initial State
const initialState = {
  isVisitorAnimationPaused: true,
  isRadiologistAnimationPaused: true
}

const LandingPage = () => {
  const history = useHistory()
  const { role, setRole } = useContext(roleContext)
  const { visible, setVisible } = useContext(userContext)
  const [collapsed, setCollapsed] = useState(true)
  const [isRadiologistAnimationPaused, setIsRadiologistAnimationPaused] = useState(initialState.isRadiologistAnimationPaused)
  const [isVisitorAnimationPaused, setIsVisitorAnimationPaused] = useState(initialState.isVisitorAnimationPaused)

  //Runs once on render
  useEffect(() => {
    setVisible(false)
    setRole('')
    localStorage.clear()
  }, [])

  const radiologistDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: radiologistAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }
  const visitorDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: visitorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <div className="container-fluid">
      <FadeInDownDiv style={{ background: "#e6e6e6", width: "100%", marginBottom: "2%", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
        <Navbar color="faded" light>
          <NavbarBrand href="/">XRAY DIAGNOSIS HELPER</NavbarBrand>
          <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink href="/api-user-login"><AiOutlineApi size="19" className="mb-1" />API Users Login</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </FadeInDownDiv>
      <div className="row">
        <FadeInLeftDiv
          className='col-lg-6'
          onMouseEnter={() => setIsRadiologistAnimationPaused(!isRadiologistAnimationPaused)}
          onMouseLeave={() => setIsRadiologistAnimationPaused(!isRadiologistAnimationPaused)}
        >
          <Lottie
            style={{ borderRadius: "50px", cursor: "default" }}
            isPaused={isRadiologistAnimationPaused}
            options={radiologistDefaultOptions}
            height={443}
            width={630}
          />
          <FadeInLeftButton>
            <button
              className="btn btn-lg btn-primary mt-2"
              style={{ width: '100%', backgroundColor: '#2a606d', color: 'white', borderRadius: '50px' }}
              onClick={() => history.push('/radiologist-login')}
            >
              Radiologist
            </button>
          </FadeInLeftButton>
        </FadeInLeftDiv>

        <FadeInRightDiv
          className='col-lg-6'
          onMouseEnter={() => setIsVisitorAnimationPaused(!isVisitorAnimationPaused)}
          onMouseLeave={() => setIsVisitorAnimationPaused(!isVisitorAnimationPaused)}
        >
          <Lottie
            style={{ borderRadius: "50px", cursor: "default" }}
            isPaused={isVisitorAnimationPaused}
            options={visitorDefaultOptions}
            height={443}
            width={630}
          />
          <FadeInRightButton>
            <button
              className="btn btn-lg mt-2"
              style={{ width: '100%', backgroundColor: '#59b1a7', color: 'white', borderRadius: '50px' }}
              onClick={() => history.push('/visitor-login')}
            >
              Visitor
            </button>
          </FadeInRightButton>
        </FadeInRightDiv>
      </div>
    </div>
  )
}

export default LandingPage