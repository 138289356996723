import React from 'react'
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${ fadeInAnimation };`

const Error = (props) => {
  return (
    <FadeInDiv 
      className='alert alert-danger mt-1' 
      role='alert'
    >
      {props.error}
    </FadeInDiv>
  )
}

export default Error
