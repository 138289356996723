import { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

//Imports for sidebar and topbar contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

//Import for Radiologist API handler
import * as ApiUserApi from '../../../api/users/api_user/ApiUserApi'

//Import for notifications
import NotificationManager from "react-notifications/lib/NotificationManager"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  errorOrg_firstName: "",
  errorOrg_lastName: "",
  errorTelephone: "",
  errorRegisterNumber: "",
  errorOrganization_name: "",
  errorAddress: "",
  errorOrg_firstNameIsOpen: false,
  errorOrg_lastNameIsOpen: false,
  errorTelephoneIsOpen: false,
  errorRegisterNumberIsOpen: false,
  errorOrganization_nameIsOpen: false,
  errorAddressIsOpen: false,
  apiUser: {
    api_user_id: "",
    address: "",
    documents: "",
    email: "",
    organization_name: "",
    registration_number: "",
    phone: "",
    status: true,
    password: ""
  },
  passVisible: false,
  saving: false
}

//Regular Expressions for validations
const nameRegExPattern = new RegExp(/[0-9]/)
const specialCharactersRegExPattern = new RegExp(/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/)
const telephoneRegExPattern = new RegExp(/[a-zA-Z]/)

const ApiUserProfile = (props) => {

  const api_user_id = props.match.params.id

  const { REACT_APP_API_URL } = process.env

  const history = useHistory()

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [passVisible, setPassVisible] = useState(initialState.passVisible)
  const [saving, setSaving] = useState(initialState.saving)

  const [errorOrg_firstName, setErrorOrg_firstName] = useState(initialState.errorOrg_firstName)
  const [errorOrg_lastName, setErrorOrg_lastName] = useState(initialState.errorOrg_lastName)
  const [errorTelephone, setErrorTelephone] = useState(initialState.errorTelephone)
  const [errorRegisterNumber, setErrorRegisterNumber] = useState(initialState.errorRegisterNumber)
  const [errorAddress, setErrorAddress] = useState(initialState.errorAddress)
  const [errorOrganization_name, setErrorOrganization_name] = useState(initialState.errorOrganization_name)

  const [errorOrg_firstNameIsOpen, setErrorOrg_firstNameIsOpen] = useState(initialState.errorOrg_firstNameIsOpen)
  const [errorOrg_lastNameIsOpen, setErrorOrg_lastNameIsOpen] = useState(initialState.errorOrg_lastNameIsOpen)
  const [errorTelephoneIsOpen, setErrorTelephoneIsOpen] = useState(initialState.errorTelephoneIsOpen)
  const [errorAddressIsOpen, setErrorAddressOpen] = useState(initialState.errorAddressIsOpen)
  const [errorRegisterNumberIsOpen, setErrorRegisterNumberIsOpen] = useState(initialState.errorRegisterNumberIsOpen)

  const [org_firstName, setOrg_firstName] = useState(initialState.apiUser.org_firstName)
  const [org_lastName, setOrg_lastName] = useState(initialState.apiUser.org_lastName)
  const [phone, setTelephone] = useState(initialState.apiUser.phone)
  const [email, setEmail] = useState(initialState.apiUser.email)
  const [password, setPassword] = useState(initialState.apiUser.password)
  const [status, setStatus] = useState(initialState.apiUser.status)
  const [registerNumber, setRegisterNumber] = useState(initialState.apiUser.registerNumber)
  const [organization_name, setOrganization_name] = useState(initialState.apiUser.organization_name)
  const [document, setDocument] = useState(initialState.apiUser.document)

  const [apiUser, setApiUser] = useState(initialState.apiUser)

  const firstUpdate = useRef(true)

  //Runs once on render
  useEffect(() => {
    console.log(props)

    setVisible(true)
    setRole(localStorage.getItem('role'))

    ApiUserApi.getApiUser(api_user_id)
      .then(res => {
        setApiUser(res)
      })
  }, [])

  useEffect(() => {
    console.log(apiUser)
  }, [apiUser])

  // //Runs whenever apiUser changes
  // useEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false
  //     return
  //   } else {
  //     setEmail(apiUser.email)
  //     setTelephone(apiUser.phone)
  //     setPassword(apiUser.password)
  //     setStatus(apiUser.status)
  //     setRegisterNumber(apiUser.registration_number)
  //     setOrganization_name(apiUser.organization_name)
  //     setDocument(apiUser.document)
  //   }
  // }, [apiUser])

  //Handler for input field onChange
  const onChangeHandler = (event) => {
    const { name, value } = event.target

    if (name === "org_firstName" || name === "org_lastName") {
      nameValidation(event)

      setApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    } else if (name === "phone") {
      telephoneValidation(event)

      setApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    } else {
      setApiUser((prevApiUser) => ({
        ...prevApiUser,
        [name]: value,
      }))
    }
  }

  //Validate name fields
  const nameValidation = (event) => {
    const { name, value } = event.target

    if (name === 'firstName') {

      if (value.length === 0) {
        setErrorOrg_firstName("Empty Field")
        setErrorOrg_firstNameIsOpen(true)
        return false
      } else if (value.length > 0 && value.length < 3) {
        setErrorOrg_firstName("Name must be atleast 3 characters long")
        setErrorOrg_firstNameIsOpen(true)
        return false
      } else if (nameRegExPattern.test(value)) {
        console.log(value)
        setErrorOrg_firstName("Numeric values are not allowed")
        setErrorOrg_firstNameIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorOrg_firstName("Special Characters are not allowed")
        setErrorOrg_firstNameIsOpen(true)
        return false
      } else {
        setErrorOrg_firstName("")
        setErrorOrg_firstNameIsOpen(false)
        return true
      }
    } else {
      if (value.length === 0) {
        setErrorOrg_lastName("Empty Field")
        setErrorOrg_lastNameIsOpen(true)
        return false
      } else if (value.length > 0 && value.length < 3) {
        setErrorOrg_lastName("Name must be atleast 3 characters long")
        setErrorOrg_lastNameIsOpen(true)
        return false
      } else if (nameRegExPattern.test(value)) {
        console.log(value)
        setErrorOrg_lastName("Numeric values cannot be present in name")
        setErrorOrg_lastNameIsOpen(true)
        return false
      } else if (specialCharactersRegExPattern.test(value)) {
        setErrorOrg_lastName("Special Characters are not allowed")
        setErrorOrg_lastNameIsOpen(true)
        return false
      } else {
        setErrorOrg_lastName("")
        setErrorOrg_lastNameIsOpen(false)
        return true
      }
    }
  }

  //Validate phone field
  const telephoneValidation = (event) => {
    const { name, value } = event.target

    if (value.length === 0) {
      setErrorTelephone("Phone Number is required")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (value.length < 11) {
      setErrorTelephone("Phone Number must contain 11 digits")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (telephoneRegExPattern.test(value)) {
      setErrorTelephone("Alphabet cannot be present in phone number")
      setErrorTelephoneIsOpen(true)
      return false
    } else if (specialCharactersRegExPattern.test(value)) {
      setErrorTelephone("Special Characters are not allowed")
      setErrorTelephoneIsOpen(true)
      return false
    } else {
      setErrorTelephone("")
      setErrorTelephoneIsOpen(false)
      return true
    }
  }

  //Handler for upadting API User
  const updateApiUser = () => {

    setSaving(true)

    const apiUserFormData = new FormData()

    // apiUserFormData.append("_id", apiUser._id)
    apiUserFormData.append("email", apiUser.email)
    apiUserFormData.append("phone", apiUser.phone)
    apiUserFormData.append("password", apiUser.password)
    apiUserFormData.append("registration_number", apiUser.registration_number)
    apiUserFormData.append("documents", JSON.stringify(apiUser.documents))
    apiUserFormData.append("organization_name", apiUser.organization_name)
    apiUserFormData.append("address", apiUser.address)
    apiUserFormData.append("status", apiUser.status)

    console.log(...apiUserFormData)

    ApiUserApi.updateApiUser(apiUserFormData, apiUser.api_user_id)
      .then(res => {
        if (res.status === 201) {
          setSaving(initialState.saving)

          NotificationManager.success(`${apiUser.organization_name}'s info has been updated.`, "Success", 1000)
        } else {
          NotificationManager.error(`Something went wrong while updating ${apiUser.organization_name}`, "Error", 1000)
          setSaving(initialState.saving)
        }
      })
      .catch(err => {
        NotificationManager.error(`Something went wrong while updating ${apiUser.organization_name}: ` + err, "Error", 1000)
        setSaving(initialState.saving)
      })
  }

  const handleDocumentView = (base64URL) => {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  return (
    <FadeInDiv className="card shadow mb-4" style={{ width: "100%" }}>
      <div className="card-body row">
        <div className="col-md-6" style={{ left: '30%' }}>

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Organization Name</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={apiUser.organization_name}
                name="organization_name"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Registration Number</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={apiUser.registration_number}
                name="registration_number"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Address</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={apiUser.address}
                name="address"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Email</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={apiUser.email}
                name="email"
                readOnly
                disabled
              />
            </div>
          </div>

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Phone</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                value={apiUser.phone}
                name="phone"
                onChange={onChangeHandler}
              />
            </div>
            {errorTelephoneIsOpen ?
              <FadeInDiv
                className='alert alert-danger'
                role='alert'
              >
                {errorTelephone}
              </FadeInDiv> :
              <></>
            }
          </div>

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Password</label>
            </div>
            <div className="col-md-6">
              <input
                className="form-control form-control-sm"
                type={passVisible ? "text" : "password"}
                value={apiUser.password}
                readOnly
                disabled
              />
            </div>
            <div className="small mt-1">
              <i
                style={{ color: '#59b1a7' }}
                onClick={() => setPassVisible(prevState => !prevState)}
                className={passVisible ? "far fa-eye" : "far fa-eye-slash"}
              ></i>
            </div>
          </div>



          {apiUser.documents.length === 0 ?
            <></> :
            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Document 1</label>
              </div>
              <div className="col-md-6 text-center">
                <label style={{ cursor: "pointer" }} className="text-primary font-weight-bold" onClick={() => handleDocumentView(document[0])}>View Document 1</label>
              </div>
            </div>
          }

          {apiUser.documents.length === 0 ?
            <></> :
            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Document 2</label>
              </div>
              <div className="col-md-6 text-center">
                <label style={{ cursor: "pointer" }} className="text-primary font-weight-bold" onClick={() => handleDocumentView(document[1])}>View Document 2</label>
              </div>
            </div>
          }

          {apiUser.documents.length === 0 ?
            <></> :
            <div className="row mb-1">
              <div className="col-md-3 font-weight-bold">
                <label>Document 3</label>
              </div>
              <div className="col-md-6 text-center">
                <label style={{ cursor: "pointer" }} className="text-primary font-weight-bold" onClick={() => handleDocumentView(document[2])}>View Document 3</label>
              </div>
            </div>
          }

          <div className="row mb-1">
            <div className="col-md-4 font-weight-bold">
              <label>Status</label>
            </div>
            <div className="col-md-6">
              {apiUser.status ?
                <p
                  className="text-success font-weight-bold text-center"
                >
                  Active
                </p> :
                <p
                  className="text-danger font-weight-bold text-center"
                >
                  Suspended
                </p>
              }
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-md-4" style={{ left: "165px" }}>
              {saving ?
                <button
                  style={{ borderRadius: '50px' }}
                  type="button"
                  className="btn btn-outline-success btn-block"
                  disabled
                >
                  <i class="fas fa-spinner fa-spin mr-2"></i>
                  Updating
                </button> :
                <button
                  type="button"
                  style={{ borderRadius: '50px' }}
                  className="btn btn-outline-primary btn-block"
                  onClick={updateApiUser}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square mr-2" viewBox="0 0 16 17">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                  Update
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default ApiUserProfile