//Necessary system imports
import React, { useContext, useEffect, useState } from "react"
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"
import { useHistory, withRouter } from "react-router-dom"

//Imports for counting number of users for statistics display for admin
import * as VisitorApi from '../../../api/users/visitor/VisitorApi'
import * as RadiologistApi from '../../../api/users/radiologist/RadiologistApi'
import * as ApiUserApi from '../../../api/users/api_user/ApiUserApi'

//Import for admin login API
import { AdminLoginApi } from '../../../api/auth/login/LoginApi'

import NotificationManager from "react-notifications/lib/NotificationManager"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  userData: {
    email: "",
    password: "",
  },
  passVisible: false
}

const AdminLogin = (props) => {
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)

  const [email, setEmail] = useState(initialState.userData.email)
  const [password, setPassword] = useState(initialState.userData.password)
  const [userData, setUserData] = useState(initialState.userData)
  const [passVisible, setPassVisible] = useState(initialState.passVisible)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  //Use Effect to run for first time render
  useEffect(() => {
    setVisible(false)
    setRole('')
    if (localStorage.getItem("token") && localStorage.getItem('role') === 'admin')
      history.push("/admin")
  }, [])

  //onChange handler for input fields
  const handlechange = (event) => {
    const { name, value } = event.target

    setUserData(prevUserData => ({
      ...prevUserData,
      [name]: value,
    }))
  }

  //handle login Click
  const handleClick = (event) => {
    event.preventDefault()

    setLoading(true)

    const adminFormData = new FormData()

    adminFormData.append("email", userData.email)
    adminFormData.append("password", userData.password)

    //API call handler for logging in admin
    AdminLoginApi(adminFormData)
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          localStorage.setItem('token', `Bearer ${res.access_token}`)
          localStorage.setItem('user_id', res.user_id)
          localStorage.setItem('user_email', userData.email)
          localStorage.setItem('role', 'admin')
          const emailFirstHalf = localStorage.getItem("user_email").indexOf("@")
          localStorage.setItem("user_name", localStorage.getItem("user_email").substring(0, emailFirstHalf))

          if (localStorage.getItem('token')) {
            NotificationManager.success(`Welcome Back ${localStorage.getItem('user_name')}`, "Success", 1000)
            setTimeout(() => {
              setLoading(false)
              history.push("/admin")
            }, 1000)
          }
        } else if (res.status === 141) {
          localStorage.setItem('token', `asdasd`)
          localStorage.setItem('role', 'admin')

          setLoading(false)

          history.push("/admin")
        } else if (res.status === 400) {
          setLoading(false)

          NotificationManager.error('User doesn\'t exist', "Error", 1000)

          history.push("/admin")
        } else {
          setLoading(false)

          NotificationManager.error('Email or password is incorrect', "Error", 1000)
        }
      }).catch(err => {
        setLoading(false)

        NotificationManager.error("Somehting went wrong: " + err, "Error", 1000)
      })
  }

  return (
    <FadeInDiv className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-12 col-md-9">
          <div className="card o-hidden shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-3 d-none d-lg-block">
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-primary mb-4">Admin Login</h1>
                    </div>
                    <form className="user" onSubmit={handleClick}>
                      <div className="form-group" style={{ position: 'relative' }}>
                        <input
                          style={{ borderRadius: "50px" }}
                          type="email"
                          maxLength='36'
                          className="form-control form-control-sm"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                          name="email"
                          onChange={handlechange}
                        />
                      </div>
                      <div className="form-group" style={{ position: 'relative' }}>
                        <input
                          style={{ borderRadius: "50px" }}
                          type={passVisible ? "text" : "password"}
                          className="form-control form-control-sm"
                          id="exampleInputPassword"
                          maxLength='36'
                          placeholder="Enter Password..."
                          name="password"
                          onChange={handlechange}
                        />
                      </div>

                      <div className="form-group">
                        <div className="small">
                          <i
                            style={{ color: '#4e73df', float: "left", paddingTop: "5px" }}
                            onClick={() => setPassVisible(prevState => !prevState)}
                            className={passVisible ? "far fa-eye" : "far fa-eye-slash"}
                          ></i>
                          <label
                            style={{ paddingLeft: "10px" }}
                            onClick={() => setPassVisible(prevState => !prevState)}
                          >
                            {passVisible ? "Hide Password" : "Show Password"}
                          </label>
                        </div>
                      </div>
                      <button
                        style={{ borderRadius: "50px" }}
                        disabled={loading}
                        className="btn btn-primary btn-block text-center"
                        type="submit"
                      >
                        {loading ? "Logging in..." : "Login"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default withRouter(AdminLogin)
