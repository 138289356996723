import { useContext, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"

//Imports for API User, sidebar and topbar contexts
import { APIUserContext } from "../../../context/APIUserContext"
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"

//Imports for API User API
import * as ApiUserApi from '../../../api/users/api_user/ApiUserApi'

//Import for notifications
import NotificationManager from "react-notifications/lib/NotificationManager"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  apiUser: {
    api_user_id: "",
    address: "",
    documents: [],
    email: "",
    password: "",
    app_token: "",
    organization_name: "",
    registration_number: "",
    phone: "",
    status: true
  }
}

const ViewAPIUser = (props) => {
  const APIUserId = props.match.params.id

  const history = useHistory()

  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const { contextAPIUser, setContextAPIUser } = useContext(APIUserContext)

  const firstAPIUserUpdate = useRef(true)
  const firstContextAPIUserUpdate = useRef(true)

  const [APIUser, setAPIUser] = useState(initialState.apiUser)

  const [org_firstName, setOrg_firstName] = useState(initialState.apiUser.org_firstName)
  const [org_lastName, setOrg_lastName] = useState(initialState.apiUser.org_lastName)
  const [phone, setTelephone] = useState(initialState.apiUser.phone)
  const [email, setEmail] = useState(initialState.apiUser.email)
  const [app_token, setApp_Token] = useState(initialState.app_token)
  const [address, setAddress] = useState(initialState.apiUser.address)
  const [document, setDocument] = useState(initialState.apiUser.document)
  const [password, setPassword] = useState(initialState.apiUser.password)
  const [registerNumber, setRegisterNumber] = useState(initialState.apiUser.registerNumber)
  const [organization_name, setOrganization_name] = useState(initialState.apiUser.organization_name)
  const [status, setStatus] = useState(initialState.apiUser.status)

  useEffect(() => {
    console.log(props)

    setVisible(true)
    setRole(localStorage.getItem('role'))

    /*Setup for api calling for single user on page load*/

    const APIUserDetailFormData = new FormData()

    APIUserDetailFormData.append("id", APIUserId)

    ApiUserApi.getApiUser(APIUserId)
      .then(res => {
        setAPIUser(res)
        setContextAPIUser(res)
      })
      .catch(err => {
        NotificationManager.error(`Something went wrong: ` + err, 'Error', 1000)
      })
  }, [])

  //Runs whenever context of API User is changed
  useEffect(() => {
    if(contextAPIUser.status !== APIUser.status) {
      const APIUserFormData = new FormData()

      APIUserFormData.append("_id", contextAPIUser.api_user_id)
      APIUserFormData.append("email", contextAPIUser.email)
      APIUserFormData.append("address", contextAPIUser.address)
      APIUserFormData.append("phone", contextAPIUser.phone)
      APIUserFormData.append("registration_number", contextAPIUser.registration_number)
      APIUserFormData.append("organization_name", contextAPIUser.organization_name)
      APIUserFormData.append("status", contextAPIUser.status)
      APIUserFormData.append("app_token", contextAPIUser.app_token)
      APIUserFormData.append("documents", contextAPIUser.documents)

      console.log(...APIUserFormData)

      ApiUserApi.updateApiUser(APIUserFormData, contextAPIUser.api_user_id)
        .then(res => {

          if (contextAPIUser.status === true)
            NotificationManager.success(`${contextAPIUser.organization_name} has been reinstated`, 'Success', 1000)
          else
            NotificationManager.success(`${contextAPIUser.organization_name} has been suspended`, 'Success', 1000)

          setTimeout(() => {
            history.push('/admin/api-users')
          }, 1000)

          console.log(res)
        })
        .catch(err => NotificationManager.error(`Something went wrong while suspending ${contextAPIUser.organiztion_name}: ` + err, 'Error', 1000))
    }
  }, [contextAPIUser])

  const handleDocumentView = (base64URL) => {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  return (
    <FadeInDiv className="card shadow mb-1" style={{ width: "100%" }}>
      <div className="card-header">
        <h5 className="ml-1 h4">
          API User
        </h5>
        <div className='row'>
          <h6 className="ml-3 h2 text-primary">
            {APIUser.organization_name}
          </h6>
          <div style={{ width: '100%' }}>
            <Link
              to={{
                pathname: "/admin/api-users/" + APIUser.api_user_id + "/edit",
                data: {
                  apiUserData: APIUser
                }
              }}
            >
              <button
                className="btn btn-primary btn-sm float-right"
                type="button"
                style={{ marginRight: "2%", marginLeft: "2%" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-pencil-square mr-1"
                  viewBox="0 1 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>
                Update
              </button>
            </Link>
            {APIUser.status ?
              <button
                className="btn btn-danger btn-sm float-right"
                type="button"
                onClick={() => {
                  setContextAPIUser({ ...contextAPIUser, status: false })
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-person-x mr-1"
                  viewBox="0 1 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path fillRule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                </svg>
                Suspend
              </button> :
              <button
                className="btn btn-success btn-sm float-right"
                type="button"
                onClick={() => {
                  setContextAPIUser({ ...contextAPIUser, status: true })
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-person-check mr-1"
                  viewBox="0 1 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                </svg>
                Reinstate
              </button>
            }
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-bordered text-center"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <tbody>
              <tr>
                <th className="font-weight-bold">Organization Name</th>
                <th>{APIUser.organization_name === "" ? 'Fetching Data...' : APIUser.organization_name}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">Email</th>
                <th>{APIUser.email === "" ? 'Fetching Data...' : APIUser.email}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">Phone</th>
                <th>{APIUser.phone === "" ? 'Fetching Data...' : APIUser.phone}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">Address</th>
                <th>{APIUser.address === "" ? 'Fetching Data...' : APIUser.address}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">Password</th>
                <th>{APIUser.password === "" ? 'Fetching Data...' : APIUser.password}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">App Token</th>
                <th className=" text-primary" style={{ cursor: "pointer" }} data-toggle="modal" data-target="#myModal">{APIUser.app_token === "" ? 'Fetching Data...' : "Click to view"}</th>
              </tr>
              <tr>
                <th></th>
                <th>{APIUser.documents === [] ?  'Document Not Found' : APIUser.documents[0] === undefined ? 'Fetching Document...' : <a className="text-primary" style={{ cursor: "pointer" }} onClick={() => handleDocumentView(APIUser.documents[0])}>View Document 1</a>}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">Documents</th>
                <th>{APIUser.documents === [] ? 'Document Not Found' : APIUser.documents[1] === undefined ? 'Fetching Document...' :  <a className="text-primary" style={{ cursor: "pointer" }} onClick={() => handleDocumentView(APIUser.documents[1])}>View Document 2</a>}</th>
              </tr>
              <tr>
                <th></th>
                <th>{APIUser.documents === [] ? 'Document Not Found' : APIUser.documents[2] === undefined ? <></> :  <a className="text-primary" style={{ cursor: "pointer" }} onClick={() => handleDocumentView(APIUser.documents[2])}>View Document 3</a>}</th>
              </tr>
              <tr>
                <th className="font-weight-bold">Status</th>
                {APIUser.status === "" || APIUser.status ?
                  <th className="text-success">
                    {status === "" ? 'Fetching Data...' : "Active"}
                  </th> :
                  <th className="text-danger">
                    {status === "" ? 'Fetching Data...' : "Suspended"}
                  </th>
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">App Token</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <p>{APIUser.app_token}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default ViewAPIUser