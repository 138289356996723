//Imports for styling notifications and app
import "./components/Layout/App.css"
import "react-notifications/lib/notifications.css"

//Necessary Imports
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useState } from "react"

// //Imports for user specific dashboards
// import Admin from "./components/users/admin/Admin"
// import APIUser from "./components/users/api-user/APIUser"
// import Visitor from "./components/users/visitor/Visitor"
// import Radiologist from "./components/users/radiologist/Radiologist"
// import XrayFeedbackPage from "./components/users/admin/XrayFeedbackPage"

//Imports for utility components
import Topbar from "./components/Layout/partials/Topbar"
import Sidebar from "./components/Layout/partials/Sidebar"
import PageNotFound from "./components/utility/PageNotFound"

//Imports for user specific contexts
import { userContext } from "./context/userContext"
import { roleContext } from "./context/RoleContext"
import APIUserContext from "./context/APIUserContext"
import { VisitorContext } from "./context/VisitorContext"
import { RadiologistContext } from "./context/RadiologistContext"

// //Imports for user specific management pages
// import ManageApiUserPage from "./components/users/api-user/ManageApiUserPage"
// import ManageVisitorPage from "./components/users/visitor/ManageVisitorPage"
// import ManageRadiologistPage from "./components/users/radiologist/ManageRadiologistPage"

// //Imports for user specific login Pages
// import AdminLogin from "./components/auth/admin/AdminLogin"
// import VisitorLogin from "./components/auth/visitor/VisitorLogin"
// import APIUserLogin from "./components/auth/api-user/APIUserLogin"
// import RadiologistLogin from "./components/auth/radiologist/RadiologistLogin"

// //Imports for list rendering pages for specific users
// import ApiUserPage from "./components/users/api-user/ApiUserPage"
// import VisitorPage from "./components/users/visitor/VisitorPage"
// import RadiologistPage from "./components/users/radiologist/RadiologistPage"

// //Imports for user specific view pages
// import ViewVisitor from "./components/users/visitor/ViewVisitor"
// import ViewAPIUser from "./components/users/api-user/ViewAPIUser"
// import ViewRadiologist from "./components/users/radiologist/ViewRadiologist"

//Import for notifications
import NotificationContainer from "react-notifications/lib/NotificationContainer"

// //Import for landing page
// import LandingPage from "./components/utility/LandingPage"

// //Import for user specific profile components
// import VisitorProfile from "./components/users/visitor/VisitorProfile"
// import ApiUserProfile from "./components/users/api-user/ApiUserProfile"
// import RadiologistProfile from "./components/users/radiologist/RadiologistProfile"

// //Import for documentation components for API User
// import DiagnosisDocumentation from "./components/users/api-user/documentation/DiagnosisDocumentation"
// import Model1FeedbackDocumentation from "./components/users/api-user/documentation/Model1FeedbackDocumentation"
// import Model2FeedbackDocumentation from "./components/users/api-user/documentation/Model2FeedbackDocumentation"
// import ViewAppToken from "./components/users/api-user/ViewAppToken"
// import ChangePassword from "./components/utility/ChangePassword"
import { adminRoutes, userRoutes, visitorRoutes, radiologistRoutes, apiUserRoutes, xrayDetailRoutes } from "./routes/allRoutes"
import AuthMiddleware from "./routes/middleware/AuthMiddleware"

//Initial States for visitor, apiUser, radiologist and user role
const initialState = {
  role: "",
  radiologist: {
    _id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    qualification: "",
    photo: "",
    documents: [],
    status: true
  },
  visitor: {
    _id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    photo: "",
    status: true
  },
  apiUser: {
    _id: "",
    address: "",
    document: [],
    email: "",
    organization_name: "",
    registration_number: "",
    phone: "",
    status: true,
    password: ""
  }
}

const App = props => {
  const { REACT_APP_SERVER_UP } = process.env

  const [visible, setVisible] = useState(false)
  const [role, setRole] = useState(initialState.role)
  const [contextVisitor, setContextVisitor] = useState(initialState.visitor)
  const [contextAPIUser, setContextAPIUser] = useState(initialState.apiUser)
  const [contextRadiologist, setContextRadiologist] = useState(initialState.radiologist)

  return (
    <Router>
      <div id='wrapper'>
        {role === "admin" || role === "apiUser" ? <Sidebar /> : null}
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content' className='bg-white'>
            {visible ? <Topbar /> : null}

            <Switch>
              {/*App wrapped in sidebar and topbar contexts*/}
              <roleContext.Provider value={{ role, setRole }}>
                <userContext.Provider value={{ visible, setVisible }}>
                  {/*Context wrapped Visitor management Rout es for Admin*/}
                  <VisitorContext.Provider value={{ contextVisitor, setContextVisitor }}>
                    {/*Context wrapped API User management Routes for Admin*/}
                    <APIUserContext.Provider value={{ contextAPIUser, setContextAPIUser }}>
                      {/*Context wrapped Radiologist management Routes for Admin*/}
                      <RadiologistContext.Provider value={{ contextRadiologist, setContextRadiologist }}>
                        {adminRoutes.map((route, idx) => (
                          <AuthMiddleware
                            path={route.path}
                            component={route.component}
                            key={idx}
                            exact
                            isAuthProtected={true}
                            isRoleProtected={true}
                            serverUp={REACT_APP_SERVER_UP === "TRUE" ? true : false}
                            AllowedRoles={["admin"]}
                          />
                        ))}
                      </RadiologistContext.Provider>
                    </APIUserContext.Provider>
                  </VisitorContext.Provider>

                  {radiologistRoutes.map((route, idx) => (
                    <AuthMiddleware
                      path={route.path}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      isRoleProtected={true}
                      serverUp={REACT_APP_SERVER_UP === "TRUE" ? true : false}
                      AllowedRoles={["radiologist"]}
                      exact
                    />
                  ))}

                  {visitorRoutes.map((route, idx) => (
                    <AuthMiddleware
                      path={route.path}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      isRoleProtected={true}
                      AllowedRoles={["visitor"]}
                      exact
                    />
                  ))}

                  {apiUserRoutes.map((route, idx) => (
                    <AuthMiddleware
                      path={route.path}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      isRoleProtected={true}
                      AllowedRoles={["apiUser"]}
                      exact
                    />
                  ))}

                  {xrayDetailRoutes.map((route, idx) => (
                    <AuthMiddleware
                      path={route.path}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      isRoleProtected={true}
                      AllowedRoles={["radiologist, visitor"]}
                      exact
                    />
                  ))}

                  {userRoutes.map((route, idx) => (
                    <AuthMiddleware path={route.path} component={route.component} key={idx} isAuthProtected={false} exact />
                  ))}
                </userContext.Provider>
              </roleContext.Provider>
            </Switch>
          </div>
        </div>

        {/*Notification rendering component*/}
        <NotificationContainer />
      </div>
    </Router>
  )
}

export default App
