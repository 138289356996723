//Necessary system imports
import React, { useContext, useEffect, useState } from "react"
import { userContext } from "../../../context/userContext"
import { useHistory, withRouter } from "react-router-dom"

//Import for radiologist login API
import { RadiologistLoginApi } from '../../../api/auth/login/LoginApi'

import NotificationManager from "react-notifications/lib/NotificationManager"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  errorEmail: "",
  errorPassword: "",
  errorEmailIsOpen: false,
  errorPasswordIsOpen: false,
  passVisible: false,
  emailList: ["@gmail", "@yahoo", "@outlook", '@pkteam'],

  userData: {
    email: "",
    password: "",
  },

  radiologist: {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    password: "",
    profile_pic: "",
    qualification: "",
    uploadedDocumentPath: "",
    status: true
  }
}

const RadiologistLogin = (props) => {
  const { visible, setVisible } = useContext(userContext)

  const [email, setEmail] = useState(initialState.userData.email)
  const [password, setPassword] = useState(initialState.userData.password)

  const [userData, setUserData] = useState(initialState.userData)
  const [passVisible, setPassVisible] = useState(initialState.passVisible)

  const [loading, setLoading] = useState(false)

  const emailList = useState(initialState.emailList)

  const [errorEmail, setErrorEmail] = useState(initialState.errorEmail)
  const [errorPassword, setErrorPassword] = useState(initialState.errorPassword)
  const [errorEmailIsOpen, setErrorEmailIsOpen] = useState(initialState.errorEmailIsOpen)
  const [errorPasswordIsOpen, setErrorPasswordIsOpen] = useState(initialState.errorPasswordIsOpen)

  const [radiologistDetail, setRadiologistDetail] = useState(initialState.radiologist)

  const history = useHistory()

  //Runs only once on render
  useEffect(() => {
    setVisible(false)

    if (localStorage.getItem("token") && localStorage.getItem('role') === 'radiologist')
      history.push("/radiologist")
  }, [])

  //Runs whenever radiologistDetail is changed
  useEffect(() => {
    localStorage.setItem('radiologist_detail', JSON.stringify(radiologistDetail))

    localStorage.setItem('user_email', userData.email)
    localStorage.setItem('role', 'radiologist')

    if (localStorage.getItem('token')) {
      NotificationManager.success(`Welcome Back ${localStorage.getItem('user_name')}`, "Success", 1000)
      history.push("/radiologist")
    }
  }, [radiologistDetail])

  //Input Change handler for email and password input fields
  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'email') {
      emailValidation(event)
      setUserData(prevUserData => ({
        ...prevUserData,
        [name]: value,
      }))
    } else {
      passwordValidation(event)
      setUserData(prevUserData => ({
        ...prevUserData,
        [name]: value,
      }))
    }
  }

  //validate email Field
  const emailValidation = (event) => {
    if (event.target.value.length === 0) {
      setErrorEmail("Empty Field")
      setErrorEmailIsOpen(true)
      return false
    } else {
      if (!event.target.value.includes(emailList[0][0])) {
        if (!event.target.value.includes(emailList[0][1])) {
          if (!event.target.value.includes(emailList[0][2])) {
            if (!event.target.value.includes(emailList[0][3])) {
              setErrorEmail("Enter a valid email address")
              setErrorEmailIsOpen(true)
              return false
            }
          }
        }
      }
    }

    setErrorEmail("")
    setErrorEmailIsOpen(false)
    return true
  }

  //Validate password Field
  const passwordValidation = (event) => {
    if (event.target.value.length === 0) {
      setErrorPassword("Empty Field")
      setErrorPasswordIsOpen(true)
      return false
    } else {
      if (event.target.value.length < 6) {
        setErrorPassword("Enter password with atleast 6 characters")
        setErrorPasswordIsOpen(true)
        return false
      }
    }

    setErrorPassword("")
    setErrorPasswordIsOpen(false)
    return true
  }

  //Handler for login button click
  const handleLoginClick = (event) => {
    event.preventDefault()

    setLoading(true)

    let radiologistFormData = new FormData()

    radiologistFormData.append('email', userData.email)
    radiologistFormData.append('password', userData.password)

    //API call handler for logging in radiologist
    RadiologistLoginApi(radiologistFormData)
      .then(res => {
        console.log(res)

        if (res.message === 'wrong email or password') {
          setLoading(false)
          NotificationManager.error('Username or password is incorrect', "Error", 1000)
        } else if (res.message === 'User is Suspended') {
          setLoading(false)
          NotificationManager.error("You've been suspended, please contact admin for more information.", "Error", 1000)
        } else if (res.status === 141) {
          localStorage.setItem('token', `asdasd`)
          localStorage.setItem('role', 'radiologist')

          setLoading(false)

          history.push("/radiologist")
        } else {
          localStorage.setItem('token', `Bearer ${res.access_token}`)
          localStorage.setItem('name', res.name)
          localStorage.setItem('user_id', res.user_id)
          localStorage.setItem('photo', res.photo)
          localStorage.setItem('role', 'radiologist')

          setLoading(false)

          history.push('/radiologist')

          // var radiologist_detail = {
          //   _id: res._id,
          //   firstName: res.firstName,
          //   lastName: res.lastName,
          //   email: res.email,
          //   telephone: res.telephone,
          //   password: res.password,
          //   profile_pic: res.profile_pic,
          //   status: res.status,
          //   qualification: res.qualification,
          //   uploadedDocumentPath: res.uploadedDocumentPath
          // }

          // localStorage.setItem('user_name', res.firstName + " " + res.lastName)

          // setRadiologistDetail(radiologist_detail)
        }
      }).catch(err => {
        setLoading(false)
        
        NotificationManager.error("Somehting went wrong: " + err, "Error", 1000)
      })
  }

  return (
    <FadeInDiv className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-12 col-md-9">
          <div className="card o-hidden shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-3 d-none d-lg-block">
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 mb-4" style={{ color: '#2a606d' }}>Radiologist Login</h1>
                    </div>
                    <form className="user" onSubmit={handleLoginClick}>
                      <div className="form-group" style={{ position: 'relative' }}>
                        <input
                          style={{ borderRadius: "50px" }}
                          type="email"
                          maxLength='36'
                          className="form-control form-control-sm"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="example@mail.com"
                          name="email"
                          onChange={handleChange}
                        />
                        {errorEmailIsOpen ?
                          <FadeInDiv
                            className='alert alert-danger'
                            role='alert'
                          >
                            {errorEmail}
                          </FadeInDiv> :
                          <></>
                        }
                      </div>
                      <div className="form-group" style={{ position: 'relative' }}>
                        <input
                          style={{ borderRadius: "50px" }}
                          type={passVisible ? "text" : "password"}
                          className="form-control form-control-sm"
                          id="exampleInputPassword"
                          placeholder="Enter Password..."
                          maxLength='6'
                          name="password"
                          onChange={handleChange}
                        />
                        {errorPasswordIsOpen ?
                          <FadeInDiv
                            className='alert alert-danger'
                            role='alert'
                          >
                            {errorPassword}
                          </FadeInDiv> :
                          <></>
                        }
                      </div>

                      <div className="form-group">
                        <div className="small">
                          <i
                            style={{ color: '#2a606d', float: "left", paddingTop: "5px" }}
                            onClick={() => setPassVisible(prevState => !prevState)}
                            className={passVisible ? "far fa-eye" : "far fa-eye-slash"}
                          ></i>
                          <label
                            style={{ paddingLeft: "10px" }}
                            onClick={() => setPassVisible(prevState => !prevState)}
                          >
                            {passVisible ? "Hide Password" : "Show Password"}
                          </label>
                        </div>
                      </div>
                      <button
                        className="btn btn-block text-center"
                        disabled={loading}
                        type="submit"
                        style={{ backgroundColor: '#2a606d', color: 'white', borderRadius: "50px" }}
                      >
                        {loading ? "Logging in..." : "Login"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="suspensionModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Something's not right</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>It looks like you've been suspended, please contact admin for further information.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeInDiv>
  )
}

export default withRouter(RadiologistLogin)
