import { get, patch, post, put } from "../../middleware/ApiCallsMiddleware"

//Get Api URL from .env file
const { REACT_APP_API_URL } = process.env

const createAPIUserEndpoint = REACT_APP_API_URL + "/apiUser/register"
const updateAPIUserEndpoint = REACT_APP_API_URL + "/apiUser/update?user_id="
const getAPIUserEndpoint = REACT_APP_API_URL + "/apiUser/?user_id="
const getAPIUsersEndpoint = REACT_APP_API_URL + "/apiUser/"
const resetAPIUserTokenEndpoint = REACT_APP_API_URL + "/reset_token"
const resetPasswordEndpoint = REACT_APP_API_URL + "/apiUser/reset_password"

//Api handler for creating Api User via Fetch API using FormData
export const createApiUser = async(apiUserFormData) => {
  return await post(createAPIUserEndpoint, apiUserFormData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for updating existing Api User via Fetch API using FormData
export const updateApiUser = async(apiUserFormData, user_id) => {
  return await put(updateAPIUserEndpoint + user_id, apiUserFormData, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting a Api User via Fetch API
export const getApiUser = async (user_id) => {
  return await get(getAPIUserEndpoint + user_id, { 'Authorization': localStorage.getItem('token') })
}

//Api Handler for getting list of Api Users via Fetch API
export const getApiUsers = async () => {
  return await get(getAPIUsersEndpoint, { 'Authorization': localStorage.getItem('token') })
}

export const resetApiUserKey = async (userData) => {
  return await post(resetAPIUserTokenEndpoint, userData, { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json' })
}

export const resetPassword = async (userData) => {
  return await patch(resetPasswordEndpoint, userData, { 'Authorization': localStorage.getItem('token') })
}