import { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

//Import for Visitor List component
import VisitorList from "./VisitorsList"

//Imports for sidebar, topbar and visitor contexts
import { userContext } from "../../../context/userContext"
import { roleContext } from "../../../context/RoleContext"
import { VisitorContext } from "../../../context/VisitorContext"

//Import for visitor api handler
import * as VisitorApi from "../../../api/users/visitor/VisitorApi"

//Import for notifications 
import NotificationManager from "react-notifications/lib/NotificationManager"

//Imports for loader
import { css } from "@emotion/core"
import BarLoader from "react-spinners/BarLoader"

//Imports for animations
import styled, { keyframes } from "styled-components"
import { fadeIn } from 'react-animations'
import { Col, Input, Row } from "reactstrap"
import { searcher } from "../../utility/Searcher"

const fadeInAnimation = keyframes`${fadeIn}`
const FadeInDiv = styled.div`animation:  0.9s ${fadeInAnimation};`

//Initial State
const initialState = {
  visitors: [],
  // visitors: [
  //   {
  //     _id: 1,
  //     firstName: "Hamza",
  //     lastName: "Mushtaq",
  //     email: "hamza@gmail.com",
  //     phone: 11117771,
  //     password: "asdasd",
  //     status: true
  //   },
  //   {
  //     _id: 2,
  //     firstName: "Ismail",
  //     lastName: "Khan",
  //     email: "Ismail@gmail.com",
  //     phone: 11111111,
  //     password: "asdasd",
  //     status: true
  //   },
  // ],
  visitor: {
    _id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    photo: '',
    status: true
  }
}

//Override styling for loader
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4A90E2;
  align-items: "center";
`;

const VisitorPage = (props) => {

  //Contexts for sidebar, topbar and visitor
  const { visible, setVisible } = useContext(userContext)
  const { role, setRole } = useContext(roleContext)
  const { contextVisitor, setContextVisitor } = useContext(VisitorContext)

  const history = useHistory()
  const [visitors, setVisitors] = useState(initialState.visitors)
  const [loading, setLoading] = useState(true)

  //Ref hook for preventing useEffect hook from running first time
  const firstUpdate = useRef(true)

  //Runs on first render
  useEffect(() => {
    setLoading(true)
    setVisible(true)
    setRole(localStorage.getItem('role'))

    VisitorApi.getVisitors()
      .then(res => {
        if (res.status === 200) {
          setVisitors(res.data)
          NotificationManager.success("Visitors Loaded Successfully", 'Success', 1000)
          setLoading(false)
        } else {
          NotificationManager.error("Something went wrong while loading Visitors: " + res.message, 'Error', 1000)
          setLoading(false)
        }
      })
      .catch(err => {
        NotificationManager.error("Something went wrong while loading Visitors: " + err, 'Error', 1000)
        setLoading(false)
      })
  }, [])

  //Runs when visitor context is changed
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    } else {

      console.log(contextVisitor)

      const visitorFormData = new FormData()

      visitorFormData.append("name", contextVisitor.name)
      visitorFormData.append("email", contextVisitor.email)
      visitorFormData.append("phone", contextVisitor.phone)
      visitorFormData.append("password", contextVisitor.password)
      visitorFormData.append("photo", contextVisitor.photo)
      visitorFormData.append("status", contextVisitor.status)

      console.log(...visitorFormData)

      setLoading(true)

      VisitorApi.updateVisitor(visitorFormData, contextVisitor.visitor_id)
        .then(res => {
          if (res.status === 201) {
            if (contextVisitor.status === true)
              NotificationManager.success(`${contextVisitor.name} has been reinstated`, 'Success', 1000)
            else
              NotificationManager.success(`${contextVisitor.name} has been suspended`, 'Success', 1000)

            //Get updated list whenever user gets updated
            VisitorApi.getVisitors()
              .then(res => {
                if (res.status === 200) {
                  setVisitors(res.data)
                  setLoading(false)
                } else {
                  NotificationManager.error("Something went wrong while loading Visitors: ", 'Error', 1000)
                  setLoading(false)
                }
              })
              .catch(err => NotificationManager.error("Something went wrong while loading Visitors: " + err, 'Error', 1000))

            console.log(res)
          } else {
            NotificationManager.error(`Something went wrong while suspending ${contextVisitor.name}: `, 'Error', 1000)
            setLoading(false)
          }
        })
        .catch(err => NotificationManager.error(`Something went wrong while suspending ${contextVisitor.name}: ` + err, 'Error', 1000))
    }
  }, [contextVisitor])

  const handleSearch = async (event) => {
    const { name, value } = event.target

    setLoading(true)

    searcher('visitor', value)
      .then(res => {
        setVisitors(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        NotificationManager.error(`Something went wrong while searching` + err, 'Error', 1000)
      })
  }

  return (
    <FadeInDiv className="mb-4" style={{ width: "100%" }}>
      <Row>
        <Col>
          <Input className="mb-2 ml-2 mr-2" type="search" placeholder="Search Visitors" onChange={handleSearch} />
        </Col>
        <Col>
          <button
            className="btn btn-primary mb-2 mr-2"
            type="button"
            onClick={() =>
              history.push('/admin/visitors/add')
            }
            style={{ float: "right" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-plus" viewBox="0 1 16 18">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
              <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
            </svg>
            {" "}Add New Visitor
          </button>
        </Col>
      </Row>

      { visitors.length === 0 ? <h4 className="text-center font-size 16">No Visitors Found</h4> :
        loading ?
          <BarLoader
            color={"#4A90E2"}
            loading={loading}
            css={override}
            size={100}
            height={5}
            width={1000}
          /> :
          <VisitorList
            visitors={visitors}
            initialState={initialState}
          />
      }
    </FadeInDiv>
  )
}
export default VisitorPage